.main_contact_div {
    background-color: #EEECE6;
    padding: 5% 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_title {
    font-family: Playfair Display;
    font-size: 34px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.6800000071525574px;
    text-align: center;
    color: black;
    margin: 0;
}

.contact_email {
    font-family: 'Guardian Sans', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
}

.contact_form {
    display: flex;
    gap: 20px;
    /* width: 50%; */
    flex-wrap: wrap;
}

.input_first {
    width: 44%;
}

.input_second {
    width: 100%;
}

.contact_form>input {
    border: 1px solid #d3d3d3;
    padding: 12px 17px;
    height: 30px;
    margin-bottom: 10px;
    color: var(--color-body, white);
    font-size: .8125rem;
    font-weight: 400;
    background: transparent;
    /* max-width: 100%; */
    border-radius: var(--input-border-radius, 6px);
}

.contact_button {
    background: white;
    font-family: 'Guardian Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
    width: 100%;
    border-radius: 25px;
    height: 50px;
    border: none;
    /* cursor: pointer; */
}

@media screen and (max-width:1100px) {
    .input_first {
        width: 100%;
    }

    .page_mask__96TCp {
        flex-direction: column;
    }

    .page_mask__96TCp>* {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .contact_title {
        font-size: 17px;
        line-height: 21px;
    }

    .contact_email {
        font-size: 8.5px;
        line-height: 13.5px;
    }

    .contact_form>input {
        font-size: 8px;
        padding: 6px 8.5px;
    }

    .contact_button {
        font-size: 7.5px;
        height: 25px;
        line-height: 12.5px;
    }

    .first_inputs {
        flex-direction: column !important;
    }

    .first_inputs>* {
        width: 100% !important;
    }

    .half_input {
        width: 100% !important;
    }


}

.review_div {
    width: 40%;
    font-family: '' Guardian Sans', sans-serif' !important;
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: black;
}

.review_intro {
    --ease: cubic-bezier(0.79, 0.14, 0.15, 0.86);
    --ease-in: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    --ease-out: cubic-bezier(0.08, 0.82, 0.17, 1);
    --fast-transition: 100ms;
    --regular-transition: 200ms;
    --slow-transition: 400ms;
    --heading-9-xl: var(--type-scale-9-xl);
    --heading-8-xl: var(--type-scale-8-xl);
    --heading-7-xl: var(--type-scale-7-xl);
    --heading-6-xl: var(--type-scale-6-xl);
    --heading-5-xl: var(--type-scale-5-xl);
    --heading-4-xl: var(--type-scale-4-xl);
    --heading-3-xl: var(--type-scale-3-xl);
    --heading-2-xl: var(--type-scale-2-xl);
    --heading-1-xl: var(--type-scale-1-xl);
    --heading-l: var(--type-scale-l);
    --heading-m: var(--type-scale-m);
    --heading-s: var(--type-scale-s);
    --heading-1-xs: var(--type-scale-1-xs);
    --body-2-xl: var(--type-scale-2-xl);
    --body-1-xl: var(--type-scale-1-xl);
    --body-l: var(--type-scale-l);
    --body-m: var(--type-scale-m);
    --body-s: var(--type-scale-s);
    --body-1-xs: var(--type-scale-1-xs);
    --font-stack-base: "Dazzed", system-ui, -apple-system, "SF Pro Display", "BlinkMacSystemFont", "Helvetica", "Lato", "Arial", "Segoe UI", "Verdana", sans-serif;
    --leading-none: 1; 
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
    --tracking-tighter: -.05em;
    --tracking-tight: -.025em;
    --tracking-normal: 0;
    --tracking-wide: .025em;
    --tracking-wider: .05em;
    --tracking-widest: .1em;
    --type-scale-9-xl: clamp(9.25rem, 3.625rem + 6.25vw, 11.125rem);
    --type-scale-8-xl: clamp(7.5rem, 3.375rem + 4.5833vw, 8.875rem);
    --type-scale-7-xl: clamp(6rem, 3.375rem + 2.9167vw, 6.875rem);
    --type-scale-6-xl: clamp(4.75rem, 2.875rem + 2.0833vw, 5.375rem);
    --type-scale-5-xl: clamp(3.875rem, 2.375rem + 1.6667vw, 4.375rem);
    --type-scale-4-xl: clamp(3rem, 1.125rem + 2.0833vw, 3.625rem);
    --type-scale-3-xl: clamp(2.5rem, 1.375rem + 1.25vw, 2.875rem);
    --type-scale-2-xl: clamp(2rem, 1.25rem + 0.8333vw, 2.25rem);
    --type-scale-1-xl: clamp(1.5rem, 1.125rem + 0.4167vw, 1.625rem);
    --type-scale-l: clamp(1.25rem, 0.875rem + 0.4167vw, 1.375rem);
    --type-scale-m: clamp(1rem, 0.625rem + 0.4167vw, 1.125rem);
    --type-scale-s: clamp(0.875rem, 0.5rem + 0.4167vw, 1rem);
    --type-scale-1-xs: clamp(0.75rem, 0.375rem + 0.4167vw, 0.875rem);
    --fresh-mint-100: #f9fefe;
    --fresh-mint-100-rgb: 249, 254, 254;
    --fresh-mint-150: azure;
    --fresh-mint-150-rgb: 240, 255, 255;
    --fresh-mint-200: #e8fefe;
    --fresh-mint-200-rgb: 232, 254, 254;
    --fresh-mint-250: #ccfdfd;
    --fresh-mint-250-rgb: 204, 253, 253;
    --fresh-mint-300: #b1fcfb;
    --fresh-mint-300-rgb: 177, 252, 251;
    --fresh-mint-400: #93f9f8;
    --fresh-mint-400-rgb: 147, 249, 248;
    --fresh-mint-500: #51f0ed;
    --fresh-mint-500-rgb: 81, 240, 237;
    --fresh-mint-600: #18dcd9;
    --fresh-mint-600-rgb: 24, 220, 217;
    --fresh-mint-700: #04afac;
    --fresh-mint-700-rgb: 4, 175, 172;
    --fresh-mint-800: #0d8c8a;
    --fresh-mint-800-rgb: 13, 140, 138;
    --fresh-mint-900: #00615f;
    --fresh-mint-900-rgb: 0, 97, 95;
    --fresh-mint-950: #004241;
    --fresh-mint-950-rgb: 0, 66, 65;
    --sweet-liquorice-100: #f5f7fa;
    --sweet-liquorice-100-rgb: 245, 247, 250;
    --sweet-liquorice-200: #e7ecf0;
    --sweet-liquorice-200-rgb: 231, 236, 240;
    --sweet-liquorice-250: #a6b2c5;
    --sweet-liquorice-250-rgb: 166, 178, 197;
    --sweet-liquorice-300: white;
    --sweet-liquorice-300-rgb: 210, 216, 223;
    --sweet-liquorice-400: #abb3ba;
    --sweet-liquorice-400-rgb: 171, 179, 186;
    --sweet-liquorice-500: #888f96;
    --sweet-liquorice-500-rgb: 136, 143, 150;
    --sweet-liquorice-600: #6c737a;
    --sweet-liquorice-600-rgb: 108, 115, 122;
    --sweet-liquorice-700: #41484f;
    --sweet-liquorice-700-rgb: 65, 72, 79;
    --sweet-liquorice-750: #31373d;
    --sweet-liquorice-750-rgb: 49, 55, 61;
    --sweet-liquorice-800: #20262c;
    --sweet-liquorice-800-rgb: 32, 38, 44;
    --sweet-liquorice-900: white;
    --sweet-liquorice-900-rgb: 17, 23, 29;
    --icy-slushie-050: #f9fafe;
    --icy-slushie-050-rgb: 249, 250, 254;
    --icy-slushie-100: #f5f7ff;
    --icy-slushie-100-rgb: 245, 247, 255;
    --icy-slushie-200: #e7ecfd;
    --icy-slushie-200-rgb: 231, 236, 253;
    --icy-slushie-250: #cfd9fc;
    --icy-slushie-250-rgb: 207, 217, 252;
    --icy-slushie-300: #a1b6fc;
    --icy-slushie-300-rgb: 161, 182, 252;
    --icy-slushie-400: #4c76ff;
    --icy-slushie-400-rgb: 76, 118, 255;
    --icy-slushie-500: #295bff;
    --icy-slushie-500-rgb: 41, 91, 255;
    --icy-slushie-600: #1d4add;
    --icy-slushie-600-rgb: 29, 74, 221;
    --icy-slushie-700: #0f36b8;
    --icy-slushie-700-rgb: 15, 54, 184;
    --icy-slushie-800: #01228e;
    --icy-slushie-800-rgb: 1, 34, 142;
    --icy-slushie-900: #011760;
    --icy-slushie-900-rgb: 1, 23, 96;
    --sticky-citrus-050: #fffef7;
    --sticky-citrus-050-rgb: 255, 254, 247;
    --sticky-citrus-100: #fffced;
    --sticky-citrus-100-rgb: 255, 252, 237;
    --sticky-citrus-200: #fff5c2;
    --sticky-citrus-200-rgb: 255, 245, 194;
    --sticky-citrus-250: #ffec9c;
    --sticky-citrus-250-rgb: 255, 236, 156;
    --sticky-citrus-300: #ffe371;
    --sticky-citrus-300-rgb: 255, 227, 113;
    --sticky-citrus-400: #ffdb4a;
    --sticky-citrus-400-rgb: 255, 219, 74;
    --sticky-citrus-500: #ffcf10;
    --sticky-citrus-500-rgb: 255, 207, 16;
    --sticky-citrus-600: #eaab00;
    --sticky-citrus-600-rgb: 234, 171, 0;
    --sticky-citrus-700: #da9500;
    --sticky-citrus-700-rgb: 218, 149, 0;
    --sticky-citrus-800: #b07300;
    --sticky-citrus-800-rgb: 176, 115, 0;
    --sticky-citrus-900: #925200;
    --sticky-citrus-900-rgb: 146, 82, 0;
    --chewy-cherry-050: #fff7fb;
    --chewy-cherry-050-rgb: 255, 247, 251;
    --chewy-cherry-100: #fceef5;
    --chewy-cherry-100-rgb: 252, 238, 245;
    --chewy-cherry-200: #ffd7e7;
    --chewy-cherry-200-rgb: 255, 215, 231;
    --chewy-cherry-250: #ffc7de;
    --chewy-cherry-250-rgb: 255, 199, 222;
    --chewy-cherry-300: #ffa4c1;
    --chewy-cherry-300-rgb: 255, 164, 193;
    --chewy-cherry-400: #ff80a8;
    --chewy-cherry-400-rgb: 255, 128, 168;
    --chewy-cherry-500: #f45a8b;
    --chewy-cherry-500-rgb: 244, 90, 139;
    --chewy-cherry-600: #e3356c;
    --chewy-cherry-600-rgb: 227, 53, 108;
    --chewy-cherry-700: #c12554;
    --chewy-cherry-700-rgb: 193, 37, 84;
    --chewy-cherry-800: #8f002b;
    --chewy-cherry-800-rgb: 143, 0, 43;
    --chewy-cherry-900: #780028;
    --chewy-cherry-900-rgb: 120, 0, 40;
    --blueberry-bubble-050: #fbf7ff;
    --blueberry-bubble-050-rgb: 251, 247, 255;
    --blueberry-bubble-100: #f4ecff;
    --blueberry-bubble-100-rgb: 244, 236, 255;
    --blueberry-bubble-200: #e9d7ff;
    --blueberry-bubble-200-rgb: 233, 215, 255;
    --blueberry-bubble-250: #e1c7ff;
    --blueberry-bubble-250-rgb: 225, 199, 255;
    --blueberry-bubble-300: #cea4ff;
    --blueberry-bubble-300-rgb: 206, 164, 255;
    --blueberry-bubble-400: #bd80ff;
    --blueberry-bubble-400-rgb: 189, 128, 255;
    --blueberry-bubble-500: #a45af4;
    --blueberry-bubble-500-rgb: 164, 90, 244;
    --blueberry-bubble-600: #8c35e3;
    --blueberry-bubble-600-rgb: 140, 53, 227;
    --blueberry-bubble-700: #7325c1;
    --blueberry-bubble-700-rgb: 115, 37, 193;
    --blueberry-bubble-800: #570ba4;
    --blueberry-bubble-800-rgb: 87, 11, 164;
    --blueberry-bubble-900: #3c0078;
    --blueberry-bubble-900-rgb: 60, 0, 120;
    --error-100: #fff7f8;
    --error-100-rgb: 255, 247, 248;
    --error-300: #ffa0ac;
    --error-300-rgb: 255, 160, 172;
    --error-500: #e8374c;
    --error-500-rgb: 232, 55, 76;
    --error-700: #720b18;
    --error-700-rgb: 114, 11, 24;
    --error-900: #410008;
    --error-900-rgb: 65, 0, 8;
    --success-100: #fbfff7;
    --success-100-rgb: 251, 255, 247;
    --success-300: #d0ffa0;
    --success-300-rgb: 208, 255, 160;
    --success-500: #8fda44;
    --success-500-rgb: 143, 218, 68;
    --success-700: #438006;
    --success-700-rgb: 67, 128, 6;
    --success-900: #204100;
    --success-900-rgb: 32, 65, 0;
    --white-000: #fff;
    --white-000-rgb: 255, 255, 255;
    --black-000: #000;
    --black-000-rgb: 0, 0, 0;
    --gray-000: #777;
    --gray-000-rgb: 119, 119, 119;
    --ui-size-4-xs: 12px;
    --ui-size-3-xs: 16px;
    --ui-size-2-xs: 20px;
    --ui-size-1-xs: 24px;
    --ui-size-s: 32px;
    --ui-size-m: 48px;
    --ui-size-l: 56px;
    --ui-size-1-xl: 64px;
    --ui-size-2-xl: 80px;
    --ui-size-3-xl: 96px;
    --ui-size-4-xl: 112px;
    --ui-size-5-xl: 152px;
    --radius-none: 0;
    --radius-1-xs: 2px;
    --radius-s: 4px;
    --radius-m: 6px;
    --radius-l: 8px;
    --radius-1-xl: 8px;
    --radius-2-xl: 16px;
    --radius-3-xl: 24px;
    --radius-4-xl: 32px;
    --radius-5-xl: 40px;
    --radius-full: 9999px;
    --zoom-scale: 1.07;
    --bg-color: var(--white-000);
    --button-disabled-bg-color: var(--sweet-liquorice-200);
    --button-disabled-color: var(--sweet-liquorice-300);
    --button-primary-bg-color: var(--sweet-liquorice-900);
    --button-primary-hover-bg-color: var(--fresh-mint-500);
    --button-primary-active-bg-color: var(--fresh-mint-600);
    --button-primary-hover-negative-bg-color: var(--white-000);
    --button-primary-color: var(--white-000);
    --button-primary-hover-color: var(--sweet-liquorice-900);
    --button-primary-active-color: var(--sweet-liquorice-900);
    --button-secondary-bg-color: var(--white-000);
    --button-secondary-hover-bg-color: var(--fresh-mint-500);
    --button-secondary-active-bg-color: var(--fresh-mint-600);
    --button-secondary-color: var(--sweet-liquorice-900);
    --button-secondary-hover-color: var(--sweet-liquorice-900);
    --button-secondary-active-color: var(--sweet-liquorice-900);
    --button-tertiary-color: var(--sweet-liquorice-900);
    --button-tertiary-hover-color: var(--sweet-liquorice-800);
    --button-tertiary-hover-bg-color: var(--fresh-mint-250);
    --button-tertiary-active-color: var(--sweet-liquorice-800);
    --button-tertiary-disabled-color: var(--sweet-liquorice-500);
    --button-bubble-hover-bg-color: var(--sweet-liquorice-900);
    --button-bubble-hover-color: var(--white-000);
    --button-bubble-inversed-hover-bg-color: var(--white-000);
    --button-bubble-inversed-hover-color: var(--sweet-liquorice-900);
    --input-bg-color: transparent;
    --input-checked-bg-color: var(--sweet-liquorice-900);
    --input-checked-hover-bg-color: var(--sweet-liquorice-600);
    --input-disabled-bg-color: var(--sweet-liquorice-200);
    --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
    --input-error-checked-bg-color: var(--chewy-cherry-700);
    --input-error-disabled-bg-color: var(--chewy-cherry-300);
    --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);
    --input-border-color: var(--sweet-liquorice-750);
    --input-hover-border-color: var(--sweet-liquorice-800);
    --input-active-border-color: var(--sweet-liquorice-500);
    --input-checked-border-color: var(--sweet-liquorice-750);
    --input-checked-hover-border-color: var(--sweet-liquorice-200);
    --input-focus-border-color: var(--fresh-mint-600);
    --input-focus-checked-border-color: var(--bg-color);
    --input-disabled-border-color: var(--sweet-liquorice-300);
    --input-error-border-color: var(--chewy-cherry-700);
    --input-color: var(--sweet-liquorice-900);
    --input-label-color: var(--sweet-liquorice-900);
    --input-disabled-label-color: var(--sweet-liquorice-250);
    --input-placeholder-color: var(--sweet-liquorice-400);
    --input-placeholder-disabled-color: var(--sweet-liquorice-250);
    --input-caret-color: var(--fresh-mint-700);
    --focus-ring-color: var(--icy-slushie-500);
    --selection-fresh-mint-200: var(--fresh-mint-200);
    --selection-fresh-mint-300: var(--fresh-mint-300);
    --selection-fresh-mint-400: var(--fresh-mint-400);
    --selection-fresh-mint-500: var(--fresh-mint-500);
    --selection-sweet-liquorice: var(--sweet-liquorice-600);
    --selection-chewy-cherry: var(--chewy-cherry-300);
    --selection-sticky-citrus: var(--sticky-citrus-400);
    --selection-blueberry-bubble: var(--blueberry-bubble-300);
    --selection-icy-slushie: var(--icy-slushie-300);
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-1-xl: 32px;
    --spacing-2-xl: 40px;
    --spacing-3-xl: 56px;
    --spacing-4-xl: 80px;
    --spacing-5-xl: 112px;
    --spacing-6-xl: 144px;
    --spacing-7-xl: 216px;
    --aspect-ratio: 16/9;
    font-family: var(--font-stack-base);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --scrollbar-width: 0px;
    --gap: 60px;
    --span: 4;
    --start: 2;
    box-sizing: border-box;
    cursor: none !important;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: initial;
    outline-color: var(--icy-slushie-500);
    font-weight: 600;
    color: var(--sweet-liquorice-900);
    line-height: var(--leading-tight);
    letter-spacing: var(--tracking-normal);
    font-size: var(--heading-1-xl);
}

.review_desc {
    --ease: cubic-bezier(0.79, 0.14, 0.15, 0.86);
    --ease-in: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    --ease-out: cubic-bezier(0.08, 0.82, 0.17, 1);
    --fast-transition: 100ms;
    --regular-transition: 200ms;
    --slow-transition: 400ms;
    --heading-9-xl: var(--type-scale-9-xl);
    --heading-8-xl: var(--type-scale-8-xl);
    --heading-7-xl: var(--type-scale-7-xl);
    --heading-6-xl: var(--type-scale-6-xl);
    --heading-5-xl: var(--type-scale-5-xl);
    --heading-4-xl: var(--type-scale-4-xl);
    --heading-3-xl: var(--type-scale-3-xl);
    --heading-2-xl: var(--type-scale-2-xl);
    --heading-1-xl: var(--type-scale-1-xl);
    --heading-l: var(--type-scale-l);
    --heading-m: var(--type-scale-m);
    --heading-s: var(--type-scale-s);
    --heading-1-xs: var(--type-scale-1-xs);
    --body-2-xl: var(--type-scale-2-xl);
    --body-1-xl: var(--type-scale-1-xl);
    --body-l: var(--type-scale-l);
    --body-m: var(--type-scale-m);
    --body-s: var(--type-scale-s);
    --body-1-xs: var(--type-scale-1-xs);
    --font-stack-base: "Dazzed", system-ui, -apple-system, "SF Pro Display", "BlinkMacSystemFont", "Helvetica", "Lato", "Arial", "Segoe UI", "Verdana", sans-serif;
    --leading-none: 1;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
    --tracking-tighter: -.05em;
    --tracking-tight: -.025em;
    --tracking-normal: 0;
    --tracking-wide: .025em;
    --tracking-wider: .05em;
    --tracking-widest: .1em;
    --type-scale-9-xl: clamp(9.25rem, 3.625rem + 6.25vw, 11.125rem);
    --type-scale-8-xl: clamp(7.5rem, 3.375rem + 4.5833vw, 8.875rem);
    --type-scale-7-xl: clamp(6rem, 3.375rem + 2.9167vw, 6.875rem);
    --type-scale-6-xl: clamp(4.75rem, 2.875rem + 2.0833vw, 5.375rem);
    --type-scale-5-xl: clamp(3.875rem, 2.375rem + 1.6667vw, 4.375rem);
    --type-scale-4-xl: clamp(3rem, 1.125rem + 2.0833vw, 3.625rem);
    --type-scale-3-xl: clamp(2.5rem, 1.375rem + 1.25vw, 2.875rem);
    --type-scale-2-xl: clamp(2rem, 1.25rem + 0.8333vw, 2.25rem);
    --type-scale-1-xl: clamp(1.5rem, 1.125rem + 0.4167vw, 1.625rem);
    --type-scale-l: clamp(1.25rem, 0.875rem + 0.4167vw, 1.375rem);
    --type-scale-m: clamp(1rem, 0.625rem + 0.4167vw, 1.125rem);
    --type-scale-s: clamp(0.875rem, 0.5rem + 0.4167vw, 1rem);
    --type-scale-1-xs: clamp(0.75rem, 0.375rem + 0.4167vw, 0.875rem);
    --fresh-mint-100: #f9fefe;
    --fresh-mint-100-rgb: 249, 254, 254;
    --fresh-mint-150: azure;
    --fresh-mint-150-rgb: 240, 255, 255;
    --fresh-mint-200: #e8fefe;
    --fresh-mint-200-rgb: 232, 254, 254;
    --fresh-mint-250: #ccfdfd;
    --fresh-mint-250-rgb: 204, 253, 253;
    --fresh-mint-300: #b1fcfb;
    --fresh-mint-300-rgb: 177, 252, 251;
    --fresh-mint-400: #93f9f8;
    --fresh-mint-400-rgb: 147, 249, 248;
    --fresh-mint-500: #51f0ed;
    --fresh-mint-500-rgb: 81, 240, 237;
    --fresh-mint-600: #18dcd9;
    --fresh-mint-600-rgb: 24, 220, 217;
    --fresh-mint-700: #04afac;
    --fresh-mint-700-rgb: 4, 175, 172;
    --fresh-mint-800: #0d8c8a;
    --fresh-mint-800-rgb: 13, 140, 138;
    --fresh-mint-900: #00615f;
    --fresh-mint-900-rgb: 0, 97, 95;
    --fresh-mint-950: #004241;
    --fresh-mint-950-rgb: 0, 66, 65;
    --sweet-liquorice-100: #f5f7fa;
    --sweet-liquorice-100-rgb: 245, 247, 250;
    --sweet-liquorice-200: #e7ecf0;
    --sweet-liquorice-200-rgb: 231, 236, 240;
    --sweet-liquorice-250: #a6b2c5;
    --sweet-liquorice-250-rgb: 166, 178, 197;
    --sweet-liquorice-300: white;
    --sweet-liquorice-300-rgb: 210, 216, 223;
    --sweet-liquorice-400: #abb3ba;
    --sweet-liquorice-400-rgb: 171, 179, 186;
    --sweet-liquorice-500: #888f96;
    --sweet-liquorice-500-rgb: 136, 143, 150;
    --sweet-liquorice-600: #6c737a;
    --sweet-liquorice-600-rgb: 108, 115, 122;
    --sweet-liquorice-700: #41484f;
    --sweet-liquorice-700-rgb: 65, 72, 79;
    --sweet-liquorice-750: #31373d;
    --sweet-liquorice-750-rgb: 49, 55, 61;
    --sweet-liquorice-800: #20262c;
    --sweet-liquorice-800-rgb: 32, 38, 44;
    --sweet-liquorice-900: white;
    --sweet-liquorice-900-rgb: 17, 23, 29;
    --icy-slushie-050: #f9fafe;
    --icy-slushie-050-rgb: 249, 250, 254;
    --icy-slushie-100: #f5f7ff;
    --icy-slushie-100-rgb: 245, 247, 255;
    --icy-slushie-200: #e7ecfd;
    --icy-slushie-200-rgb: 231, 236, 253;
    --icy-slushie-250: #cfd9fc;
    --icy-slushie-250-rgb: 207, 217, 252;
    --icy-slushie-300: #a1b6fc;
    --icy-slushie-300-rgb: 161, 182, 252;
    --icy-slushie-400: #4c76ff;
    --icy-slushie-400-rgb: 76, 118, 255;
    --icy-slushie-500: #295bff;
    --icy-slushie-500-rgb: 41, 91, 255;
    --icy-slushie-600: #1d4add;
    --icy-slushie-600-rgb: 29, 74, 221;
    --icy-slushie-700: #0f36b8;
    --icy-slushie-700-rgb: 15, 54, 184;
    --icy-slushie-800: #01228e;
    --icy-slushie-800-rgb: 1, 34, 142;
    --icy-slushie-900: #011760;
    --icy-slushie-900-rgb: 1, 23, 96;
    --sticky-citrus-050: #fffef7;
    --sticky-citrus-050-rgb: 255, 254, 247;
    --sticky-citrus-100: #fffced;
    --sticky-citrus-100-rgb: 255, 252, 237;
    --sticky-citrus-200: #fff5c2;
    --sticky-citrus-200-rgb: 255, 245, 194;
    --sticky-citrus-250: #ffec9c;
    --sticky-citrus-250-rgb: 255, 236, 156;
    --sticky-citrus-300: #ffe371;
    --sticky-citrus-300-rgb: 255, 227, 113;
    --sticky-citrus-400: #ffdb4a;
    --sticky-citrus-400-rgb: 255, 219, 74;
    --sticky-citrus-500: #ffcf10;
    --sticky-citrus-500-rgb: 255, 207, 16;
    --sticky-citrus-600: #eaab00;
    --sticky-citrus-600-rgb: 234, 171, 0;
    --sticky-citrus-700: #da9500;
    --sticky-citrus-700-rgb: 218, 149, 0;
    --sticky-citrus-800: #b07300;
    --sticky-citrus-800-rgb: 176, 115, 0;
    --sticky-citrus-900: #925200;
    --sticky-citrus-900-rgb: 146, 82, 0;
    --chewy-cherry-050: #fff7fb;
    --chewy-cherry-050-rgb: 255, 247, 251;
    --chewy-cherry-100: #fceef5;
    --chewy-cherry-100-rgb: 252, 238, 245;
    --chewy-cherry-200: #ffd7e7;
    --chewy-cherry-200-rgb: 255, 215, 231;
    --chewy-cherry-250: #ffc7de;
    --chewy-cherry-250-rgb: 255, 199, 222;
    --chewy-cherry-300: #ffa4c1;
    --chewy-cherry-300-rgb: 255, 164, 193;
    --chewy-cherry-400: #ff80a8;
    --chewy-cherry-400-rgb: 255, 128, 168;
    --chewy-cherry-500: #f45a8b;
    --chewy-cherry-500-rgb: 244, 90, 139;
    --chewy-cherry-600: #e3356c;
    --chewy-cherry-600-rgb: 227, 53, 108;
    --chewy-cherry-700: #c12554;
    --chewy-cherry-700-rgb: 193, 37, 84;
    --chewy-cherry-800: #8f002b;
    --chewy-cherry-800-rgb: 143, 0, 43;
    --chewy-cherry-900: #780028;
    --chewy-cherry-900-rgb: 120, 0, 40;
    --blueberry-bubble-050: #fbf7ff;
    --blueberry-bubble-050-rgb: 251, 247, 255;
    --blueberry-bubble-100: #f4ecff;
    --blueberry-bubble-100-rgb: 244, 236, 255;
    --blueberry-bubble-200: #e9d7ff;
    --blueberry-bubble-200-rgb: 233, 215, 255;
    --blueberry-bubble-250: #e1c7ff;
    --blueberry-bubble-250-rgb: 225, 199, 255;
    --blueberry-bubble-300: #cea4ff;
    --blueberry-bubble-300-rgb: 206, 164, 255;
    --blueberry-bubble-400: #bd80ff;
    --blueberry-bubble-400-rgb: 189, 128, 255;
    --blueberry-bubble-500: #a45af4;
    --blueberry-bubble-500-rgb: 164, 90, 244;
    --blueberry-bubble-600: #8c35e3;
    --blueberry-bubble-600-rgb: 140, 53, 227;
    --blueberry-bubble-700: #7325c1;
    --blueberry-bubble-700-rgb: 115, 37, 193;
    --blueberry-bubble-800: #570ba4;
    --blueberry-bubble-800-rgb: 87, 11, 164;
    --blueberry-bubble-900: #3c0078;
    --blueberry-bubble-900-rgb: 60, 0, 120;
    --error-100: #fff7f8;
    --error-100-rgb: 255, 247, 248;
    --error-300: #ffa0ac;
    --error-300-rgb: 255, 160, 172;
    --error-500: #e8374c;
    --error-500-rgb: 232, 55, 76;
    --error-700: #720b18;
    --error-700-rgb: 114, 11, 24;
    --error-900: #410008;
    --error-900-rgb: 65, 0, 8;
    --success-100: #fbfff7;
    --success-100-rgb: 251, 255, 247;
    --success-300: #d0ffa0;
    --success-300-rgb: 208, 255, 160;
    --success-500: #8fda44;
    --success-500-rgb: 143, 218, 68;
    --success-700: #438006;
    --success-700-rgb: 67, 128, 6;
    --success-900: #204100;
    --success-900-rgb: 32, 65, 0;
    --white-000: #fff;
    --white-000-rgb: 255, 255, 255;
    --black-000: #000;
    --black-000-rgb: 0, 0, 0;
    --gray-000: #777;
    --gray-000-rgb: 119, 119, 119;
    --ui-size-4-xs: 12px;
    --ui-size-3-xs: 16px;
    --ui-size-2-xs: 20px;
    --ui-size-1-xs: 24px;
    --ui-size-s: 32px;
    --ui-size-m: 48px;
    --ui-size-l: 56px;
    --ui-size-1-xl: 64px;
    --ui-size-2-xl: 80px;
    --ui-size-3-xl: 96px;
    --ui-size-4-xl: 112px;
    --ui-size-5-xl: 152px;
    --radius-none: 0;
    --radius-1-xs: 2px;
    --radius-s: 4px;
    --radius-m: 6px;
    --radius-l: 8px;
    --radius-1-xl: 8px;
    --radius-2-xl: 16px;
    --radius-3-xl: 24px;
    --radius-4-xl: 32px;
    --radius-5-xl: 40px;
    --radius-full: 9999px;
    --zoom-scale: 1.07;
    --bg-color: var(--white-000);
    --button-disabled-bg-color: var(--sweet-liquorice-200);
    --button-disabled-color: var(--sweet-liquorice-300);
    --button-primary-bg-color: var(--sweet-liquorice-900);
    --button-primary-hover-bg-color: var(--fresh-mint-500);
    --button-primary-active-bg-color: var(--fresh-mint-600);
    --button-primary-hover-negative-bg-color: var(--white-000);
    --button-primary-color: var(--white-000);
    --button-primary-hover-color: var(--sweet-liquorice-900);
    --button-primary-active-color: var(--sweet-liquorice-900);
    --button-secondary-bg-color: var(--white-000);
    --button-secondary-hover-bg-color: var(--fresh-mint-500);
    --button-secondary-active-bg-color: var(--fresh-mint-600);
    --button-secondary-color: var(--sweet-liquorice-900);
    --button-secondary-hover-color: var(--sweet-liquorice-900);
    --button-secondary-active-color: var(--sweet-liquorice-900);
    --button-tertiary-color: var(--sweet-liquorice-900);
    --button-tertiary-hover-color: var(--sweet-liquorice-800);
    --button-tertiary-hover-bg-color: var(--fresh-mint-250);
    --button-tertiary-active-color: var(--sweet-liquorice-800);
    --button-tertiary-disabled-color: var(--sweet-liquorice-500);
    --button-bubble-hover-bg-color: var(--sweet-liquorice-900);
    --button-bubble-hover-color: var(--white-000);
    --button-bubble-inversed-hover-bg-color: var(--white-000);
    --button-bubble-inversed-hover-color: var(--sweet-liquorice-900);
    --input-bg-color: transparent;
    --input-checked-bg-color: var(--sweet-liquorice-900);
    --input-checked-hover-bg-color: var(--sweet-liquorice-600);
    --input-disabled-bg-color: var(--sweet-liquorice-200);
    --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
    --input-error-checked-bg-color: var(--chewy-cherry-700);
    --input-error-disabled-bg-color: var(--chewy-cherry-300);
    --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);
    --input-border-color: var(--sweet-liquorice-750);
    --input-hover-border-color: var(--sweet-liquorice-800);
    --input-active-border-color: var(--sweet-liquorice-500);
    --input-checked-border-color: var(--sweet-liquorice-750);
    --input-checked-hover-border-color: var(--sweet-liquorice-200);
    --input-focus-border-color: var(--fresh-mint-600);
    --input-focus-checked-border-color: var(--bg-color);
    --input-disabled-border-color: var(--sweet-liquorice-300);
    --input-error-border-color: var(--chewy-cherry-700);
    --input-color: var(--sweet-liquorice-900);
    --input-label-color: var(--sweet-liquorice-900);
    --input-disabled-label-color: var(--sweet-liquorice-250);
    --input-placeholder-color: var(--sweet-liquorice-400);
    --input-placeholder-disabled-color: var(--sweet-liquorice-250);
    --input-caret-color: var(--fresh-mint-700);
    --focus-ring-color: var(--icy-slushie-500);
    --selection-fresh-mint-200: var(--fresh-mint-200);
    --selection-fresh-mint-300: var(--fresh-mint-300);
    --selection-fresh-mint-400: var(--fresh-mint-400);
    --selection-fresh-mint-500: var(--fresh-mint-500);
    --selection-sweet-liquorice: var(--sweet-liquorice-600);
    --selection-chewy-cherry: var(--chewy-cherry-300);
    --selection-sticky-citrus: var(--sticky-citrus-400);
    --selection-blueberry-bubble: var(--blueberry-bubble-300);
    --selection-icy-slushie: var(--icy-slushie-300);
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-1-xl: 32px;
    --spacing-2-xl: 40px;
    --spacing-3-xl: 56px;
    --spacing-4-xl: 80px;
    --spacing-5-xl: 112px;
    --spacing-6-xl: 144px;
    --spacing-7-xl: 216px;
    --aspect-ratio: 16/9;
    font-family: var(--font-stack-base);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --scrollbar-width: 0px;
    --gap: 60px;
    --span: 4;
    --start: 2;
    box-sizing: border-box;
    cursor: none !important;
    font-weight: 500;
    color: var(--sweet-liquorice-750);
    line-height: var(--leading-normal);
    letter-spacing: var(--tracking-normal);
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: initial;
    outline-color: var(--icy-slushie-500);
    color: black;
}

.contact_1_main_heading {
    --ease: cubic-bezier(0.79, 0.14, 0.15, 0.86);
    --ease-in: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    --ease-out: cubic-bezier(0.08, 0.82, 0.17, 1);
    --fast-transition: 100ms;
    --regular-transition: 200ms;
    --slow-transition: 400ms;
    --heading-9-xl: var(--type-scale-9-xl);
    --heading-8-xl: var(--type-scale-8-xl);
    --heading-7-xl: var(--type-scale-7-xl);
    --heading-6-xl: var(--type-scale-6-xl);
    --heading-5-xl: var(--type-scale-5-xl);
    --heading-4-xl: var(--type-scale-4-xl);
    --heading-3-xl: var(--type-scale-3-xl);
    --heading-2-xl: var(--type-scale-2-xl);
    --heading-1-xl: var(--type-scale-1-xl);
    --heading-l: var(--type-scale-l);
    --heading-m: var(--type-scale-m);
    --heading-s: var(--type-scale-s);
    --heading-1-xs: var(--type-scale-1-xs);
    --body-2-xl: var(--type-scale-2-xl);
    --body-1-xl: var(--type-scale-1-xl);
    --body-l: var(--type-scale-l);
    --body-m: var(--type-scale-m);
    --body-s: var(--type-scale-s);
    --body-1-xs: var(--type-scale-1-xs);
    --font-stack-base: "Dazzed", system-ui, -apple-system, "SF Pro Display", "BlinkMacSystemFont", "Helvetica", "Lato", "Arial", "Segoe UI", "Verdana", sans-serif;
    --leading-none: 1;
    width: 50%;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
    --tracking-tighter: -.05em;
    --tracking-tight: -.025em;
    --tracking-normal: 0;
    --tracking-wide: .025em;
    --tracking-wider: .05em;
    --tracking-widest: .1em;
    --type-scale-9-xl: clamp(9.25rem, 3.625rem + 6.25vw, 11.125rem);
    --type-scale-8-xl: clamp(7.5rem, 3.375rem + 4.5833vw, 8.875rem);
    --type-scale-7-xl: clamp(6rem, 3.375rem + 2.9167vw, 6.875rem);
    --type-scale-6-xl: clamp(4.75rem, 2.875rem + 2.0833vw, 5.375rem);
    --type-scale-5-xl: clamp(3.875rem, 2.375rem + 1.6667vw, 4.375rem);
    --type-scale-4-xl: clamp(3rem, 1.125rem + 2.0833vw, 3.625rem);
    --type-scale-3-xl: clamp(2.5rem, 1.375rem + 1.25vw, 2.875rem);
    --type-scale-2-xl: clamp(2rem, 1.25rem + 0.8333vw, 2.25rem);
    --type-scale-1-xl: clamp(1.5rem, 1.125rem + 0.4167vw, 1.625rem);
    --type-scale-l: clamp(1.25rem, 0.875rem + 0.4167vw, 1.375rem);
    --type-scale-m: clamp(1rem, 0.625rem + 0.4167vw, 1.125rem);
    --type-scale-s: clamp(0.875rem, 0.5rem + 0.4167vw, 1rem);
    --type-scale-1-xs: clamp(0.75rem, 0.375rem + 0.4167vw, 0.875rem);
    --fresh-mint-100: #f9fefe;
    --fresh-mint-100-rgb: 249, 254, 254;
    --fresh-mint-150: azure;
    --fresh-mint-150-rgb: 240, 255, 255;
    --fresh-mint-200: #e8fefe;
    --fresh-mint-200-rgb: 232, 254, 254;
    --fresh-mint-250: #ccfdfd;
    --fresh-mint-250-rgb: 204, 253, 253;
    --fresh-mint-300: #b1fcfb;
    --fresh-mint-300-rgb: 177, 252, 251;
    --fresh-mint-400: #93f9f8;
    --fresh-mint-400-rgb: 147, 249, 248;
    --fresh-mint-500: #51f0ed;
    --fresh-mint-500-rgb: 81, 240, 237;
    --fresh-mint-600: #18dcd9;
    --fresh-mint-600-rgb: 24, 220, 217;
    --fresh-mint-700: #04afac;
    --fresh-mint-700-rgb: 4, 175, 172;
    --fresh-mint-800: #0d8c8a;
    --fresh-mint-800-rgb: 13, 140, 138;
    --fresh-mint-900: #00615f;
    --fresh-mint-900-rgb: 0, 97, 95;
    --fresh-mint-950: #004241;
    --fresh-mint-950-rgb: 0, 66, 65;
    --sweet-liquorice-100: #f5f7fa;
    --sweet-liquorice-100-rgb: 245, 247, 250;
    --sweet-liquorice-200: #e7ecf0;
    --sweet-liquorice-200-rgb: 231, 236, 240;
    --sweet-liquorice-250: #a6b2c5;
    --sweet-liquorice-250-rgb: 166, 178, 197;
    --sweet-liquorice-300: black;
    --sweet-liquorice-300-rgb: 210, 216, 223;
    --sweet-liquorice-400: #abb3ba;
    --sweet-liquorice-400-rgb: 171, 179, 186;
    --sweet-liquorice-500: #888f96;
    --sweet-liquorice-500-rgb: 136, 143, 150;
    --sweet-liquorice-600: #6c737a;
    --sweet-liquorice-600-rgb: 108, 115, 122;
    --sweet-liquorice-700: #41484f;
    --sweet-liquorice-700-rgb: 65, 72, 79;
    --sweet-liquorice-750: #31373d;
    --sweet-liquorice-750-rgb: 49, 55, 61;
    --sweet-liquorice-800: #20262c;
    --sweet-liquorice-800-rgb: 32, 38, 44;
    --sweet-liquorice-900: black;
    --sweet-liquorice-900-rgb: 17, 23, 29;
    --icy-slushie-050: #f9fafe;
    --icy-slushie-050-rgb: 249, 250, 254;
    --icy-slushie-100: #f5f7ff;
    --icy-slushie-100-rgb: 245, 247, 255;
    --icy-slushie-200: #e7ecfd;
    --icy-slushie-200-rgb: 231, 236, 253;
    --icy-slushie-250: #cfd9fc;
    --icy-slushie-250-rgb: 207, 217, 252;
    --icy-slushie-300: #a1b6fc;
    --icy-slushie-300-rgb: 161, 182, 252;
    --icy-slushie-400: #4c76ff;
    --icy-slushie-400-rgb: 76, 118, 255;
    --icy-slushie-500: #295bff;
    --icy-slushie-500-rgb: 41, 91, 255;
    --icy-slushie-600: #1d4add;
    --icy-slushie-600-rgb: 29, 74, 221;
    --icy-slushie-700: #0f36b8;
    --icy-slushie-700-rgb: 15, 54, 184;
    --icy-slushie-800: #01228e;
    --icy-slushie-800-rgb: 1, 34, 142;
    --icy-slushie-900: #011760;
    --icy-slushie-900-rgb: 1, 23, 96;
    --sticky-citrus-050: #fffef7;
    --sticky-citrus-050-rgb: 255, 254, 247;
    --sticky-citrus-100: #fffced;
    --sticky-citrus-100-rgb: 255, 252, 237;
    --sticky-citrus-200: #fff5c2;
    --sticky-citrus-200-rgb: 255, 245, 194;
    --sticky-citrus-250: #ffec9c;
    --sticky-citrus-250-rgb: 255, 236, 156;
    --sticky-citrus-300: #ffe371;
    --sticky-citrus-300-rgb: 255, 227, 113;
    --sticky-citrus-400: #ffdb4a;
    --sticky-citrus-400-rgb: 255, 219, 74;
    --sticky-citrus-500: #ffcf10;
    --sticky-citrus-500-rgb: 255, 207, 16;
    --sticky-citrus-600: #eaab00;
    --sticky-citrus-600-rgb: 234, 171, 0;
    --sticky-citrus-700: #da9500;
    --sticky-citrus-700-rgb: 218, 149, 0;
    --sticky-citrus-800: #b07300;
    --sticky-citrus-800-rgb: 176, 115, 0;
    --sticky-citrus-900: #925200;
    --sticky-citrus-900-rgb: 146, 82, 0;
    --chewy-cherry-050: #fff7fb;
    --chewy-cherry-050-rgb: 255, 247, 251;
    --chewy-cherry-100: #fceef5;
    --chewy-cherry-100-rgb: 252, 238, 245;
    --chewy-cherry-200: #ffd7e7;
    --chewy-cherry-200-rgb: 255, 215, 231;
    --chewy-cherry-250: #ffc7de;
    --chewy-cherry-250-rgb: 255, 199, 222;
    --chewy-cherry-300: #ffa4c1;
    --chewy-cherry-300-rgb: 255, 164, 193;
    --chewy-cherry-400: #ff80a8;
    --chewy-cherry-400-rgb: 255, 128, 168;
    --chewy-cherry-500: #f45a8b;
    --chewy-cherry-500-rgb: 244, 90, 139;
    --chewy-cherry-600: #e3356c;
    --chewy-cherry-600-rgb: 227, 53, 108;
    --chewy-cherry-700: #c12554;
    --chewy-cherry-700-rgb: 193, 37, 84;
    --chewy-cherry-800: #8f002b;
    --chewy-cherry-800-rgb: 143, 0, 43;
    --chewy-cherry-900: #780028;
    --chewy-cherry-900-rgb: 120, 0, 40;
    --blueberry-bubble-050: #fbf7ff;
    --blueberry-bubble-050-rgb: 251, 247, 255;
    --blueberry-bubble-100: #f4ecff;
    --blueberry-bubble-100-rgb: 244, 236, 255;
    --blueberry-bubble-200: #e9d7ff;
    --blueberry-bubble-200-rgb: 233, 215, 255;
    --blueberry-bubble-250: #e1c7ff;
    --blueberry-bubble-250-rgb: 225, 199, 255;
    --blueberry-bubble-300: #cea4ff;
    --blueberry-bubble-300-rgb: 206, 164, 255;
    --blueberry-bubble-400: #bd80ff;
    --blueberry-bubble-400-rgb: 189, 128, 255;
    --blueberry-bubble-500: #a45af4;
    --blueberry-bubble-500-rgb: 164, 90, 244;
    --blueberry-bubble-600: #8c35e3;
    --blueberry-bubble-600-rgb: 140, 53, 227;
    --blueberry-bubble-700: #7325c1;
    --blueberry-bubble-700-rgb: 115, 37, 193;
    --blueberry-bubble-800: #570ba4;
    --blueberry-bubble-800-rgb: 87, 11, 164;
    --blueberry-bubble-900: #3c0078;
    --blueberry-bubble-900-rgb: 60, 0, 120;
    --error-100: #fff7f8;
    --error-100-rgb: 255, 247, 248;
    --error-300: #ffa0ac;
    --error-300-rgb: 255, 160, 172;
    --error-500: #e8374c;
    --error-500-rgb: 232, 55, 76;
    --error-700: #720b18;
    --error-700-rgb: 114, 11, 24;
    --error-900: #410008;
    --error-900-rgb: 65, 0, 8;
    --success-100: #fbfff7;
    --success-100-rgb: 251, 255, 247;
    --success-300: #d0ffa0;
    --success-300-rgb: 208, 255, 160;
    --success-500: #8fda44;
    --success-500-rgb: 143, 218, 68;
    --success-700: #438006;
    --success-700-rgb: 67, 128, 6;
    --success-900: #204100;
    --success-900-rgb: 32, 65, 0;
    --white-000: #fff;
    --white-000-rgb: 255, 255, 255;
    --black-000: #000;
    --black-000-rgb: 0, 0, 0;
    --gray-000: #777;
    --gray-000-rgb: 119, 119, 119;
    --ui-size-4-xs: 12px;
    --ui-size-3-xs: 16px;
    --ui-size-2-xs: 20px;
    --ui-size-1-xs: 24px;
    --ui-size-s: 32px;
    --ui-size-m: 48px;
    --ui-size-l: 56px;
    --ui-size-1-xl: 64px;
    --ui-size-2-xl: 80px;
    --ui-size-3-xl: 96px;
    --ui-size-4-xl: 112px;
    --ui-size-5-xl: 152px;
    --radius-none: 0;
    --radius-1-xs: 2px;
    --radius-s: 4px;
    --radius-m: 6px;
    --radius-l: 8px;
    --radius-1-xl: 8px;
    --radius-2-xl: 16px;
    --radius-3-xl: 24px;
    --radius-4-xl: 32px;
    --radius-5-xl: 40px;
    --radius-full: 9999px;
    --zoom-scale: 1.07;
    --bg-color: var(--white-000);
    --button-disabled-bg-color: var(--sweet-liquorice-200);
    --button-disabled-color: var(--sweet-liquorice-300);
    --button-primary-bg-color: var(--sweet-liquorice-900);
    --button-primary-hover-bg-color: var(--fresh-mint-500);
    --button-primary-active-bg-color: var(--fresh-mint-600);
    --button-primary-hover-negative-bg-color: var(--white-000);
    --button-primary-color: var(--white-000);
    --button-primary-hover-color: var(--sweet-liquorice-900);
    --button-primary-active-color: var(--sweet-liquorice-900);
    --button-secondary-bg-color: var(--white-000);
    --button-secondary-hover-bg-color: var(--fresh-mint-500);
    --button-secondary-active-bg-color: var(--fresh-mint-600);
    --button-secondary-color: var(--sweet-liquorice-900);
    --button-secondary-hover-color: var(--sweet-liquorice-900);
    --button-secondary-active-color: var(--sweet-liquorice-900);
    --button-tertiary-color: var(--sweet-liquorice-900);
    --button-tertiary-hover-color: var(--sweet-liquorice-800);
    --button-tertiary-hover-bg-color: var(--fresh-mint-250);
    --button-tertiary-active-color: var(--sweet-liquorice-800);
    --button-tertiary-disabled-color: var(--sweet-liquorice-500);
    --button-bubble-hover-bg-color: var(--sweet-liquorice-900);
    --button-bubble-hover-color: var(--white-000);
    --button-bubble-inversed-hover-bg-color: var(--white-000);
    --button-bubble-inversed-hover-color: var(--sweet-liquorice-900);
    --input-bg-color: transparent;
    --input-checked-bg-color: var(--sweet-liquorice-900);
    --input-checked-hover-bg-color: var(--sweet-liquorice-600);
    --input-disabled-bg-color: var(--sweet-liquorice-200);
    --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
    --input-error-checked-bg-color: var(--chewy-cherry-700);
    --input-error-disabled-bg-color: var(--chewy-cherry-300);
    --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);
    --input-border-color: var(--sweet-liquorice-750);
    --input-hover-border-color: var(--sweet-liquorice-800);
    --input-active-border-color: var(--sweet-liquorice-500);
    --input-checked-border-color: var(--sweet-liquorice-750);
    --input-checked-hover-border-color: var(--sweet-liquorice-200);
    --input-focus-border-color: var(--fresh-mint-600);
    --input-focus-checked-border-color: var(--bg-color);
    --input-disabled-border-color: var(--sweet-liquorice-300);
    --input-error-border-color: var(--chewy-cherry-700);
    --input-color: var(--sweet-liquorice-900);
    --input-label-color: var(--sweet-liquorice-900);
    --input-disabled-label-color: var(--sweet-liquorice-250);
    --input-placeholder-color: var(--sweet-liquorice-400);
    --input-placeholder-disabled-color: var(--sweet-liquorice-250);
    --input-caret-color: var(--fresh-mint-700);
    --focus-ring-color: var(--icy-slushie-500);
    --selection-fresh-mint-200: var(--fresh-mint-200);
    --selection-fresh-mint-300: var(--fresh-mint-300);
    --selection-fresh-mint-400: var(--fresh-mint-400);
    --selection-fresh-mint-500: var(--fresh-mint-500);
    --selection-sweet-liquorice: var(--sweet-liquorice-600);
    --selection-chewy-cherry: var(--chewy-cherry-300);
    --selection-sticky-citrus: var(--sticky-citrus-400);
    --selection-blueberry-bubble: var(--blueberry-bubble-300);
    --selection-icy-slushie: var(--icy-slushie-300);
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-1-xl: 32px;
    --spacing-2-xl: 40px;
    --spacing-3-xl: 56px;
    --spacing-4-xl: 80px;
    --spacing-5-xl: 112px;
    --spacing-6-xl: 144px;
    --spacing-7-xl: 216px;
    --aspect-ratio: 16/9;
    font-family: var(--font-stack-base);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --scrollbar-width: 0px;
    --gap: 60px;
    --span: 10;
    --span-1-xl: 8;
    --start: 2;
    box-sizing: border-box;
    cursor: none !important;
    font-weight: 600;
    color: var(--sweet-liquorice-900);
    line-height: var(--leading-tight);
    letter-spacing: var(--tracking-normal);
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: initial;
    outline-color: var(--icy-slushie-500);
    font-size: var(--heading-6-xl);
    max-width: 664px;
    margin-bottom: var(--spacing-l);
}

.contact_1_main {
    padding: 8% 5% 8% 10%;
    background-color: #EEECE6;
}

.form_div_c1 {
    width: 55%;
}

.half_input {
    background-color: #fff;
    border: 1px solid white;
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: 40px;
    margin: 0;
    /* width: 100%; */
    padding-right: 48px;
    font-family: inherit;
    color: black;
    padding: 0 24px;
    line-height: 72px;
    font-size: var(--body-1-xl);
}

div>input {
    background-color: #fff;
    border: 1px solid white;
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: 40px;
    margin: 0;
    /* width: 100%; */
    padding-right: 48px;
    font-family: inherit;
    color: black;
    padding: 0 24px;
    line-height: 72px;
    font-size: var(--body-1-xl);
}

div>textarea {
    background-color: #fff;
    border: 1px solid white;
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: 40px;
    margin: 0;
    /* width: 100%; */
    padding-right: 48px;
    font-family: inherit;
    color: black;
    padding: 0 24px;
    line-height: 72px;
    font-size: var(--body-1-xl);
}

.review_div_desc {
    padding-top: 20px !important;
    --ease: cubic-bezier(0.79, 0.14, 0.15, 0.86);
    --ease-in: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    --ease-out: cubic-bezier(0.08, 0.82, 0.17, 1);
    --fast-transition: 100ms;
    --regular-transition: 200ms;
    --slow-transition: 400ms;
    --heading-9-xl: var(--type-scale-9-xl);
    --heading-8-xl: var(--type-scale-8-xl);
    --heading-7-xl: var(--type-scale-7-xl);
    --heading-6-xl: var(--type-scale-6-xl);
    --heading-5-xl: var(--type-scale-5-xl);
    --heading-4-xl: var(--type-scale-4-xl);
    --heading-3-xl: var(--type-scale-3-xl);
    --heading-2-xl: var(--type-scale-2-xl);
    --heading-1-xl: var(--type-scale-1-xl);
    --heading-l: var(--type-scale-l);
    --heading-m: var(--type-scale-m);
    --heading-s: var(--type-scale-s);
    --heading-1-xs: var(--type-scale-1-xs);
    --body-2-xl: var(--type-scale-2-xl);
    --body-1-xl: var(--type-scale-1-xl);
    --body-l: var(--type-scale-l);
    --body-m: var(--type-scale-m);
    --body-s: var(--type-scale-s);
    --body-1-xs: var(--type-scale-1-xs);
    --font-stack-base: "Dazzed", system-ui, -apple-system, "SF Pro Display", "BlinkMacSystemFont", "Helvetica", "Lato", "Arial", "Segoe UI", "Verdana", sans-serif;
    --leading-none: 1;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
    --tracking-tighter: -.05em;
    --tracking-tight: -.025em;
    --tracking-normal: 0;
    --tracking-wide: .025em;
    --tracking-wider: .05em;
    --tracking-widest: .1em;
    --type-scale-9-xl: clamp(9.25rem, 3.625rem + 6.25vw, 11.125rem);
    --type-scale-8-xl: clamp(7.5rem, 3.375rem + 4.5833vw, 8.875rem);
    --type-scale-7-xl: clamp(6rem, 3.375rem + 2.9167vw, 6.875rem);
    --type-scale-6-xl: clamp(4.75rem, 2.875rem + 2.0833vw, 5.375rem);
    --type-scale-5-xl: clamp(3.875rem, 2.375rem + 1.6667vw, 4.375rem);
    --type-scale-4-xl: clamp(3rem, 1.125rem + 2.0833vw, 3.625rem);
    --type-scale-3-xl: clamp(2.5rem, 1.375rem + 1.25vw, 2.875rem);
    --type-scale-2-xl: clamp(2rem, 1.25rem + 0.8333vw, 2.25rem);
    --type-scale-1-xl: clamp(1.5rem, 1.125rem + 0.4167vw, 1.625rem);
    --type-scale-l: clamp(1.25rem, 0.875rem + 0.4167vw, 1.375rem);
    --type-scale-m: clamp(1rem, 0.625rem + 0.4167vw, 1.125rem);
    --type-scale-s: clamp(0.875rem, 0.5rem + 0.4167vw, 1rem);
    --type-scale-1-xs: clamp(0.75rem, 0.375rem + 0.4167vw, 0.875rem);
    --fresh-mint-100: #f9fefe;
    --fresh-mint-100-rgb: 249, 254, 254;
    --fresh-mint-150: azure;
    --fresh-mint-150-rgb: 240, 255, 255;
    --fresh-mint-200: #e8fefe;
    --fresh-mint-200-rgb: 232, 254, 254;
    --fresh-mint-250: #ccfdfd;
    --fresh-mint-250-rgb: 204, 253, 253;
    --fresh-mint-300: #b1fcfb;
    --fresh-mint-300-rgb: 177, 252, 251;
    --fresh-mint-400: #93f9f8;
    --fresh-mint-400-rgb: 147, 249, 248;
    --fresh-mint-500: #51f0ed;
    --fresh-mint-500-rgb: 81, 240, 237;
    --fresh-mint-600: #18dcd9;
    --fresh-mint-600-rgb: 24, 220, 217;
    --fresh-mint-700: #04afac;
    --fresh-mint-700-rgb: 4, 175, 172;
    --fresh-mint-800: #0d8c8a;
    --fresh-mint-800-rgb: 13, 140, 138;
    --fresh-mint-900: #00615f;
    --fresh-mint-900-rgb: 0, 97, 95;
    --fresh-mint-950: #004241;
    --fresh-mint-950-rgb: 0, 66, 65;
    --sweet-liquorice-100: #f5f7fa;
    --sweet-liquorice-100-rgb: 245, 247, 250;
    --sweet-liquorice-200: #e7ecf0;
    --sweet-liquorice-200-rgb: 231, 236, 240;
    --sweet-liquorice-250: #a6b2c5;
    --sweet-liquorice-250-rgb: 166, 178, 197;
    --sweet-liquorice-300: white;
    --sweet-liquorice-300-rgb: 210, 216, 223;
    --sweet-liquorice-400: #abb3ba;
    --sweet-liquorice-400-rgb: 171, 179, 186;
    --sweet-liquorice-500: #888f96;
    --sweet-liquorice-500-rgb: 136, 143, 150;
    --sweet-liquorice-600: #6c737a;
    --sweet-liquorice-600-rgb: 108, 115, 122;
    --sweet-liquorice-700: #41484f;
    --sweet-liquorice-700-rgb: 65, 72, 79;
    --sweet-liquorice-750: #31373d;
    --sweet-liquorice-750-rgb: 49, 55, 61;
    --sweet-liquorice-800: #20262c;
    --sweet-liquorice-800-rgb: 32, 38, 44;
    --sweet-liquorice-900: white;
    --sweet-liquorice-900-rgb: 17, 23, 29;
    --icy-slushie-050: #f9fafe;
    --icy-slushie-050-rgb: 249, 250, 254;
    --icy-slushie-100: #f5f7ff;
    --icy-slushie-100-rgb: 245, 247, 255;
    --icy-slushie-200: #e7ecfd;
    --icy-slushie-200-rgb: 231, 236, 253;
    --icy-slushie-250: #cfd9fc;
    --icy-slushie-250-rgb: 207, 217, 252;
    --icy-slushie-300: #a1b6fc;
    --icy-slushie-300-rgb: 161, 182, 252;
    --icy-slushie-400: #4c76ff;
    --icy-slushie-400-rgb: 76, 118, 255;
    --icy-slushie-500: #295bff;
    --icy-slushie-500-rgb: 41, 91, 255;
    --icy-slushie-600: #1d4add;
    --icy-slushie-600-rgb: 29, 74, 221;
    --icy-slushie-700: #0f36b8;
    --icy-slushie-700-rgb: 15, 54, 184;
    --icy-slushie-800: #01228e;
    --icy-slushie-800-rgb: 1, 34, 142;
    --icy-slushie-900: #011760;
    --icy-slushie-900-rgb: 1, 23, 96;
    --sticky-citrus-050: #fffef7;
    --sticky-citrus-050-rgb: 255, 254, 247;
    --sticky-citrus-100: #fffced;
    --sticky-citrus-100-rgb: 255, 252, 237;
    --sticky-citrus-200: #fff5c2;
    --sticky-citrus-200-rgb: 255, 245, 194;
    --sticky-citrus-250: #ffec9c;
    --sticky-citrus-250-rgb: 255, 236, 156;
    --sticky-citrus-300: #ffe371;
    --sticky-citrus-300-rgb: 255, 227, 113;
    --sticky-citrus-400: #ffdb4a;
    --sticky-citrus-400-rgb: 255, 219, 74;
    --sticky-citrus-500: #ffcf10;
    --sticky-citrus-500-rgb: 255, 207, 16;
    --sticky-citrus-600: #eaab00;
    --sticky-citrus-600-rgb: 234, 171, 0;
    --sticky-citrus-700: #da9500;
    --sticky-citrus-700-rgb: 218, 149, 0;
    --sticky-citrus-800: #b07300;
    --sticky-citrus-800-rgb: 176, 115, 0;
    --sticky-citrus-900: #925200;
    --sticky-citrus-900-rgb: 146, 82, 0;
    --chewy-cherry-050: #fff7fb;
    --chewy-cherry-050-rgb: 255, 247, 251;
    --chewy-cherry-100: #fceef5;
    --chewy-cherry-100-rgb: 252, 238, 245;
    --chewy-cherry-200: #ffd7e7;
    --chewy-cherry-200-rgb: 255, 215, 231;
    --chewy-cherry-250: #ffc7de;
    --chewy-cherry-250-rgb: 255, 199, 222;
    --chewy-cherry-300: #ffa4c1;
    --chewy-cherry-300-rgb: 255, 164, 193;
    --chewy-cherry-400: #ff80a8;
    --chewy-cherry-400-rgb: 255, 128, 168;
    --chewy-cherry-500: #f45a8b;
    --chewy-cherry-500-rgb: 244, 90, 139;
    --chewy-cherry-600: #e3356c;
    --chewy-cherry-600-rgb: 227, 53, 108;
    --chewy-cherry-700: #c12554;
    --chewy-cherry-700-rgb: 193, 37, 84;
    --chewy-cherry-800: #8f002b;
    --chewy-cherry-800-rgb: 143, 0, 43;
    --chewy-cherry-900: #780028;
    --chewy-cherry-900-rgb: 120, 0, 40;
    --blueberry-bubble-050: #fbf7ff;
    --blueberry-bubble-050-rgb: 251, 247, 255;
    --blueberry-bubble-100: #f4ecff;
    --blueberry-bubble-100-rgb: 244, 236, 255;
    --blueberry-bubble-200: #e9d7ff;
    --blueberry-bubble-200-rgb: 233, 215, 255;
    --blueberry-bubble-250: #e1c7ff;
    --blueberry-bubble-250-rgb: 225, 199, 255;
    --blueberry-bubble-300: #cea4ff;
    --blueberry-bubble-300-rgb: 206, 164, 255;
    --blueberry-bubble-400: #bd80ff;
    --blueberry-bubble-400-rgb: 189, 128, 255;
    --blueberry-bubble-500: #a45af4;
    --blueberry-bubble-500-rgb: 164, 90, 244;
    --blueberry-bubble-600: #8c35e3;
    --blueberry-bubble-600-rgb: 140, 53, 227;
    --blueberry-bubble-700: #7325c1;
    --blueberry-bubble-700-rgb: 115, 37, 193;
    --blueberry-bubble-800: #570ba4;
    --blueberry-bubble-800-rgb: 87, 11, 164;
    --blueberry-bubble-900: #3c0078;
    --blueberry-bubble-900-rgb: 60, 0, 120;
    --error-100: #fff7f8;
    --error-100-rgb: 255, 247, 248;
    --error-300: #ffa0ac;
    --error-300-rgb: 255, 160, 172;
    --error-500: #e8374c;
    --error-500-rgb: 232, 55, 76;
    --error-700: #720b18;
    --error-700-rgb: 114, 11, 24;
    --error-900: #410008;
    --error-900-rgb: 65, 0, 8;
    --success-100: #fbfff7;
    --success-100-rgb: 251, 255, 247;
    --success-300: #d0ffa0;
    --success-300-rgb: 208, 255, 160;
    --success-500: #8fda44;
    --success-500-rgb: 143, 218, 68;
    --success-700: #438006;
    --success-700-rgb: 67, 128, 6;
    --success-900: #204100;
    --success-900-rgb: 32, 65, 0;
    --white-000: #fff;
    --white-000-rgb: 255, 255, 255;
    --black-000: #000;
    --black-000-rgb: 0, 0, 0;
    --gray-000: #777;
    --gray-000-rgb: 119, 119, 119;
    --ui-size-4-xs: 12px;
    --ui-size-3-xs: 16px;
    --ui-size-2-xs: 20px;
    --ui-size-1-xs: 24px;
    --ui-size-s: 32px;
    --ui-size-m: 48px;
    --ui-size-l: 56px;
    --ui-size-1-xl: 64px;
    --ui-size-2-xl: 80px;
    --ui-size-3-xl: 96px;
    --ui-size-4-xl: 112px;
    --ui-size-5-xl: 152px;
    --radius-none: 0;
    --radius-1-xs: 2px;
    --radius-s: 4px;
    --radius-m: 6px;
    --radius-l: 8px;
    --radius-1-xl: 8px;
    --radius-2-xl: 16px;
    --radius-3-xl: 24px;
    --radius-4-xl: 32px;
    --radius-5-xl: 40px;
    --radius-full: 9999px;
    --zoom-scale: 1.07;
    --bg-color: var(--white-000);
    --button-disabled-bg-color: var(--sweet-liquorice-200);
    --button-disabled-color: var(--sweet-liquorice-300);
    --button-primary-bg-color: var(--sweet-liquorice-900);
    --button-primary-hover-bg-color: var(--fresh-mint-500);
    --button-primary-active-bg-color: var(--fresh-mint-600);
    --button-primary-hover-negative-bg-color: var(--white-000);
    --button-primary-color: var(--white-000);
    --button-primary-hover-color: var(--sweet-liquorice-900);
    --button-primary-active-color: var(--sweet-liquorice-900);
    --button-secondary-bg-color: var(--white-000);
    --button-secondary-hover-bg-color: var(--fresh-mint-500);
    --button-secondary-active-bg-color: var(--fresh-mint-600);
    --button-secondary-color: var(--sweet-liquorice-900);
    --button-secondary-hover-color: var(--sweet-liquorice-900);
    --button-secondary-active-color: var(--sweet-liquorice-900);
    --button-tertiary-color: var(--sweet-liquorice-900);
    --button-tertiary-hover-color: var(--sweet-liquorice-800);
    --button-tertiary-hover-bg-color: var(--fresh-mint-250);
    --button-tertiary-active-color: var(--sweet-liquorice-800);
    --button-tertiary-disabled-color: var(--sweet-liquorice-500);
    --button-bubble-hover-bg-color: var(--sweet-liquorice-900);
    --button-bubble-hover-color: var(--white-000);
    --button-bubble-inversed-hover-bg-color: var(--white-000);
    --button-bubble-inversed-hover-color: var(--sweet-liquorice-900);
    --input-bg-color: transparent;
    --input-checked-bg-color: var(--sweet-liquorice-900);
    --input-checked-hover-bg-color: var(--sweet-liquorice-600);
    --input-disabled-bg-color: var(--sweet-liquorice-200);
    --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
    --input-error-checked-bg-color: var(--chewy-cherry-700);
    --input-error-disabled-bg-color: var(--chewy-cherry-300);
    --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);
    --input-border-color: var(--sweet-liquorice-750);
    --input-hover-border-color: var(--sweet-liquorice-800);
    --input-active-border-color: var(--sweet-liquorice-500);
    --input-checked-border-color: var(--sweet-liquorice-750);
    --input-checked-hover-border-color: var(--sweet-liquorice-200);
    --input-focus-border-color: var(--fresh-mint-600);
    --input-focus-checked-border-color: var(--bg-color);
    --input-disabled-border-color: var(--sweet-liquorice-300);
    --input-error-border-color: var(--chewy-cherry-700);
    --input-color: var(--sweet-liquorice-900);
    --input-label-color: var(--sweet-liquorice-900);
    --input-disabled-label-color: var(--sweet-liquorice-250);
    --input-placeholder-color: var(--sweet-liquorice-400);
    --input-placeholder-disabled-color: var(--sweet-liquorice-250);
    --input-caret-color: var(--fresh-mint-700);
    --focus-ring-color: var(--icy-slushie-500);
    --selection-fresh-mint-200: var(--fresh-mint-200);
    --selection-fresh-mint-300: var(--fresh-mint-300);
    --selection-fresh-mint-400: var(--fresh-mint-400);
    --selection-fresh-mint-500: var(--fresh-mint-500);
    --selection-sweet-liquorice: var(--sweet-liquorice-600);
    --selection-chewy-cherry: var(--chewy-cherry-300);
    --selection-sticky-citrus: var(--sticky-citrus-400);
    --selection-blueberry-bubble: var(--blueberry-bubble-300);
    --selection-icy-slushie: var(--icy-slushie-300);
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-1-xl: 32px;
    --spacing-2-xl: 40px;
    --spacing-3-xl: 56px;
    --spacing-4-xl: 80px;
    --spacing-5-xl: 112px;
    --spacing-6-xl: 144px;
    --spacing-7-xl: 216px;
    --aspect-ratio: 16/9;
    font-family: var(--font-stack-base);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --scrollbar-width: 0px;
    --gap: 60px;
    --span: 4;
    --start: 2;
    box-sizing: border-box;
    cursor: none !important;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: initial;
    outline-color: var(--icy-slushie-500);
    font-weight: 500;
    color: var(--sweet-liquorice-750);
    letter-spacing: var(--tracking-normal);
    font-size: var(--body-1-xl);
    line-height: var(--leading-relaxed);
    color: black;
}

.reviews>img {
    height: 50px;
    width: 50px;
    border-radius: 50%;

}

.reviews {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

div>label {
    margin-bottom: 5px;
    margin-left: 25px;
    --ease: cubic-bezier(0.79, 0.14, 0.15, 0.86);
    --ease-in: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    --ease-out: cubic-bezier(0.08, 0.82, 0.17, 1);
    --fast-transition: 100ms;
    --regular-transition: 200ms;
    --slow-transition: 400ms;
    --heading-9-xl: var(--type-scale-9-xl);
    --heading-8-xl: var(--type-scale-8-xl);
    --heading-7-xl: var(--type-scale-7-xl);
    --heading-6-xl: var(--type-scale-6-xl);
    --heading-5-xl: var(--type-scale-5-xl);
    --heading-4-xl: var(--type-scale-4-xl);
    --heading-3-xl: var(--type-scale-3-xl);
    --heading-2-xl: var(--type-scale-2-xl);
    --heading-1-xl: var(--type-scale-1-xl);
    --heading-l: var(--type-scale-l);
    --heading-m: var(--type-scale-m);
    --heading-s: var(--type-scale-s);
    --heading-1-xs: var(--type-scale-1-xs);
    --body-2-xl: var(--type-scale-2-xl);
    --body-1-xl: var(--type-scale-1-xl);
    --body-l: var(--type-scale-l);
    --body-m: var(--type-scale-m);
    --body-s: var(--type-scale-s);
    --body-1-xs: var(--type-scale-1-xs);
    --font-stack-base: "Dazzed", system-ui, -apple-system, "SF Pro Display", "BlinkMacSystemFont", "Helvetica", "Lato", "Arial", "Segoe UI", "Verdana", sans-serif;
    --leading-none: 1;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
    --tracking-tighter: -.05em;
    --tracking-tight: -.025em;
    --tracking-normal: 0;
    --tracking-wide: .025em;
    --tracking-wider: .05em;
    --tracking-widest: .1em;
    --type-scale-9-xl: clamp(9.25rem, 3.625rem + 6.25vw, 11.125rem);
    --type-scale-8-xl: clamp(7.5rem, 3.375rem + 4.5833vw, 8.875rem);
    --type-scale-7-xl: clamp(6rem, 3.375rem + 2.9167vw, 6.875rem);
    --type-scale-6-xl: clamp(4.75rem, 2.875rem + 2.0833vw, 5.375rem);
    --type-scale-5-xl: clamp(3.875rem, 2.375rem + 1.6667vw, 4.375rem);
    --type-scale-4-xl: clamp(3rem, 1.125rem + 2.0833vw, 3.625rem);
    --type-scale-3-xl: clamp(2.5rem, 1.375rem + 1.25vw, 2.875rem);
    --type-scale-2-xl: clamp(2rem, 1.25rem + 0.8333vw, 2.25rem);
    --type-scale-1-xl: clamp(1.5rem, 1.125rem + 0.4167vw, 1.625rem);
    --type-scale-l: clamp(1.25rem, 0.875rem + 0.4167vw, 1.375rem);
    --type-scale-m: clamp(1rem, 0.625rem + 0.4167vw, 1.125rem);
    --type-scale-s: clamp(0.875rem, 0.5rem + 0.4167vw, 1rem);
    --type-scale-1-xs: clamp(0.75rem, 0.375rem + 0.4167vw, 0.875rem);
    --fresh-mint-100: #f9fefe;
    --fresh-mint-100-rgb: 249, 254, 254;
    --fresh-mint-150: azure;
    --fresh-mint-150-rgb: 240, 255, 255;
    --fresh-mint-200: #e8fefe;
    --fresh-mint-200-rgb: 232, 254, 254;
    --fresh-mint-250: #ccfdfd;
    --fresh-mint-250-rgb: 204, 253, 253;
    --fresh-mint-300: #b1fcfb;
    --fresh-mint-300-rgb: 177, 252, 251;
    --fresh-mint-400: #93f9f8;
    --fresh-mint-400-rgb: 147, 249, 248;
    --fresh-mint-500: #51f0ed;
    --fresh-mint-500-rgb: 81, 240, 237;
    --fresh-mint-600: #18dcd9;
    --fresh-mint-600-rgb: 24, 220, 217;
    --fresh-mint-700: #04afac;
    --fresh-mint-700-rgb: 4, 175, 172;
    --fresh-mint-800: #0d8c8a;
    --fresh-mint-800-rgb: 13, 140, 138;
    --fresh-mint-900: #00615f;
    --fresh-mint-900-rgb: 0, 97, 95;
    --fresh-mint-950: #004241;
    --fresh-mint-950-rgb: 0, 66, 65;
    --sweet-liquorice-100: #f5f7fa;
    --sweet-liquorice-100-rgb: 245, 247, 250;
    --sweet-liquorice-200: #e7ecf0;
    --sweet-liquorice-200-rgb: 231, 236, 240;
    --sweet-liquorice-250: #a6b2c5;
    --sweet-liquorice-250-rgb: 166, 178, 197;
    --sweet-liquorice-300: white;
    --sweet-liquorice-300-rgb: 210, 216, 223;
    --sweet-liquorice-400: #abb3ba;
    --sweet-liquorice-400-rgb: 171, 179, 186;
    --sweet-liquorice-500: #888f96;
    --sweet-liquorice-500-rgb: 136, 143, 150;
    --sweet-liquorice-600: #6c737a;
    --sweet-liquorice-600-rgb: 108, 115, 122;
    --sweet-liquorice-700: #41484f;
    --sweet-liquorice-700-rgb: 65, 72, 79;
    --sweet-liquorice-750: #31373d;
    --sweet-liquorice-750-rgb: 49, 55, 61;
    --sweet-liquorice-800: #20262c;
    --sweet-liquorice-800-rgb: 32, 38, 44;
    --sweet-liquorice-900: white;
    --sweet-liquorice-900-rgb: 17, 23, 29;
    --icy-slushie-050: #f9fafe;
    --icy-slushie-050-rgb: 249, 250, 254;
    --icy-slushie-100: #f5f7ff;
    --icy-slushie-100-rgb: 245, 247, 255;
    --icy-slushie-200: #e7ecfd;
    --icy-slushie-200-rgb: 231, 236, 253;
    --icy-slushie-250: #cfd9fc;
    --icy-slushie-250-rgb: 207, 217, 252;
    --icy-slushie-300: #a1b6fc;
    --icy-slushie-300-rgb: 161, 182, 252;
    --icy-slushie-400: #4c76ff;
    --icy-slushie-400-rgb: 76, 118, 255;
    --icy-slushie-500: #295bff;
    --icy-slushie-500-rgb: 41, 91, 255;
    --icy-slushie-600: #1d4add;
    --icy-slushie-600-rgb: 29, 74, 221;
    --icy-slushie-700: #0f36b8;
    --icy-slushie-700-rgb: 15, 54, 184;
    --icy-slushie-800: #01228e;
    --icy-slushie-800-rgb: 1, 34, 142;
    --icy-slushie-900: #011760;
    --icy-slushie-900-rgb: 1, 23, 96;
    --sticky-citrus-050: #fffef7;
    --sticky-citrus-050-rgb: 255, 254, 247;
    --sticky-citrus-100: #fffced;
    --sticky-citrus-100-rgb: 255, 252, 237;
    --sticky-citrus-200: #fff5c2;
    --sticky-citrus-200-rgb: 255, 245, 194;
    --sticky-citrus-250: #ffec9c;
    --sticky-citrus-250-rgb: 255, 236, 156;
    --sticky-citrus-300: #ffe371;
    --sticky-citrus-300-rgb: 255, 227, 113;
    --sticky-citrus-400: #ffdb4a;
    --sticky-citrus-400-rgb: 255, 219, 74;
    --sticky-citrus-500: #ffcf10;
    --sticky-citrus-500-rgb: 255, 207, 16;
    --sticky-citrus-600: #eaab00;
    --sticky-citrus-600-rgb: 234, 171, 0;
    --sticky-citrus-700: #da9500;
    --sticky-citrus-700-rgb: 218, 149, 0;
    --sticky-citrus-800: #b07300;
    --sticky-citrus-800-rgb: 176, 115, 0;
    --sticky-citrus-900: #925200;
    --sticky-citrus-900-rgb: 146, 82, 0;
    --chewy-cherry-050: #fff7fb;
    --chewy-cherry-050-rgb: 255, 247, 251;
    --chewy-cherry-100: #fceef5;
    --chewy-cherry-100-rgb: 252, 238, 245;
    --chewy-cherry-200: #ffd7e7;
    --chewy-cherry-200-rgb: 255, 215, 231;
    --chewy-cherry-250: #ffc7de;
    --chewy-cherry-250-rgb: 255, 199, 222;
    --chewy-cherry-300: #ffa4c1;
    --chewy-cherry-300-rgb: 255, 164, 193;
    --chewy-cherry-400: #ff80a8;
    --chewy-cherry-400-rgb: 255, 128, 168;
    --chewy-cherry-500: #f45a8b;
    --chewy-cherry-500-rgb: 244, 90, 139;
    --chewy-cherry-600: #e3356c;
    --chewy-cherry-600-rgb: 227, 53, 108;
    --chewy-cherry-700: #c12554;
    --chewy-cherry-700-rgb: 193, 37, 84;
    --chewy-cherry-800: #8f002b;
    --chewy-cherry-800-rgb: 143, 0, 43;
    --chewy-cherry-900: #780028;
    --chewy-cherry-900-rgb: 120, 0, 40;
    --blueberry-bubble-050: #fbf7ff;
    --blueberry-bubble-050-rgb: 251, 247, 255;
    --blueberry-bubble-100: #f4ecff;
    --blueberry-bubble-100-rgb: 244, 236, 255;
    --blueberry-bubble-200: #e9d7ff;
    --blueberry-bubble-200-rgb: 233, 215, 255;
    --blueberry-bubble-250: #e1c7ff;
    --blueberry-bubble-250-rgb: 225, 199, 255;
    --blueberry-bubble-300: #cea4ff;
    --blueberry-bubble-300-rgb: 206, 164, 255;
    --blueberry-bubble-400: #bd80ff;
    --blueberry-bubble-400-rgb: 189, 128, 255;
    --blueberry-bubble-500: #a45af4;
    --blueberry-bubble-500-rgb: 164, 90, 244;
    --blueberry-bubble-600: #8c35e3;
    --blueberry-bubble-600-rgb: 140, 53, 227;
    --blueberry-bubble-700: #7325c1;
    --blueberry-bubble-700-rgb: 115, 37, 193;
    --blueberry-bubble-800: #570ba4;
    --blueberry-bubble-800-rgb: 87, 11, 164;
    --blueberry-bubble-900: #3c0078;
    --blueberry-bubble-900-rgb: 60, 0, 120;
    --error-100: #fff7f8;
    --error-100-rgb: 255, 247, 248;
    --error-300: #ffa0ac;
    --error-300-rgb: 255, 160, 172;
    --error-500: #e8374c;
    --error-500-rgb: 232, 55, 76;
    --error-700: #720b18;
    --error-700-rgb: 114, 11, 24;
    --error-900: #410008;
    --error-900-rgb: 65, 0, 8;
    --success-100: #fbfff7;
    --success-100-rgb: 251, 255, 247;
    --success-300: #d0ffa0;
    --success-300-rgb: 208, 255, 160;
    --success-500: #8fda44;
    --success-500-rgb: 143, 218, 68;
    --success-700: #438006;
    --success-700-rgb: 67, 128, 6;
    --success-900: #204100;
    --success-900-rgb: 32, 65, 0;
    --white-000: #fff;
    --white-000-rgb: 255, 255, 255;
    --black-000: #000;
    --black-000-rgb: 0, 0, 0;
    --gray-000: #777;
    --gray-000-rgb: 119, 119, 119;
    --ui-size-4-xs: 12px;
    --ui-size-3-xs: 16px;
    --ui-size-2-xs: 20px;
    --ui-size-1-xs: 24px;
    --ui-size-s: 32px;
    --ui-size-m: 48px;
    --ui-size-l: 56px;
    --ui-size-1-xl: 64px;
    --ui-size-2-xl: 80px;
    --ui-size-3-xl: 96px;
    --ui-size-4-xl: 112px;
    --ui-size-5-xl: 152px;
    --radius-none: 0;
    --radius-1-xs: 2px;
    --radius-s: 4px;
    --radius-m: 6px;
    --radius-l: 8px;
    --radius-1-xl: 8px;
    --radius-2-xl: 16px;
    --radius-3-xl: 24px;
    --radius-4-xl: 32px;
    --radius-5-xl: 40px;
    --radius-full: 9999px;
    --zoom-scale: 1.07;
    --bg-color: var(--white-000);
    --button-disabled-bg-color: var(--sweet-liquorice-200);
    --button-disabled-color: var(--sweet-liquorice-300);
    --button-primary-bg-color: var(--sweet-liquorice-900);
    --button-primary-hover-bg-color: var(--fresh-mint-500);
    --button-primary-active-bg-color: var(--fresh-mint-600);
    --button-primary-hover-negative-bg-color: var(--white-000);
    --button-primary-color: var(--white-000);
    --button-primary-hover-color: var(--sweet-liquorice-900);
    --button-primary-active-color: var(--sweet-liquorice-900);
    --button-secondary-bg-color: var(--white-000);
    --button-secondary-hover-bg-color: var(--fresh-mint-500);
    --button-secondary-active-bg-color: var(--fresh-mint-600);
    --button-secondary-color: var(--sweet-liquorice-900);
    --button-secondary-hover-color: var(--sweet-liquorice-900);
    --button-secondary-active-color: var(--sweet-liquorice-900);
    --button-tertiary-color: var(--sweet-liquorice-900);
    --button-tertiary-hover-color: var(--sweet-liquorice-800);
    --button-tertiary-hover-bg-color: var(--fresh-mint-250);
    --button-tertiary-active-color: var(--sweet-liquorice-800);
    --button-tertiary-disabled-color: var(--sweet-liquorice-500);
    --button-bubble-hover-bg-color: var(--sweet-liquorice-900);
    --button-bubble-hover-color: var(--white-000);
    --button-bubble-inversed-hover-bg-color: var(--white-000);
    --button-bubble-inversed-hover-color: var(--sweet-liquorice-900);
    --input-bg-color: transparent;
    --input-checked-bg-color: var(--sweet-liquorice-900);
    --input-checked-hover-bg-color: var(--sweet-liquorice-600);
    --input-disabled-bg-color: var(--sweet-liquorice-200);
    --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
    --input-error-checked-bg-color: var(--chewy-cherry-700);
    --input-error-disabled-bg-color: var(--chewy-cherry-300);
    --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);
    --input-border-color: var(--sweet-liquorice-750);
    --input-hover-border-color: var(--sweet-liquorice-800);
    --input-active-border-color: var(--sweet-liquorice-500);
    --input-checked-border-color: var(--sweet-liquorice-750);
    --input-checked-hover-border-color: var(--sweet-liquorice-200);
    --input-focus-border-color: var(--fresh-mint-600);
    --input-focus-checked-border-color: var(--bg-color);
    --input-disabled-border-color: var(--sweet-liquorice-300);
    --input-error-border-color: var(--chewy-cherry-700);
    --input-color: var(--sweet-liquorice-900);
    --input-label-color: var(--sweet-liquorice-900);
    --input-disabled-label-color: var(--sweet-liquorice-250);
    --input-placeholder-color: var(--sweet-liquorice-400);
    --input-placeholder-disabled-color: var(--sweet-liquorice-250);
    --input-caret-color: var(--fresh-mint-700);
    --focus-ring-color: var(--icy-slushie-500);
    --selection-fresh-mint-200: var(--fresh-mint-200);
    --selection-fresh-mint-300: var(--fresh-mint-300);
    --selection-fresh-mint-400: var(--fresh-mint-400);
    --selection-fresh-mint-500: var(--fresh-mint-500);
    --selection-sweet-liquorice: var(--sweet-liquorice-600);
    --selection-chewy-cherry: var(--chewy-cherry-300);
    --selection-sticky-citrus: var(--sticky-citrus-400);
    --selection-blueberry-bubble: var(--blueberry-bubble-300);
    --selection-icy-slushie: var(--icy-slushie-300);
    --spacing-3-xs: 2px;
    --spacing-2-xs: 4px;
    --spacing-1-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-1-xl: 32px;
    --spacing-2-xl: 40px;
    --spacing-3-xl: 56px;
    --spacing-4-xl: 80px;
    --spacing-5-xl: 112px;
    --spacing-6-xl: 144px;
    --spacing-7-xl: 216px;
    --aspect-ratio: 16/9;
    font-family: var(--font-stack-base);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --scrollbar-width: 0px;
    --gap: 60px;
    --span: 7;
    --start: 6;
    box-sizing: border-box;
    cursor: none !important;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: initial;
    outline-color: var(--icy-slushie-500);
    font-weight: 600;
    line-height: var(--leading-tight);
    letter-spacing: var(--tracking-normal);
    font-size: var(--heading-m);
    margin-bottom: var(--spacing-s);
    margin-left: var(--spacing-1-xl);
    display: inline-flex;
    align-items: center;
    color: black;
    min-height: 24px;
}

@media only screen and (max-width: 1100px) {
    .contact_title {
        font-size: 25.5px;
        /* 17px * 1.5 */
        line-height: 31.5px;
        /* 21px * 1.5 */
    }

    .contact_email {
        font-size: 12.75px;
        /* 8.5px * 1.5 */
        line-height: 20.25px;
        /* 13.5px * 1.5 */
    }

    .contact_form>input {
        font-size: 12px;
        /* 8px * 1.5 */
        padding: 9px 12.75px;
        /* 6px * 1.5, 8.5px * 1.5 */
    }

    .contact_button {
        font-size: 11.25px;
        /* 7.5px * 1.5 */
        height: 37.5px;
        /* 25px * 1.5 */
        line-height: 18.75px;
        /* 12.5px * 1.5 */
    }
}

.send_button {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    background-color: black !important;
    height: 60px;
    width: 150px;
    font-weight: 600;
    font-size: 20px;
    color: black !important;
    border-radius: 40px;
    gap: 10px !important;
    position: relative;
    overflow: hidden;
}

.send_button {
    /* Other styles */
    background-color: black;
    /* Initial background color */
    transition: background-color 1s ease;
}

.send_button:hover {
    background-color: #4D5770 !important;
}

.send_button>span {
    color: black;
}


input{
    color: white !important;
}

textarea::placeholder {
    color: white;
}

input::placeholder {
    color: white !important;
}

input {
    background-color: #4D5770 !important;
}

textarea {
    background-color: #4D5770 !important;
}

.first_inputs>div>label {
    margin-bottom: 0;
}

input {
    color: white !important;
}

textarea {
    color: white !important;
}

@media screen and (max-width:500px) {
    .contact_1_main_heading{
        font-size: 30px !important;
        width: 100%;
    }

    .cursorr{
        display: none;
    }
}

