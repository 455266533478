.news_dialogue {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    position: fixed;
    height: 100vh;
    z-index: 2;
    bottom: 0;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(50%);
}

.news_dialogue_main{
    background-color: rgb(249 249 249);
    padding: 30px;
}

.news_dialogue_heading{
    font-size: 24px;
}

.news_dialogue_inner_div{
    background-color: white;
    padding: 20px;
    overflow: auto;
    max-height: 80vh;
}

.news_dialogue_inner_div::-webkit-scrollbar{
    display: none;
}

.news_dialogue_inner_div_parts{
    display: flex;
    align-items: flex-start;
    gap: 40px;
    width: 40vw;
    margin-bottom: 15px;
}

.news_dialogue_inner_div_part1{
    min-width: 35%;
    max-width: 40%;
    font-weight: 600;
}

.news_dialogue_top{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}