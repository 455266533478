/* styles.css */

.privacy-policy {
    font-family: 'Vollkorn', serif;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .privacy-policy-title {
    color: black;
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content {
    line-height: 1.6;
  }
  
  .privacy-policy-content h2 {
    color: black;
    font-size: 24px;
    font-family: 'Larken Demo', sans-serif;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-content ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .privacy-policy-content ul li {
    margin-bottom: 10px;
    color: black;
  }
  
  .privacy-policy-content p {
    margin-bottom: 18px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
  }
  

  /* styles.css */

.terms-and-conditions {
    font-family: 'Vollkorn', serif;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .terms-and-conditions-title {
    color: black;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: 'Larken Demo', sans-serif;
  }
  
  .terms-and-conditions-content {
    line-height: 1.6;
  }
  
  .terms-and-conditions-content h2{
    color: black;
    font-size: 24px;
    margin-top: 30px;
    font-family: 'Larken Demo', sans-serif;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions-content ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
  }
  
  .terms-and-conditions-content ul li {
    margin-bottom: 10px;
    font-size: 18px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .terms-and-conditions-content p {
    margin-bottom: 15px;
    font-size: 18px;
    color: black;
    font-family: 'Times New Roman', Times, serif;
  }
  

  