body{
  /* overflow-x: hidden; */
}
.App {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App.light-theme {
  color: #333333;
}

.App.dark-theme {
  background-color: #1a1a1a;
}
header {
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.5), 0 1px 1px rgba(139, 30, 63, 0.5);
  /* border-bottom: .1px solid #23395B; */
}

.containerH {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem; /* Adjust padding as needed */
  width: 100%;
  min-width: 100vw;
  top: 0;
position: fixed;
z-index: 999;
}

.containerH div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem; /* Adjust padding as needed */
  
}

ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 0 1rem; 
}

ul li a {
  text-decoration: none;
  font-weight: 400;
  color: inherit; 
}
ul li a :active {
  text-decoration: none;
  font-weight: 400;
  color: inherit; 
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.auth li a {
  border-radius: 20px;
  padding: 10px 20px;

  border: 1px solid #23395B;
}

.auth li a:active, .auth li a:focus , .active{
 
  border: 1px solid #23395B;
  background-color: #8B1E3F;
  color: azure;
}

.text-brand{
  color: #8B1E3F;
}
.bg-brand{
  border: 1px solid #23395B;
  background-color: #8B1E3F !important;
  color: azure;
}

.fa-bars{
  display: none;
}
.bg-img {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10em;
  width: fit-content;
  padding: 1em 4.5em;
  z-index: 1;
}

.bg-img > div h6,
.bg-img > div p {
  position: relative;
  visibility: hidden; 
  opacity: 0;
  transition: visibility 2s, opacity 0.5s; 
}

.bg-img:hover > div h6,
.bg-img:hover > div p {
  visibility: visible; 
  opacity: 1; 
}
.bg-img:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8b1e3fa6;
  animation: revealOverlay 1s ease forwards;
}
.invert{
  filter: invert(51%);
}
@keyframes revealOverlay {
  from {
    top: 100%; 
  }
  to {
    top: 0; 
  }
}

.bg-img:hover::before {
  top: 0; 
}

.vrbg-h1{
  padding: 0;
  padding-left: 29em;
  margin-top:-4em;
}

.carousel-item{
  background-color: transparent !important;
}

@media screen and (max-width: 1250px) {
  .containerH div ul {
    display: none;
  }
.containerH{
  /* position: relative; */
}
  .menubar-visible {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff; /* Adjust background color as needed */
    padding: 1rem; /* Adjust padding as needed */
    border: 1px solid #23395B; /* Adjust border as needed */
    z-index: 1000; /* Adjust z-index as needed */
  }
  
  .fa-bars {
    display: block;
  }

  
  .vrbg-h1{
  padding: 1em;
    margin-top:0;
    background-color: #23395B;
    border: none;
    font-size: 1.2em;
  }

}

/* .app {
  padding: 10px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flair {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
} */

.ball {
  position: fixed;
  /* background-color: yellow; */
  border-radius: 50%;
  z-index: 999;
  
}

.rotate {
  animation: rotation 20s infinite both;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}