.whatwedo_main_component {
    background: white;
}

.what_banner_main {
    height: 550px;
    width: 100vw;
    background-image: url('../../Assets/About/whatwedo.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.what_banner_heading {
    font-family: 'Larken Demo', sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -1.0399999618530273px;
    text-align: start;
    color: #CEA165;
    margin: 0;
}

.what_banner_desc {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: start;
    color: #CEA165;
}

.what-upper {
    padding: 70px 10%;
}

.upper_heading {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -0.6000000238418579px;
    text-align: start;
    color: #CEA165;
}

.upper_description {
    font-family: 'Vollkorn', serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: start;
    color: #CEA165;
}

.what_upper_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upper_image_heading {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px;
    font-weight: 400; 
    line-height: 38px;
    letter-spacing: -0.6000000238418579px;
    text-align: left;
    color: #CEA165;
}

.what_upper_image {
    margin: 3% 0 !important;
}

.what_process_img {
    background-image: url('../../Assets/About/what.png');
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-content: flex-end;
}

.image_inner_text_what {
    font-family: 'Larken Demo', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #CEA165;
    padding: 2% 3% 0 0;
}

.youtube_main_component {
    padding: 0 10% 0% 10%;
}

.youtube_content {}

.youtube_content_heading {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: -0.6000000238418579px;
    text-align: left;
    color: #CEA165;
}

.youtube_content_desc {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #CEA165;
}

.indigo {
    height: 100vh;
}

/* @media screen and (max-width: 600px) {
    .what_banner_heading {
        font-size: 26px;
        line-height: 30px;
    }

    .what_banner_desc {
        font-size: 8.5px;
        line-height: 13.5px;
    }

    .upper_heading {
        font-size: 15px;
        line-height: 19px;
    }

    .upper_description {
        font-size: 8.5px;
        line-height: 13.5px;
    }

    .upper_image_heading {
        font-size: 15px;
        line-height: 19px;
    }

    .image_inner_text_what {
        font-size: 7.5px;
        line-height: 7.5px;
    }

    .youtube_content_heading {
        font-size: 15px;
        line-height: 19px;
    }

    .youtube_content_desc {
        font-size: 8.5px;
        line-height: 13.5px;
    }

    .what_process_img {
        height: 50vh;
    }

    .youtube_video>iframe {
        height: 300px !important;
    }

    .video_adjust {
        height: 300px !important;
    }
} */

@media screen and (max-width: 1100px) {
    .what_banner_heading {
        font-size: 39px;
        line-height: 45px;
    }

    /* .what_banner_desc {
        font-size: 12.75px;
        line-height: 20.25px;
    } */

    /* .upper_heading {
        font-size: 22.5px;
        line-height: 28.5px;
    }

    .upper_description {
        font-size: 12.75px;
        line-height: 20.25px;
    }

    .upper_image_heading {
        font-size: 22.5px;
        line-height: 28.5px;
    }

    .image_inner_text_what {
        font-size: 5.625px;
        line-height: 5.625px;
    }

    .youtube_content_heading {
        font-size: 22.5px;
        line-height: 28.5px;
    }

    .youtube_content_desc {
        font-size: 12.75px;
        line-height: 20.25px;
    }

    .what_process_img {
        height: 70vh !important;
    } */

    .indigo {
        height: 70vh;
    }

    .youtube_video>iframe {
        height: 450px !important;
    }

    .video_adjust {
        height: 450px !important;
    }
}

.react-multi-carousel-list {
    height: 550px;
    width: 80vw;
    border-radius: 12px;
    display: flex;
    gap: 0;
    margin: 0 !important;
    padding: 0 !important;
    /* justify-content: flex-start;
    align-items: center; */
    overflow: hidden;
}

.react-multi-carousel-list>ul {
    height: 100%;
    /* position: relative;
    left: -7%; */
    gap: 0 !important;
    margin: 0 !important;
    padding: 0;
    /* width: 100%;
    
    overflow: ; */
}

.react-multi-carousel-list>ul>li {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.react-multi-carousel-list>ul>li>img {
    min-height: 100%;
    max-width: 70vw;
    margin: 0 !important;
    padding: 0 !important;
}

/* For font size less than 24px, change color to white */
.what_banner_desc,
.upper_description1,
.image_inner_text_what,
.youtube_content_desc {
    color: black;
    /* Change color to white */
}

@media screen and (max-width:500px) {
    .react-multi-carousel-list{
        height: 350px;
    }

    .carousel_inner_p{
        font-size: 12px !important;
    }

    .headding{
        font-size: 24px !important;
        margin-top: 20px !important;
    }
}

/* @media screen and (max-width: 500px) {
    .what_banner_heading {
        font-size: 21px;
        line-height: 30px; 
    }

    .what_banner_desc,
    .upper_description,
    .upper_image_heading,
    .image_inner_text_what,
    .youtube_content_heading,
    .youtube_content_desc {
        font-size: 9px;
        line-height: 14px; 
    }
} */
