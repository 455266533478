.about_banner_main_div {
    background-image: url('../../Assets/About/about_banner.png');
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_banner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 0px;
}

.about_banner_heading {
    font-family: 'Guardian Sans', sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: -1.2000000476837158px;
    text-align: center;
    color: #C99652;
    margin: 0;
}

.about_banner_text {
    font-family: 'Guardian Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #C99652;
}
 
.about_main_component {
    margin: 5% 15% 0 15%;
    padding-bottom: 5%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.about_main_inner_div {
    min-height: 450px;
    width: 440px;
    border-radius: 8px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    background-position: center;
    background-size: 440px 500px;
    flex-direction: column;
}

.about_main_title {
    font-family: 'Guardian Sans', sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: -0.8399999737739563px;
    text-align: center;
    color: #C99652;
    margin: 0;
}

.about_main_desc {
    font-family: 'Guardian Sans', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #40462C;
}

@media screen and (max-width:1100px) {
    .about_main_inner_div {
        min-height: 400px;
        width: 340px;
        justify-content: center;
        background-position: center;
        background-size: 340px 400px;
    }
}

@media screen and (max-width: 600px) {
    .about_banner_heading {
        font-size: 30px;
        line-height: 34px;
    }

    .about_banner_text {
        font-size: 7px;
        line-height: 13.5px;
    }

    .about_main_title {
        font-size: 21px;
        line-height: 25px;
    }

    .about_main_desc {
        font-size: 8.5px;
        line-height: 13.5px;
    }
}

@media screen and (max-width: 1100px) {
    .about_banner_heading {
        font-size: 45px;
        line-height: 51px;
    }

    .about_banner_text {
        font-size: 10.5px;
        line-height: 20.25px;
    }

    .about_main_title {
        font-size: 31.5px;
        line-height: 37.5px;
    }

    .about_main_desc {
        font-size: 12.75px;
        line-height: 20.25px;
    }
}
