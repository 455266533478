.story_banner_main_component {
    width: 100vw;
    height: 80vh;
    background-color: white;
}

.banner_video {
    width: 100vw;
    height: 80vh;
}

.story_main_component {
    width: 100vw;
    background-color: white;
}

.story_main_componentt {
    padding: 5% 10% 0 10%;
}

.story_heading {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px !important;
    font-weight: 400;
    text-align: start;
    line-height: 46px;
    letter-spacing: -0.7599999904632568px;
    color: black;
    margin: 0;
}

.story_content {
    width: 50%;
    min-width: 300px;
}

.story_year {
    font-family: 'Guardian Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 1.2000000476837158px;
    text-align: left;
    color: #CEA165;
}

.story_desc {
    font-family: 'Vollkorn', serif;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: start;
    color: black;
}

.story_box {
    display: flex;
    width: 100%;
    gap: 5%;
    flex-wrap: wrap-reverse;
    margin: 5% 0;
    align-items: center;
    min-height: 300px;

}

.story_boxx {
    display: flex;
    width: 100%;
    gap: 5%;
    flex-wrap: wrap;
    margin: 5% 0;
    align-items: center;
    min-height: 300px;
}

.story_img {
    width: 44%;
    min-width: 300px;
    /* background-color: rgb(191, 185, 185); */
    padding: 10px;
}

.story_img>img {
    width: 100%;
    height: auto;
}

@media screen and (max-width:1000px) {
    .story_img {
        width: 100%;
    }

    .story_content {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .story_heading {
        font-size: 19px;
        line-height: 23px;
    }

    .story_year {
        font-size: 6px;
        line-height: 9.5px;
    }

    .story_desc {
        font-size: 8.5px;
        line-height: 13.5px;
    }

    .story_box {
        justify-content: center;
    }

    .story_boxx {
        justify-content: center;
    }
}

.story_heading1 {
    /* margin: 0; */
    /* Remove default margins */
    overflow: hidden; /* Ensure the text is hidden initially */
    white-space: pre-wrap; /* Allow line breaks */
    display: inline-block;
    animation: touch-type 3s steps(40, end) forwards ; /* Apply animation to reveal text */
}

.story_heading1 .overflowing {
    white-space: normal; /* Allow line breaks when text overflows */
}


@keyframes touch-type {
    0% {
        width: 0; /* Start with zero width */
    }

    100% {
        width: 100%; /* End with full width */
    }
}

@media screen and (max-width: 1100px) {
    .story_heading {
        font-size: 28.5px;
        /* 19px * 1.5 */
        line-height: 34.5px;
        /* 23px * 1.5 */
    }

    .story_year {
        font-size: 9px;
        /* 6px * 1.5 */
        line-height: 14.25px;
        /* 9.5px * 1.5 */
    }

    .story_desc {
        font-size: 12.75px;
        /* 8.5px * 1.5 */
        line-height: 20.25px;
        /* 13.5px * 1.5 */
    }

    .story_box,
    .story_boxx {
        justify-content: center;
        margin: 15% 0;
    }
}