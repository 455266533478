.main{
  height: 100vh;

  .mask, .body{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #afa18f;
    font-size: 64px;
    line-height: 66px;
    cursor: default;
    
    p{
      width: 1000px;
      padding:40px;
    }
    span{
      color: #ec4e39;
    }
  }

  .mask{
    mask-image: url('../../../public/mask.svg');
    mask-repeat: no-repeat;
    mask-size: 40px;
    background: #ec4e39;
    position: absolute;
    z-index: -1;
    color: rgb(184, 17, 17);
  }
}