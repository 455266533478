:root {
    --ease: cubic-bezier(0.79,0.14,0.15,0.86);
    --ease-in: cubic-bezier(0.6,0.04,0.98,0.34);
    --ease-out: cubic-bezier(0.08,0.82,0.17,1);
    --fast-transition: 100ms;
    --regular-transition: 200ms;
    --slow-transition: 400ms;
    --type-scale-9-xl: clamp(3.125rem,1.7857rem + 6.6964vw,5rem);
    --type-scale-8-xl: clamp(2.875rem,1.8929rem + 4.9107vw,4.25rem);
    --type-scale-7-xl: clamp(2.5rem,1.6964rem + 4.0179vw,3.625rem);
    --type-scale-6-xl: clamp(2.25rem,1.7143rem + 2.6786vw,3rem);
    --type-scale-5-xl: clamp(2rem,1.5536rem + 2.2321vw,2.625rem);
    --type-scale-4-xl: clamp(1.75rem,1.3929rem + 1.7857vw,2.25rem);
    --type-scale-3-xl: clamp(1.625rem,1.4464rem + 0.8929vw,1.875rem);
    --type-scale-2-xl: clamp(1.375rem,1.1964rem + 0.8929vw,1.625rem);
    --type-scale-1-xl: clamp(1.25rem,1.1607rem + 0.4464vw,1.375rem);
    --type-scale-l: 1.125rem;
    --type-scale-m: 1rem;
    --type-scale-s: 0.875rem;
    --type-scale-1-xs: 0.8125rem;
    --heading-9-xl: var(--type-scale-9-xl);
    --heading-8-xl: var(--type-scale-8-xl);
    --heading-7-xl: var(--type-scale-7-xl);
    --heading-6-xl: var(--type-scale-6-xl);
    --heading-5-xl: var(--type-scale-5-xl);
    --heading-4-xl: var(--type-scale-4-xl);
    --heading-3-xl: var(--type-scale-3-xl);
    --heading-2-xl: var(--type-scale-2-xl);
    --heading-1-xl: var(--type-scale-1-xl);
    --heading-l: var(--type-scale-l);
    --heading-m: var(--type-scale-m);
    --heading-s: var(--type-scale-s);
    --heading-1-xs: var(--type-scale-1-xs);
    --body-2-xl: var(--type-scale-2-xl);
    --body-1-xl: var(--type-scale-1-xl);
    --body-l: var(--type-scale-l);
    --body-m: var(--type-scale-m);
    --body-s: var(--type-scale-s);
    --body-1-xs: var(--type-scale-1-xs);
    --font-stack-base: "Dazzed",system-ui,-apple-system,"SF Pro Display","BlinkMacSystemFont","Helvetica","Lato","Arial","Segoe UI","Verdana",sans-serif;
    --leading-none: 1;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
    --tracking-tighter: -.05em;
    --tracking-tight: -.025em;
    --tracking-normal: 0;
    --tracking-wide: .025em;
    --tracking-wider: .05em;
    --tracking-widest: .1em
}

@media screen and (min-width: 768px) {
    :root {
        --type-scale-9-xl:clamp(5rem,0.1429rem + 10.119vw,9.25rem);
        --type-scale-8-xl: clamp(4.25rem,0.5357rem + 7.7381vw,7.5rem);
        --type-scale-7-xl: clamp(3.625rem,0.9107rem + 5.6548vw,6rem);
        --type-scale-6-xl: clamp(3rem,1rem + 4.1667vw,4.75rem);
        --type-scale-5-xl: clamp(2.625rem,1.1964rem + 2.9762vw,3.875rem);
        --type-scale-4-xl: clamp(2.25rem,1.3929rem + 1.7857vw,3rem);
        --type-scale-3-xl: clamp(1.875rem,1.1607rem + 1.4881vw,2.5rem);
        --type-scale-2-xl: clamp(1.625rem,1.1964rem + 0.8929vw,2rem);
        --type-scale-1-xl: clamp(1.375rem,1.2321rem + 0.2976vw,1.5rem);
        --type-scale-l: clamp(1.125rem,0.9821rem + 0.2976vw,1.25rem);
        --type-scale-m: 1rem;
        --type-scale-s: 0.875rem;
        --type-scale-1-xs: clamp(0.8125rem,0.8839rem + -0.1488vw,0.75rem)
    }
}

@media screen and (min-width: 1440px) {
    :root {
        --type-scale-9-xl:clamp(9.25rem,3.625rem + 6.25vw,11.125rem);
        --type-scale-8-xl: clamp(7.5rem,3.375rem + 4.5833vw,8.875rem);
        --type-scale-7-xl: clamp(6rem,3.375rem + 2.9167vw,6.875rem);
        --type-scale-6-xl: clamp(4.75rem,2.875rem + 2.0833vw,5.375rem);
        --type-scale-5-xl: clamp(3.875rem,2.375rem + 1.6667vw,4.375rem);
        --type-scale-4-xl: clamp(3rem,1.125rem + 2.0833vw,3.625rem);
        --type-scale-3-xl: clamp(2.5rem,1.375rem + 1.25vw,2.875rem);
        --type-scale-2-xl: clamp(2rem,1.25rem + 0.8333vw,2.25rem);
        --type-scale-1-xl: clamp(1.5rem,1.125rem + 0.4167vw,1.625rem);
        --type-scale-l: clamp(1.25rem,0.875rem + 0.4167vw,1.375rem);
        --type-scale-m: clamp(1rem,0.625rem + 0.4167vw,1.125rem);
        --type-scale-s: clamp(0.875rem,0.5rem + 0.4167vw,1rem);
        --type-scale-1-xs: clamp(0.75rem,0.375rem + 0.4167vw,0.875rem)
    }
}

:root {
    --fresh-mint-100: #f9fefe;
    --fresh-mint-100-rgb: 249,254,254;
    --fresh-mint-150: azure;
    --fresh-mint-150-rgb: 240,255,255;
    --fresh-mint-200: #e8fefe;
    --fresh-mint-200-rgb: 232,254,254;
    --fresh-mint-250: #ccfdfd;
    --fresh-mint-250-rgb: 204,253,253;
    --fresh-mint-300: #b1fcfb;
    --fresh-mint-300-rgb: 177,252,251;
    --fresh-mint-400: #93f9f8;
    --fresh-mint-400-rgb: 147,249,248;
    --fresh-mint-500: #51f0ed;
    --fresh-mint-500-rgb: 81,240,237;
    --fresh-mint-600: #18dcd9;
    --fresh-mint-600-rgb: 24,220,217;
    --fresh-mint-700: #04afac;
    --fresh-mint-700-rgb: 4,175,172;
    --fresh-mint-800: #0d8c8a;
    --fresh-mint-800-rgb: 13,140,138;
    --fresh-mint-900: #00615f;
    --fresh-mint-900-rgb: 0,97,95;
    --fresh-mint-950: #004241;
    --fresh-mint-950-rgb: 0,66,65;
    --sweet-liquorice-100: #f5f7fa;
    --sweet-liquorice-100-rgb: 245,247,250;
    --sweet-liquorice-200: #e7ecf0;
    --sweet-liquorice-200-rgb: 231,236,240;
    --sweet-liquorice-250: #a6b2c5;
    --sweet-liquorice-250-rgb: 166,178,197;
    --sweet-liquorice-300: #d2d8df;
    --sweet-liquorice-300-rgb: 210,216,223;
    --sweet-liquorice-400: #abb3ba;
    --sweet-liquorice-400-rgb: 171,179,186;
    --sweet-liquorice-500: #888f96;
    --sweet-liquorice-500-rgb: 136,143,150;
    --sweet-liquorice-600: #6c737a;
    --sweet-liquorice-600-rgb: 108,115,122;
    --sweet-liquorice-700: #41484f;
    --sweet-liquorice-700-rgb: 65,72,79;
    --sweet-liquorice-750: #31373d;
    --sweet-liquorice-750-rgb: 49,55,61;
    --sweet-liquorice-800: #20262c;
    --sweet-liquorice-800-rgb: 32,38,44;
    --sweet-liquorice-900: #11171d;
    --sweet-liquorice-900-rgb: 17,23,29;
    --icy-slushie-050: #f9fafe;
    --icy-slushie-050-rgb: 249,250,254;
    --icy-slushie-100: #f5f7ff;
    --icy-slushie-100-rgb: 245,247,255;
    --icy-slushie-200: #e7ecfd;
    --icy-slushie-200-rgb: 231,236,253;
    --icy-slushie-250: #cfd9fc;
    --icy-slushie-250-rgb: 207,217,252;
    --icy-slushie-300: #a1b6fc;
    --icy-slushie-300-rgb: 161,182,252;
    --icy-slushie-400: #4c76ff;
    --icy-slushie-400-rgb: 76,118,255;
    --icy-slushie-500: #295bff;
    --icy-slushie-500-rgb: 41,91,255;
    --icy-slushie-600: #1d4add;
    --icy-slushie-600-rgb: 29,74,221;
    --icy-slushie-700: #0f36b8;
    --icy-slushie-700-rgb: 15,54,184;
    --icy-slushie-800: #01228e;
    --icy-slushie-800-rgb: 1,34,142;
    --icy-slushie-900: #011760;
    --icy-slushie-900-rgb: 1,23,96;
    --sticky-citrus-050: #fffef7;
    --sticky-citrus-050-rgb: 255,254,247;
    --sticky-citrus-100: #fffced;
    --sticky-citrus-100-rgb: 255,252,237;
    --sticky-citrus-200: #fff5c2;
    --sticky-citrus-200-rgb: 255,245,194;
    --sticky-citrus-250: #ffec9c;
    --sticky-citrus-250-rgb: 255,236,156;
    --sticky-citrus-300: #ffe371;
    --sticky-citrus-300-rgb: 255,227,113;
    --sticky-citrus-400: #ffdb4a;
    --sticky-citrus-400-rgb: 255,219,74;
    --sticky-citrus-500: #ffcf10;
    --sticky-citrus-500-rgb: 255,207,16;
    --sticky-citrus-600: #eaab00;
    --sticky-citrus-600-rgb: 234,171,0;
    --sticky-citrus-700: #da9500;
    --sticky-citrus-700-rgb: 218,149,0;
    --sticky-citrus-800: #b07300;
    --sticky-citrus-800-rgb: 176,115,0;
    --sticky-citrus-900: #925200;
    --sticky-citrus-900-rgb: 146,82,0;
    --chewy-cherry-050: #fff7fb;
    --chewy-cherry-050-rgb: 255,247,251;
    --chewy-cherry-100: #fceef5;
    --chewy-cherry-100-rgb: 252,238,245;
    --chewy-cherry-200: #ffd7e7;
    --chewy-cherry-200-rgb: 255,215,231;
    --chewy-cherry-250: #ffc7de;
    --chewy-cherry-250-rgb: 255,199,222;
    --chewy-cherry-300: #ffa4c1;
    --chewy-cherry-300-rgb: 255,164,193;
    --chewy-cherry-400: #ff80a8;
    --chewy-cherry-400-rgb: 255,128,168;
    --chewy-cherry-500: #f45a8b;
    --chewy-cherry-500-rgb: 244,90,139;
    --chewy-cherry-600: #e3356c;
    --chewy-cherry-600-rgb: 227,53,108;
    --chewy-cherry-700: #c12554;
    --chewy-cherry-700-rgb: 193,37,84;
    --chewy-cherry-800: #8f002b;
    --chewy-cherry-800-rgb: 143,0,43;
    --chewy-cherry-900: #780028;
    --chewy-cherry-900-rgb: 120,0,40;
    --blueberry-bubble-050: #fbf7ff;
    --blueberry-bubble-050-rgb: 251,247,255;
    --blueberry-bubble-100: #f4ecff;
    --blueberry-bubble-100-rgb: 244,236,255;
    --blueberry-bubble-200: #e9d7ff;
    --blueberry-bubble-200-rgb: 233,215,255;
    --blueberry-bubble-250: #e1c7ff;
    --blueberry-bubble-250-rgb: 225,199,255;
    --blueberry-bubble-300: #cea4ff;
    --blueberry-bubble-300-rgb: 206,164,255;
    --blueberry-bubble-400: #bd80ff;
    --blueberry-bubble-400-rgb: 189,128,255;
    --blueberry-bubble-500: #a45af4;
    --blueberry-bubble-500-rgb: 164,90,244;
    --blueberry-bubble-600: #8c35e3;
    --blueberry-bubble-600-rgb: 140,53,227;
    --blueberry-bubble-700: #7325c1;
    --blueberry-bubble-700-rgb: 115,37,193;
    --blueberry-bubble-800: #570ba4;
    --blueberry-bubble-800-rgb: 87,11,164;
    --blueberry-bubble-900: #3c0078;
    --blueberry-bubble-900-rgb: 60,0,120;
    --error-100: #fff7f8;
    --error-100-rgb: 255,247,248;
    --error-300: #ffa0ac;
    --error-300-rgb: 255,160,172;
    --error-500: #e8374c;
    --error-500-rgb: 232,55,76;
    --error-700: #720b18;
    --error-700-rgb: 114,11,24;
    --error-900: #410008;
    --error-900-rgb: 65,0,8;
    --success-100: #fbfff7;
    --success-100-rgb: 251,255,247;
    --success-300: #d0ffa0;
    --success-300-rgb: 208,255,160;
    --success-500: #8fda44;
    --success-500-rgb: 143,218,68;
    --success-700: #438006;
    --success-700-rgb: 67,128,6;
    --success-900: #204100;
    --success-900-rgb: 32,65,0;
    --white-000: #fff;
    --white-000-rgb: 255,255,255;
    --black-000: #020155;
    --black-000-rgb: 0,0,0;
    --gray-000: #777;
    --gray-000-rgb: 119,119,119
}

.heading-1-xl,.heading-2-xl,.heading-3-xl,.heading-4-xl,.heading-5-xl,.heading-6-xl,.heading-7-xl,.heading-8-xl,.heading-9-xl,.heading-l,.heading-m,.heading-s,h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
    color: #020155;
    line-height: var(--leading-tight);
    letter-spacing: var(--tracking-normal)
}

.body-1-xl,.body-2-xl,.body-l,.body-m,.body-s,blockquote,blockquote.streamer,p,p.streamer,q,q.streamer {
    font-weight: 500;
    color: var(--sweet-liquorice-750);
    line-height: var(--leading-normal);
    letter-spacing: var(--tracking-normal)
}

blockquote.quote-1-xl,blockquote.quote-l,p.quote-1-xl,p.quote-l,q.quote-1-xl,q.quote-l {
    font-weight: 500;
    color: #020155;
    line-height: var(--leading-normal);
    letter-spacing: var(--tracking-normal)
}

.textlink-l,.textlink-m,.textlink-s {
    font-weight: 500;
    text-decoration: underline;
    color: #020155;
    line-height: var(--leading-none);
    letter-spacing: var(--tracking-normal)
}

.button-l,.button-m,.button-s {
    font-weight: 600;
    line-height: var(--leading-normal);
    letter-spacing: var(--tracking-normal)
}

.inversed .heading-1-xl,.inversed .heading-2-xl,.inversed .heading-3-xl,.inversed .heading-4-xl,.inversed .heading-5-xl,.inversed .heading-6-xl,.inversed .heading-7-xl,.inversed .heading-8-xl,.inversed .heading-9-xl,.inversed .heading-l,.inversed .heading-m,.inversed .heading-s,.inversed h1,.inversed h2,.inversed h3,.inversed h4,.inversed h5,.inversed h6 {
    color: var(--white-000)
}

.inversed .body-1-xl,.inversed .body-2-xl,.inversed .body-l,.inversed .body-m,.inversed .body-s,.inversed .textlink-l,.inversed .textlink-m,.inversed .textlink-s,.inversed blockquote,.inversed p,.inversed q {
    color: #C99652
}

:root {
    --spacing-3-xs: 0;
    --spacing-2-xs: 2px;
    --spacing-1-xs: 4px;
    --spacing-s: 6px;
    --spacing-m: 8px;
    --spacing-l: 12px;
    --spacing-1-xl: 16px;
    --spacing-2-xl: 24px;
    --spacing-3-xl: 32px;
    --spacing-4-xl: 40px;
    --spacing-5-xl: 56px;
    --spacing-6-xl: 80px;
    --spacing-7-xl: 104px;
    --ui-size-4-xs: 12px;
    --ui-size-3-xs: 16px;
    --ui-size-2-xs: 20px;
    --ui-size-1-xs: 24px;
    --ui-size-s: 32px;
    --ui-size-m: 48px;
    --ui-size-l: 56px;
    --ui-size-1-xl: 64px;
    --ui-size-2-xl: 80px;
    --ui-size-3-xl: 96px;
    --ui-size-4-xl: 112px;
    --ui-size-5-xl: 152px;
    --radius-none: 0;
    --radius-1-xs: 2px;
    --radius-s: 4px;
    --radius-m: 6px;
    --radius-l: 8px;
    --radius-1-xl: 8px;
    --radius-2-xl: 16px;
    --radius-3-xl: 24px;
    --radius-4-xl: 32px;
    --radius-5-xl: 40px;
    --radius-full: 9999px;
    --zoom-scale: 1.07;
    --bg-color: var(--white-000);
    --button-disabled-bg-color: var(--sweet-liquorice-200);
    --button-disabled-color: #C99652;
    --button-primary-bg-color: #020155;
    --button-primary-hover-bg-color: var(--fresh-mint-500);
    --button-primary-active-bg-color: var(--fresh-mint-600);
    --button-primary-hover-negative-bg-color: var(--white-000);
    --button-primary-color: var(--white-000);
    --button-primary-hover-color: #020155;
    --button-primary-active-color: #020155;
    --button-secondary-bg-color: var(--white-000);
    --button-secondary-hover-bg-color: var(--fresh-mint-500);
    --button-secondary-active-bg-color: var(--fresh-mint-600);
    --button-secondary-color: #020155;
    --button-secondary-hover-color: #020155;
    --button-secondary-active-color: #020155;
    --button-tertiary-color: #020155;
    --button-tertiary-hover-color: var(--sweet-liquorice-800);
    --button-tertiary-hover-bg-color: var(--fresh-mint-250);
    --button-tertiary-active-color: var(--sweet-liquorice-800);
    --button-tertiary-disabled-color: var(--sweet-liquorice-500);
    --button-bubble-hover-bg-color: #020155;
    --button-bubble-hover-color: var(--white-000);
    --button-bubble-inversed-hover-bg-color: var(--white-000);
    --button-bubble-inversed-hover-color: #020155;
    --input-bg-color: transparent;
    --input-checked-bg-color: #020155;
    --input-checked-hover-bg-color: var(--sweet-liquorice-600);
    --input-disabled-bg-color: var(--sweet-liquorice-200);
    --input-disabled-checked-bg-color: var(--sweet-liquorice-200);
    --input-error-checked-bg-color: var(--chewy-cherry-700);
    --input-error-disabled-bg-color: var(--chewy-cherry-300);
    --input-error-checked-disabled-bg-color: var(--chewy-cherry-500);
    --input-border-color: var(--sweet-liquorice-750);
    --input-hover-border-color: var(--sweet-liquorice-800);
    --input-active-border-color: var(--sweet-liquorice-500);
    --input-checked-border-color: var(--sweet-liquorice-750);
    --input-checked-hover-border-color: var(--sweet-liquorice-200);
    --input-focus-border-color: var(--fresh-mint-600);
    --input-focus-checked-border-color: var(--bg-color);
    --input-disabled-border-color: #C99652;
    --input-error-border-color: var(--chewy-cherry-700);
    --input-color: #020155;
    --input-label-color: #020155;
    --input-disabled-label-color: var(--sweet-liquorice-250);
    --input-placeholder-color: var(--sweet-liquorice-400);
    --input-placeholder-disabled-color: var(--sweet-liquorice-250);
    --input-caret-color: var(--fresh-mint-700);
    --focus-ring-color: var(--icy-slushie-500);
    --selection-fresh-mint-200: var(--fresh-mint-200);
    --selection-fresh-mint-300: var(--fresh-mint-300);
    --selection-fresh-mint-400: var(--fresh-mint-400);
    --selection-fresh-mint-500: var(--fresh-mint-500);
    --selection-sweet-liquorice: var(--sweet-liquorice-600);
    --selection-chewy-cherry: var(--chewy-cherry-300);
    --selection-sticky-citrus: var(--sticky-citrus-400);
    --selection-blueberry-bubble: var(--blueberry-bubble-300);
    --selection-icy-slushie: var(--icy-slushie-300)
}

@media screen and (min-width: 768px) {
    :root {
        --spacing-3-xs:2px;
        --spacing-2-xs: 4px;
        --spacing-1-xs: 6px;
        --spacing-s: 8px;
        --spacing-m: 12px;
        --spacing-l: 16px;
        --spacing-1-xl: 24px;
        --spacing-2-xl: 32px;
        --spacing-3-xl: 40px;
        --spacing-4-xl: 56px;
        --spacing-5-xl: 72px;
        --spacing-6-xl: 96px;
        --spacing-7-xl: 128px
    }
}

@media screen and (min-width: 1280px) {
    :root {
        --spacing-3-xs:2px;
        --spacing-2-xs: 4px;
        --spacing-1-xs: 6px;
        --spacing-s: 8px;
        --spacing-m: 12px;
        --spacing-l: 16px;
        --spacing-1-xl: 24px;
        --spacing-2-xl: 32px;
        --spacing-3-xl: 48px;
        --spacing-4-xl: 64px;
        --spacing-5-xl: 88px;
        --spacing-6-xl: 128px;
        --spacing-7-xl: 176px
    }
}

@media screen and (min-width: 1650px) {
    :root {
        --spacing-3-xs:2px;
        --spacing-2-xs: 4px;
        --spacing-1-xs: 8px;
        --spacing-s: 12px;
        --spacing-m: 16px;
        --spacing-l: 24px;
        --spacing-1-xl: 32px;
        --spacing-2-xl: 40px;
        --spacing-3-xl: 56px;
        --spacing-4-xl: 80px;
        --spacing-5-xl: 112px;
        --spacing-6-xl: 144px;
        --spacing-7-xl: 216px
    }
}

* {
    box-sizing: border-box
}

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,button,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: initial;
    -moz-osx-font-smoothing: grayscale;
    outline-color: var(--icy-slushie-500)
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display: block
}

ol,ul {
    list-style: none
}

blockquote,q {
    quotes: none
}

blockquote:after,blockquote:before,q:after,q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

img {
    vertical-align: middle
}

a {
    text-decoration: none
}

@media screen and (min-width: 1024px)and (prefers-reduced-motion:no-preference) {
    * {
        /* cursor:none!important */
    }
}

body {
    --scrollbar-width: 0;
    --aspect-ratio: 16/9;
    font-family: var(--font-stack-base);
    background-color: var(--white-000);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    color: var(--black-000);
    padding-right: var(--scrollbar-width);
    overscroll-behavior-y: none;
    max-width: 100vw;
    overflow-x: hidden
}

::-moz-selection {
    background-color: var(--selection-fresh-mint-300)
}

::selection {
    background-color: var(--selection-fresh-mint-300)
}

.aspect-ratio {
    position: relative;
    height: 0;
    padding-bottom: calc(100%/(var(--aspect-ratio)))
}

.aspect-ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
}

blockquote,cite,q {
    font-style: normal
}

hr {
    width: 100%;
    height: 1px;
    background-color: var(--sweet-liquorice-200);
    border: 0;
    margin: 0
}

hr.vertical {
    width: 1px;
    height: 100%
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: 0
}

.js-focus-visible .focus-visible {
    outline: 2px solid var(--icy-slushie-500);
    outline-offset: 2px
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border-width: 0
}

[data-container-size=s] {
    max-width: 1024px
}

[data-container-size=m] {
    max-width: 1280px
}

[data-container-size=l] {
    max-width: 1440px
}

[data-container-size="1-xl"] {
    max-width: 1650px
}

[data-container-size="2-xl"] {
    max-width: 1920px
}

[data-grid] {
    --gap: 60px;
    display: grid;
    grid-gap: var(--gap);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;
    max-width: 1440px
}

[data-grid],[data-grid][data-gap] {
    gap: var(--gap)
}

[data-grid][data-align] {
    place-items: var(--align)
}

@media screen and (min-width: 1650px) {
    [data-grid] {
        max-width:1650px
    }
}

@media screen and (min-width: 1920px) {
    [data-grid] {
        max-width:1920px
    }
}

@media screen and (max-width: 1650px) {
    [data-grid] {
        --gap:56px
    }
}

@media screen and (max-width: 768px) {
    [data-grid] {
        --gap:24px;
        padding-right: 24px;
        padding-left: 24px
    }
}

@media screen and (max-width: 414px) {
    [data-grid] {
        --gap:16px;
        padding-right: 20px;
        padding-left: 20px
    }
}

[data-grid][data-remove-padding] {
    padding-right: 0;
    padding-left: 0
}

@media screen and (max-width: 768px) {
    [data-grid][data-remove-tablet-padding] {
        --gap:24px;
        padding-right: 0;
        padding-left: 0
    }
}

[data-cols="1"] {
    grid-template-columns: repeat(1,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="1"] {
        grid-template-columns:repeat(1,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="1"] {
        grid-template-columns:repeat(1,[col] 1fr)
    }
}

[data-cols="2"] {
    grid-template-columns: repeat(2,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="2"] {
        grid-template-columns:repeat(1,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="2"] {
        grid-template-columns:repeat(1,[col] 1fr)
    }
}

[data-cols="3"] {
    grid-template-columns: repeat(3,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="3"] {
        grid-template-columns:repeat(2,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="3"] {
        grid-template-columns:repeat(1,[col] 1fr)
    }
}

[data-cols="4"] {
    grid-template-columns: repeat(4,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="4"] {
        grid-template-columns:repeat(2,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="4"] {
        grid-template-columns:repeat(2,[col] 1fr)
    }
}

[data-cols="5"] {
    grid-template-columns: repeat(5,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="5"] {
        grid-template-columns:repeat(3,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="5"] {
        grid-template-columns:repeat(2,[col] 1fr)
    }
}

[data-cols="6"] {
    grid-template-columns: repeat(6,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="6"] {
        grid-template-columns:repeat(3,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="6"] {
        grid-template-columns:repeat(2,[col] 1fr)
    }
}

[data-cols="7"] {
    grid-template-columns: repeat(7,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="7"] {
        grid-template-columns:repeat(4,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="7"] {
        grid-template-columns:repeat(3,[col] 1fr)
    }
}

[data-cols="8"] {
    grid-template-columns: repeat(8,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="8"] {
        grid-template-columns:repeat(4,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="8"] {
        grid-template-columns:repeat(3,[col] 1fr)
    }
}

[data-cols="9"] {
    grid-template-columns: repeat(9,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="9"] {
        grid-template-columns:repeat(5,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="9"] {
        grid-template-columns:repeat(3,[col] 1fr)
    }
}

[data-cols="10"] {
    grid-template-columns: repeat(10,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="10"] {
        grid-template-columns:repeat(5,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="10"] {
        grid-template-columns:repeat(4,[col] 1fr)
    }
}

[data-cols="11"] {
    grid-template-columns: repeat(11,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="11"] {
        grid-template-columns:repeat(6,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="11"] {
        grid-template-columns:repeat(4,[col] 1fr)
    }
}

[data-cols="12"] {
    grid-template-columns: repeat(12,[col] 1fr)
}

@media screen and (max-width: 920px) {
    [data-cols="12"] {
        grid-template-columns:repeat(6,[col] 1fr)
    }
}

@media screen and (max-width: 414px) {
    [data-cols="12"] {
        grid-template-columns:repeat(4,[col] 1fr)
    }
}

[data-col] {
    max-width: 100%
}

[data-span="1"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="1"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="2"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="2"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="3"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="3"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="4"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="4"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="5"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="5"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="6"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="6"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="7"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="7"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="8"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="8"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="9"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="9"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="10"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="10"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="11"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="11"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-span="12"] {
    grid-column: span var(--span)/span var(--span);
    grid-column-start: var(--start)
}

@media screen and (min-width: 1650px) {
    [data-span="12"] {
        grid-column:span var(--span-1-xl,var(--span))/span var(--span-1-xl,var(--span));
        grid-column-start: var(--start)
    }
}

[data-end] {
    grid-column-end: var(--end)
}

@media screen and (max-width: 1120px) {
    [data-span="10"],[data-span="11"],[data-span="12"] {
        grid-column:1/-1
    }

    [data-span="1"],[data-span="2"],[data-span="3"] {
        grid-column: span 6/span 6
    }
}

@media screen and (max-width: 920px) {
    [data-span="10"],[data-span="11"],[data-span="12"],[data-span="4"],[data-span="5"],[data-span="6"],[data-span="7"],[data-span="8"],[data-span="9"] {
        grid-column:1/-1
    }

    [data-span="1"],[data-span="2"],[data-span="3"] {
        grid-column: span 3/span 3
    }
}

@media screen and (max-width: 562px) {
    [data-span="1"],[data-span="2"],[data-span="3"] {
        grid-column:1/-1
    }
}

h1 {
    font-size: var(--heading-6-xl)
}

h2 {
    font-size: var(--heading-3-xl)
}

h3 {
    font-size: var(--heading-2-xl)
}

h4 {
    font-size: var(--heading-1-xl)
}

h5 {
    font-size: var(--heading-l)
}

h6 {
    font-size: var(--heading-m)
}

.heading-s {
    font-size: var(--heading-s)
}

.heading-m {
    font-size: var(--heading-m)
}

.heading-l {
    font-size: var(--heading-l)
}

.heading-1-xl {
    font-size: var(--heading-1-xl)
}

.heading-2-xl {
    font-size: var(--heading-2-xl)
}

.heading-3-xl {
    font-size: var(--heading-3-xl)
}

.heading-4-xl {
    font-size: var(--heading-4-xl)
}

.heading-5-xl {
    font-size: var(--heading-5-xl)
}

.heading-6-xl {
    font-size: var(--heading-6-xl)
}

.heading-7-xl {
    font-size: var(--heading-7-xl)
}

.heading-8-xl {
    font-size: var(--heading-8-xl)
}

.heading-9-xl {
    font-size: var(--heading-9-xl)
}

blockquote.streamer,p.streamer,q.streamer {
    font-size: var(--streamer-size);
    font-weight: 500
}

blockquote.quote-1-xl,p.quote-1-xl,q.quote-1-xl {
    font-size: var(--heading-2-xl)
}

blockquote.quote-l,p.quote-l,q.quote-l {
    font-size: var(--heading-l)
}

.body-s {
    font-size: var(--body-s)
}

.body-m {
    font-size: var(--body-m)
}

.body-l {
    font-size: var(--body-l)
}

.body-1-xl {
    font-size: var(--body-1-xl)
}

.body-2-xl {
    font-size: var(--body-2-xl)
}

.textlink-l {
    font-weight: 600
}

.caption {
    font-weight: 500
}

.eyebrow {
    text-transform: uppercase;
    font-weight: 600;
    font-size: var(--heading-s);
    color: var(--sweet-liquorice-500)
}

.footerlink-m,.footerlink-s {
    color: #C99652
}

.button-s {
    font-size: var(--body-s)
}

.button-m {
    font-size: var(--body-m)
}

.button-l {
    font-size: var(--body-l)
}

strong {
    font-weight: 600
}

input::placeholder{
    color: white !important;
}

.underline {
    --accent: #020155;
    --width: 2px;
    --size: 0%;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: currentcolor;
    line-height: var(--leading-normal)
}

.underline:after,.underline:before {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: var(--width);
    border-radius: 1em
}

.underline:before {
    transform: scale(1);
    bottom: 0
}

.underline:after {
    background: var(--accent);
    bottom: 0;
    transform: scaleX(0);
    transition: transform .3s;
    transform-origin: right top
}

.underline:focus {
    outline: none
}

.underline:focus:before {
    opacity: 0
}

.underline:focus:after {
    transform-origin: left top;
    transform: scale(1)
}

@media(hover: hover) {
    .underline:hover:before {
        opacity:0
    }

    .underline:hover:after {
        transform-origin: left top;
        transform: scale(1)
    }
}

.underline-inline {
    --accent: #020155;
    --width: 2px;
    --size: 0%
}

.underline-inline a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    color: var(--sweet-liquorice-750);
    line-height: var(--leading-normal)
}

.underline-inline a:after,.underline-inline a:before {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: var(--width);
    border-radius: 1em;
    background: var(--accent);
    bottom: 0
}

.underline-inline a:before {
    transform: scale(1);
    transition: transform .3s;
    transform-origin: left top
}

.underline-inline a:after {
    transform: scaleX(0);
    transition: transform .3s .2s;
    transform-origin: right top
}

.underline-inline a:focus {
    outline: none
}

.underline-inline a:focus:before {
    transform-origin: left top;
    transform: scale(1)
}

.underline-inline a:focus:after {
    opacity: 0
}

@media(hover: hover) {
    .underline-inline a:hover:before {
        transform-origin:right top;
        transform: scaleX(0)
    }

    .underline-inline a:hover:after {
        transform-origin: left top;
        transform: scale(1)
    }
}

pre {
    padding: var(--spacing-3-xl);
    max-width: 904px;
    margin: auto;
    background: #020155;
    border-radius: var(--radius-1-xs);
    font-size: var(--body-m)
}

@media screen and (max-width: 768px) {
    pre {
        overflow:scroll
    }
}

pre code {
    padding: var(--spacing-1-xs) 0;
    line-height: var(--spacing-1-xl);
    color: var(--icy-slushie-300);
    font-weight: 400;
    font-family: Roboto Mono,monospace
}

pre code span::-moz-selection,pre code::-moz-selection {
    background: var(--sweet-liquorice-750);
    color: var(--sweet-liquorice-200)
}

pre code span::selection,pre code::selection {
    background: var(--sweet-liquorice-750);
    color: var(--sweet-liquorice-200)
}

pre.line-numbers {
    padding-left: var(--spacing-5-xl)!important
}

@media screen and (max-width: 768px) {
    pre.line-numbers {
        padding:var(--spacing-3-xl) var(--spacing-5-xl)!important
    }
}

pre .line-numbers-rows {
    border-right: none!important;
    margin-top: var(--spacing-2-xs)
}

pre .line-numbers-rows>span {
    line-height: var(--spacing-1-xl);
    color: #888f96;
    border: none
}

pre .comment {
    color: #888f96
}

pre .function {
    color: var(--chewy-cherry-400)
}

pre .operator,pre .punctuation {
    color: var(--white-000)
}

pre .parameter {
    color: #a1b6fc
}

pre .number {
    color: #ffdb4a
}

.token.comment {
    color: #3f5169;
    font-style: italic
}

.token.punctuation {
    color: var(--white-000)
}

.token.variable {
    color: #92a7c4
}

.token.constant {
    color: #fff
}

.token.keyword {
    color: var(--white-000)
}

.token.char,.token.number,.token.string {
    color: #d5a27c
}

.token.deleted,.token.tag {
    color: #fe60a1
}

.token.builtin {
    color: #8bd49c
}

.token.inserted,.token.symbo1l {
    color: #7a6ce5
}

.token.changed {
    color: #48c498
}

.token.attr-name {
    color: #c792ea
}

.token.regex {
    color: #08c
}

.token.at-rule,.token.property {
    color: #637b91
}

.token.operator {
    color: var(--sweet-liquorice-400)
}

.token.bold,.token.important {
    font-weight: 700
}

.token.italic {
    font-style: italic
}

.CookieNotice_cookie-notice___Akqt {
    background-color: #020155;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 100;
    padding-right: var(--scrollbar-width)
}

.CookieNotice_container__4qpAZ {
    align-items: center;
    display: grid;
    flex-direction: row;
    grid-gap: var(--spacing-l);
    gap: var(--spacing-l);
    grid-template-columns: auto auto;
    justify-content: space-between;
    padding: var(--spacing-l) 0
}

.CookieNotice_container__4qpAZ p {
    color: #C99652
}

.CookieNotice_container__4qpAZ p::-moz-selection {
    background-color: var(--selection-sweet-liquorice)
}

.CookieNotice_container__4qpAZ p::selection {
    background-color: var(--selection-sweet-liquorice)
}

@media screen and (max-width: 768px) {
    .CookieNotice_container__4qpAZ p {
        grid-area:inherit
    }
}

.CookieNotice_container__4qpAZ p a {
    display: inline-block;
    margin-left: var(--spacing-1-xs)
}

.CookieNotice_container__4qpAZ p a span::-moz-selection {
    background-color: var(--selection-sweet-liquorice)
}

.CookieNotice_container__4qpAZ p a span::selection {
    background-color: var(--selection-sweet-liquorice)
}

@media screen and (max-width: 768px) {
    .CookieNotice_container__4qpAZ p a {
        display:none
    }
}

@media screen and (max-width: 375px) {
    .CookieNotice_container__4qpAZ p a {
        display:inline-block
    }
}

.CookieNotice_container__4qpAZ a {
    display: none
}

@media screen and (max-width: 768px) {
    .CookieNotice_container__4qpAZ a {
        display:block;
        margin-right: auto
    }
}

@media screen and (max-width: 375px) {
    .CookieNotice_container__4qpAZ a {
        display:none
    }
}

@media screen and (max-width: 768px) {
    .CookieNotice_container__4qpAZ {
        grid-template-columns:auto auto
    }
}

.CookieNotice_button-container__REjWd {
    display: grid;
    grid-gap: var(--spacing-l);
    gap: var(--spacing-l);
    grid-template-columns: auto auto
}

@media screen and (max-width: 768px) {
    .CookieNotice_button-container__REjWd {
        margin-left:auto
    }
}

.bubble_bubble__1RIbA {
    --bubble-duration: 400ms;
    --bubble-bg-color: var(--fresh-mint-500);
    --bubble-bg-end-color: var(--fresh-mint-250);
    --bubble-scale-to: 2;
    --bubble-exit-duration: 400ms;
    background-color: var(--bubble-bg-color);
    border-radius: 50%;
    display: block;
    position: absolute;
    pointer-events: none;
    transition: none var(--slow-transition) var(--ease-out);
    transform-origin: center;
    transition-property: transform,background-color,opacity;
    z-index: 0;
    animation: bubble_bubble__1RIbA var(--bubble-duration) var(--ease-out) forwards;
    transform: translateZ(0)
}

.bubble_bubble-exit__dj_28 {
    animation: bubble_bubble__1RIbA var(--bubble-exit-duration) var(--ease) forwards reverse
}

@keyframes bubble_bubble__1RIbA {
    0% {
        transform: translateZ(0) scale(0);
        background-color: var(--bubble-bg-color)
    }

    to {
        transform: translateZ(0) scale(var(--bubble-scale-to));
        background-color: var(--bubble-bg-end-color)
    }
}

.Button_button-content__YAp0j.Button_l__6vnrN,.Button_button-content__YAp0j.Button_m__QruPa,.Button_button-content__YAp0j.Button_s__DdP49 {
    font-weight: 600;
    line-height: var(--leading-normal);
    letter-spacing: var(--tracking-normal)
}

.Button_button__6kS7R {
    --focus-ring-color: #020155;
    background: 0 0;
    border-radius: var(--radius-4-xl);
    -webkit-clip-path: inset(0 0 0 0 round var(--radius-4-xl));
    clip-path: inset(0 0 0 0 round var(--radius-4-xl));
    cursor: pointer;
    display: inline-block;
    font-family: var(--font-stack-base);
    justify-self: center;
    outline: none;
    position: relative;
    text-decoration: none;
    --bubble-duration: .4s
}

.Button_button__6kS7R.Button_inversed__fEqGS {
    --button-disabled-bg-color: var(--sweet-liquorice-750);
    --button-disabled-color: var(--sweet-liquorice-600);
    --button-primary-bg-color: var(--white-000);
    --button-primary-color: #020155;
    --button-secondary-bg-color: #020155;
    --button-secondary-color: var(--white-000);
    --button-tertiary-color: var(--white-000);
    --button-tertiary-hover-color: var(--white-000);
    --button-tertiary-active-color: var(--white-000);
    --button-tertiary-hover-bg-color: var(--sweet-liquorice-700)
}



.Button_button__6kS7R:focus {
    outline: none
}

.Button_button__6kS7R:focus .Button_button-content__YAp0j.Button_primary__3_h20:before,.Button_button__6kS7R:focus .Button_button-content__YAp0j.Button_secondary___atf7:before {
    --border-width: 3px;
    border-color: var(--button-secondary-color)
}

.Button_button__6kS7R:focus .Button_button-content__YAp0j.Button_tertiary__dhcl7 {
    outline: 1px dotted var(--focus-ring-color)
}

.Button_button__6kS7R:focus:not(:focus-visible) .Button_button-content__YAp0j.Button_primary__3_h20:before,.Button_button__6kS7R:focus:not(:focus-visible) .Button_button-content__YAp0j.Button_secondary___atf7:before {
    --border-width: 1px;
    border-color: transparent
}

.Button_button__6kS7R:focus:not(:focus-visible) .Button_button-content__YAp0j.Button_secondary___atf7:before {
    border-color: var(--button-secondary-color)
}

.Button_button__6kS7R:focus:not(:focus-visible) .Button_button-content__YAp0j.Button_tertiary__dhcl7 {
    outline: none
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_primary__3_h20,.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_secondary___atf7 {
    background-color: var(--button-primary-hover-bg-color);
    color: var(--button-primary-hover-color)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_primary__3_h20:before,.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_secondary___atf7:before {
    --border-width: 3px;
    border-color: var(--button-secondary-color)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_icon-only__1XjNv .Button_icon__Q3CYu {
    background-color: var(--button-tertiary-hover-bg-color)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_bubble__kHTFL {
    background-color: var(--button-bubble-inversed-hover-bg-color);
    color: var(--button-bubble-inversed-hover-color)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_bubble__kHTFL:hover {
    background-color: var(--button-bubble-hover-bg-color);
    color: var(--button-bubble-hover-color)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_bubble__kHTFL.Button_inversed__fEqGS {
    background-color: var(--button-bubble-hover-bg-color);
    color: var(--button-bubble-hover-color)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr {
    --width: 3px;
    --accent: #020155
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr:before {
    opacity: 0
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr:after {
    transform-origin: left top;
    transform: scale(1)
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_tertiary__dhcl7:active .Button_button-children__Ww8Sr {
    --width: 1px
}

.Button_button__6kS7R:focus-visible .Button_button-content__YAp0j.Button_tertiary__dhcl7 {
    outline: none
}

.Button_button__6kS7R.Button_tertiary__dhcl7 {
    -webkit-clip-path: none;
    clip-path: none
}

.Button_button-content__YAp0j {
    align-items: center;
    border-radius: var(--radius-4-xl);
    cursor: pointer;
    display: inline-flex;
    gap: var(--spacing-m);
    justify-content: center;
    position: relative;
    transform: translateZ(0);
    transition: none var(--slow-transition) var(--ease-out);
    transition-property: background-color,color;
    white-space: nowrap
}

.Button_button-content__YAp0j span {
    z-index: 99;
    display: flex
}

.Button_button-content__YAp0j .Button_icon__Q3CYu {
    display: flex;
    position: relative;
    transition: background-color var(--regular-transition) ease
}

.Button_button-content__YAp0j.Button_l__6vnrN {
    font-size: var(--body-l);
    gap: var(--spacing-m);
    padding: 0 32px
}

.Button_button-content__YAp0j.Button_l__6vnrN:not(.Button_tertiary__dhcl7) {
    height: var(--ui-size-l)
}

.Button_button-content__YAp0j.Button_l__6vnrN.Button_icon-only__1XjNv {
    padding: 16px
}

.Button_button-content__YAp0j.Button_l__6vnrN .Button_icon__Q3CYu svg {
    height: 24px;
    width: 24px
}

.Button_button-content__YAp0j.Button_m__QruPa {
    font-size: var(--body-m);
    padding: 0 24px
}

.Button_button-content__YAp0j.Button_m__QruPa:not(.Button_tertiary__dhcl7) {
    height: var(--ui-size-m)
}

.Button_button-content__YAp0j.Button_m__QruPa.Button_icon-only__1XjNv {
    padding: 12px
}

.Button_button-content__YAp0j.Button_m__QruPa .Button_icon__Q3CYu svg {
    height: 24px;
    width: 24px
}

.Button_button-content__YAp0j.Button_s__DdP49 {
    font-size: var(--body-s);
    padding: 0 16px
}

.Button_button-content__YAp0j.Button_s__DdP49:not(.Button_tertiary__dhcl7) {
    height: var(--ui-size-s)
}

.Button_button-content__YAp0j.Button_s__DdP49.Button_icon-only__1XjNv {
    padding: 8px
}

.Button_button-content__YAp0j.Button_s__DdP49 .Button_icon__Q3CYu svg {
    height: 16px;
    width: 16px
}

.Button_button-content__YAp0j.Button_icon-only__1XjNv {
    border-radius: 50%
}

.Button_button-content__YAp0j.Button_icon-only__1XjNv.Button_full-width__1t800 {
    width: auto
}

.Button_button-content__YAp0j.Button_primary__3_h20,.Button_button-content__YAp0j.Button_secondary___atf7 {
    position: relative;
    transform-origin: center;
    transition: none var(--slow-transition) var(--ease-out);
    transition-property: transform,background-color,opacity,color
}

.Button_button-content__YAp0j.Button_primary__3_h20:before,.Button_button-content__YAp0j.Button_secondary___atf7:before {
    --border-width: 1px;
    border-radius: var(--radius-4-xl);
    border: var(--border-width) solid transparent;
    content: "";
    height: calc(100% - var(--border-width)*2);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: calc(100% - var(--border-width)*2);
    z-index: 100
}

.Button_button-content__YAp0j.Button_primary__3_h20 {
    background-color: var(--button-primary-bg-color);
    color: var(--button-primary-color)
}

.Button_button-content__YAp0j.Button_primary__3_h20:hover {
    background-color: #51f0ed;
    color: var(--button-primary-hover-color)
}

.Button_button-content__YAp0j.Button_primary__3_h20:active {
    background-color: var(--button-primary-active-bg-color);
    color: var(--button-primary-active-color)
}

.Button_button-content__YAp0j.Button_primary__3_h20:active.Button_disabled__DAAHh {
    background-color: var(--button-disabled-bg-color);
    box-shadow: none;
    color: var(--button-disabled-color)
}

.Button_button-content__YAp0j.Button_primary__3_h20.Button_disabled__DAAHh {
    background-color: var(--button-disabled-bg-color);
    color: var(--button-disabled-color);
    
}

span:hover{
background-color: #C99652 !important;
}

.Button_button-content__YAp0j.Button_primary__3_h20.Button_negative__flxKw:hover {
    background-color: var(--button-primary-hover-negative-bg-color);
    color: var(--button-primary-hover-color)
}

.Button_button-content__YAp0j.Button_secondary___atf7 {
    background-color: var(--button-secondary-bg-color);
    color: var(--button-secondary-color)
}

.Button_button-content__YAp0j.Button_secondary___atf7:before {
    border-color: var(--button-secondary-color)
}

.Button_button-content__YAp0j.Button_secondary___atf7:hover {
    background-color: var(--button-secondary-hover-bg-color);
    color: var(--button-secondary-hover-color)
}

.Button_button-content__YAp0j.Button_secondary___atf7:hover:before {
    border-color: transparent
}

.Button_button-content__YAp0j.Button_secondary___atf7:active {
    background-color: var(--button-secondary-active-bg-color);
    color: var(--button-secondary-active-color)
}

.Button_button-content__YAp0j.Button_secondary___atf7:active.Button_disabled__DAAHh {
    background-color: var(--button-disabled-bg-color);
    border-color: var(--disabled-bg-color);
    box-shadow: none;
    color: var(--button-disabled-color)
}

.Button_button-content__YAp0j.Button_secondary___atf7.Button_disabled__DAAHh {
    background-color: var(--button-disabled-bg-color);
    border-color: var(--disabled-bg-color);
    color: var(--button-disabled-color);
    
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7 {
    border-radius: var(--radius-none);
    color: var(--button-tertiary-color);
    gap: var(--spacing-s);
    padding: 0
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_icon__Q3CYu {
    border-radius: var(--radius-4-xl)
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr {
    --accent: var(--sweet-liquorice-500);
    --width: 1px;
    --size: 0%;
    color: currentcolor;
    display: inline-block;
    line-height: var(--leading-normal);
    position: relative;
    text-decoration: none
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr:after,.Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr:before {
    border-radius: 1em;
    content: "";
    height: var(--width);
    left: 0;
    position: absolute;
    width: 100%
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr:before {
    bottom: -2px;
    transform: scale(1)
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7 .Button_button-children__Ww8Sr:after {
    background: var(--accent);
    bottom: -2px;
    transform-origin: right top;
    transform: scaleX(0);
    transition: transform .3s
}

@media(hover: hover) {
    .Button_button-content__YAp0j.Button_tertiary__dhcl7:hover .Button_button-children__Ww8Sr:before {
        opacity:0
    }

    .Button_button-content__YAp0j.Button_tertiary__dhcl7:hover .Button_button-children__Ww8Sr:after {
        transform-origin: left top;
        transform: scale(1)
    }
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7:hover {
    color: var(--button-tertiary-hover-color)
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7:hover .Button_icon__Q3CYu {
    background-color: var(--button-tertiary-hover-bg-color)
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7:active {
    color: var(--button-tertiary-active-color)
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_disabled__DAAHh,.Button_button-content__YAp0j.Button_tertiary__dhcl7:disabled {
    color: var(--button-tertiary-disabled-color)
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_disabled__DAAHh .Button_icon__Q3CYu,.Button_button-content__YAp0j.Button_tertiary__dhcl7:disabled .Button_icon__Q3CYu {
    background-color: transparent
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_l__6vnrN .Button_icon__Q3CYu {
    padding: 4px
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_l__6vnrN .Button_icon__Q3CYu svg {
    height: 24px;
    width: 24px
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_m__QruPa .Button_icon__Q3CYu {
    padding: 6px
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_m__QruPa .Button_icon__Q3CYu svg {
    height: 16px;
    width: 16px
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_s__DdP49 .Button_icon__Q3CYu {
    padding: 6px
}

.Button_button-content__YAp0j.Button_tertiary__dhcl7.Button_s__DdP49 .Button_icon__Q3CYu svg {
    height: 12px;
    width: 12px
}

.Button_button-content__YAp0j.Button_bubble__kHTFL {
    --main-color: var(--white-000)
}

.Button_button-content__YAp0j.Button_bubble__kHTFL span:not(.Button_blowing__IqSlO) {
    z-index: 110;
    min-width: 24px
}

.Button_button-content__YAp0j.Button_bubble__kHTFL:hover {
    background-color: var(--button-bubble-hover-bg-color);
    color: var(--button-bubble-hover-color)
}

.Button_button-content__YAp0j.Button_bubble__kHTFL:hover .Button_blowing__IqSlO {
    opacity: 0
}

.Button_button-content__YAp0j.Button_bubble__kHTFL.Button_inversed__fEqGS {
    --main-color: #020155
}

.Button_button-content__YAp0j.Button_bubble__kHTFL.Button_inversed__fEqGS:hover {
    background-color: var(--button-bubble-inversed-hover-bg-color);
    color: var(--button-bubble-inversed-hover-color)
}

.Button_button-content__YAp0j.Button_bubble__kHTFL.Button_inversed__fEqGS:hover .Button_blowing__IqSlO {
    opacity: 0
}

.Button_button-content__YAp0j.Button_bubble__kHTFL.Button_inversed__fEqGS:active {
    background-color: var(--button-bubble-inversed-hover-bg-color);
    color: var(--button-bubble-inversed-hover-color)
}

.Button_button-content__YAp0j.Button_bubble__kHTFL .Button_blowing__IqSlO {
    width: 80px;
    height: 80px;
    background-color: var(--main-color);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 101;
    transition: none var(--slow-transition) var(--ease-out);
    transition-property: opacity
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j {
    
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_primary__3_h20,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_primary__3_h20 {
    background-color: var(--button-disabled-bg-color);
    color: var(--button-disabled-color);
    
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_primary__3_h20:active,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_primary__3_h20:active {
    background-color: var(--button-disabled-bg-color);
    box-shadow: none;
    color: var(--button-disabled-color)
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_primary__3_h20 span :before,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_primary__3_h20 span :before {
    border-color: transparent
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_secondary___atf7,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_secondary___atf7 {
    background-color: var(--button-disabled-bg-color);
    border-color: var(--disabled-bg-color);
    color: var(--button-disabled-color);
    
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_secondary___atf7:active,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_secondary___atf7:active {
    background-color: var(--button-disabled-bg-color);
    border-color: var(--disabled-bg-color);
    box-shadow: none;
    color: var(--button-disabled-color)
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_secondary___atf7:before,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_secondary___atf7:before {
    border-color: transparent
}

.Button_button__6kS7R:disabled .Button_button-content__YAp0j.Button_tertiary__dhcl7,.Button_button__6kS7R[aria-disabled=true] .Button_button-content__YAp0j.Button_tertiary__dhcl7 {
    color: var(--button-tertiary-disabled-color)
}

.Button_button__6kS7R:disabled:focus .Button_button-content__YAp0j.Button_secondary___atf7:before,.Button_button__6kS7R:disabled:focus-within .Button_button-content__YAp0j.Button_secondary___atf7:before,.Button_button__6kS7R[aria-disabled=true]:focus .Button_button-content__YAp0j.Button_secondary___atf7:before,.Button_button__6kS7R[aria-disabled=true]:focus-within .Button_button-content__YAp0j.Button_secondary___atf7:before {
    border-color: transparent
}

.Button_full-width__1t800 {
    width: 100%
}

.MainNav_overlay__K7CAX {
    background: rgba(var(--sweet-liquorice-900-rgb),30%);
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0
}

.MainNav_main-nav__segSu {
    --duration: 1s;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 40px);
    max-width: 100%;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 112;
    pointer-events: none
}

.MainNav_main-nav__segSu a,.MainNav_main-nav__segSu button {
    pointer-events: all
}

.MainNav_main-nav__segSu a {
    --accent: var(--fresh-mint-600)
}

.MainNav_main-nav__segSu .MainNav_top-bar__P0qcU>div {
    margin: 0 auto;
    position: relative;
    z-index: 3
}

.MainNav_main-nav__segSu .MainNav_top-bar__P0qcU {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-2-xl) 0;
    position: relative;
    z-index: 3;
    padding-right: var(--scrollbar-width)
}

@media screen and (prefers-reduced-motion:no-preference) {
    .MainNav_main-nav__segSu .MainNav_top-bar__P0qcU {
        transition: none .3s var(--ease-out);
        transition-property: transform,background-color
    }
}

.MainNav_main-nav__segSu .MainNav_bar-container__EeHBR {
    display: flex;
    justify-content: space-between
}

.MainNav_main-nav__segSu .MainNav_logo-mark__yIfQP #MainNav_fresh-mint-mark__c7Vu9 {
    display: none
}

.MainNav_main-nav__segSu .MainNav_logo__RxTaK {
    align-items: center;
    display: flex;
    gap: 12px;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    transition: none .2s var(--ease-out);
    transition-property: opacity
}

.MainNav_main-nav__segSu .MainNav_logo__RxTaK.MainNav_fade__O6pbf {
    opacity: 0
}

.MainNav_main-nav__segSu .MainNav_logo__RxTaK img {
    height: 40px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    vertical-align: middle
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_logo__RxTaK img {
        height:32px;
        width: auto
    }

    .MainNav_main-nav__segSu .MainNav_logo__RxTaK img+img {
        height: 23px;
        width: 130px
    }
}

.MainNav_main-nav__segSu .MainNav_cta__MlGis {
    display: grid;
    grid-gap: var(--ui-size-3-xs);
    gap: var(--ui-size-3-xs);
    grid-template-columns: auto auto
}

.MainNav_main-nav__segSu .MainNav_cta__MlGis .MainNav_start-button__j9F3O {
    z-index: 10
}

@media screen and (max-width: 768px) {
    .MainNav_main-nav__segSu .MainNav_cta__MlGis .MainNav_start-button__j9F3O {
        display:none
    }
}

.MainNav_main-nav__segSu .MainNav_cta__MlGis .MainNav_menu-button__ThdCt {
    z-index: 3;
    min-width: 130px;
    width: 135px
}

.MainNav_main-nav__segSu .MainNav_cta__MlGis .MainNav_menu-button__ThdCt>span>span {
    width: 135px
}

@media screen and (max-width: 768px) {
    .MainNav_main-nav__segSu .MainNav_cta__MlGis {
        gap:0
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ {
    z-index: 2;
    position: fixed;
    width: calc(50vw + var(--scrollbar-width));
    top: 0;
    right: 0;
    height: 100vh;
    height: 100dvh;
    padding-right: var(--scrollbar-width)
}

@media screen and (max-width: 1440px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ {
        width:calc(552px + var(--scrollbar-width))
    }
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ {
        width:100%
    }
}

@media screen and (min-width: 1650px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ {
        width:calc(45vw + var(--scrollbar-width))
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O {
    --offset-right: 0;
    padding-top: var(--offset-top);
    overflow: hidden;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    align-items: baseline;
    position: relative;
    max-width: 720px
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O {
        --offset-top:132px!important
    }
}

@media screen and (max-width: 414px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O {
        grid-template-columns:repeat(8,1fr)
    }
}

@media screen and (max-width: 375px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O {
        grid-template-columns:repeat(5,1fr)
    }
}

@media screen and (min-width: 1650px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O {
        position:absolute;
        top: 0;
        right: var(--offset-right);
        width: calc(100% - var(--offset-right));
        max-width: unset
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_mark__SKpO_ {
    position: absolute;
    top: 36px;
    left: 32px
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_mark__SKpO_ {
        top:26px;
        left: 24px
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_start-button-in-menu__r_dDa {
    position: absolute;
    display: none
}

@media screen and (max-width: 768px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_start-button-in-menu__r_dDa {
        top:24px;
        right: 172px;
        display: block
    }
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_start-button-in-menu__r_dDa {
        display:none
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6 {
    padding: var(--spacing-1-xl) var(--spacing-2-xl)
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6 {
        padding:var(--spacing-2-xl) var(--spacing-4-xl) var(--spacing-2-xl) 0
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_small__XQ9j4 {
    grid-column: 1/3;
    display: grid;
    justify-content: center;
    align-self: baseline
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_small__XQ9j4 {
        justify-content:flex-end
    }
}

@media screen and (max-width: 414px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_small__XQ9j4 {
        grid-column:1/5
    }
}

@media screen and (max-width: 375px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_small__XQ9j4 {
        padding-right:24px;
        grid-column: 1/3
    }

    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_small__XQ9j4 a,.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_small__XQ9j4 a span {
        font-size: var(--body-s)
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_main__SaGxq {
    grid-column: 3/6;
    border-left: 1px solid var(--sweet-liquorice-200)
}

@media screen and (max-width: 414px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_main__SaGxq {
        grid-column:5/9
    }
}

@media screen and (max-width: 375px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_group__yaaV6.MainNav_main__SaGxq {
        padding-right:24px;
        grid-column: 3/6
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_career-count__sQXDc {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-s);
    background-color: var(--fresh-mint-300);
    border-radius: var(--radius-full);
    margin-left: var(--spacing-s);
    font-size: var(--body-s)
}

@media screen and (max-width: 375px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_career-count__sQXDc {
        width:18px;
        height: 18px;
        font-size: 11px!important
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_career-container__dh04c {
    display: flex;
    align-items: center;
    color: var(--button-tertiary-color);
    position: relative
}

@media(hover: hover) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_career-container__dh04c:hover .MainNav_career__DDQ4S:before {
        opacity:0
    }

    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_career-container__dh04c:hover .MainNav_career__DDQ4S:after {
        transform-origin: left top;
        transform: scale(1)
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_career__DDQ4S {
    --accent: var(--fresh-mint-600)
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_main-items__rAOIp {
    display: grid;
    grid-gap: var(--spacing-3-xl);
    gap: var(--spacing-3-xl);
    grid-template-columns: auto;
    text-align: right
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_main-items__rAOIp li {
    overflow: hidden
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_main-items__rAOIp a {
    cursor: pointer
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_sub-items__BPXBX {
    display: grid;
    grid-gap: var(--spacing-2-xl);
    gap: var(--spacing-2-xl);
    grid-template-columns: auto;
    text-align: right
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_sub-items__BPXBX li {
    overflow: hidden
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_sub-items__BPXBX a {
    cursor: pointer
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_footer__opccr {
    display: flex;
    justify-content:flex-end;
    padding: var(--spacing-2-xl);
    grid-column: -1/1;
    align-self:flex-end
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_footer__opccr {
        display:grid;
        align-items: center;
        justify-content: center;
        grid-gap: var(--spacing-2-xl);
        gap: var(--spacing-2-xl);
        position: fixed;
        bottom: var(--spacing-2-xl);
        bottom: calc(env(safe-area-inset-bottom))
    }

    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_footer__opccr>a {
        width: calc(100vw - var(--spacing-2-xl) - var(--spacing-2-xl))
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_banner__IeOQH {
    position: absolute;
    left: 0;
    top: 116px;
    width: calc(100% - 2*var(--spacing-2-xl));
    margin: 0 var(--spacing-2-xl);
    grid-column: -1/1
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_banner__IeOQH {
        top:auto;
        bottom: 160px
    }
}

@media screen and (max-width: 562px)and (max-height:720px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_banner__IeOQH {
        bottom:132px
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_contact__l_fH3 {
    display: none
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_contact__l_fH3 {
        display:block
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 {
    display: inline-grid;
    gap: var(--spacing-2-xl);
    grid-auto-flow: column
}

@media screen and (max-width: 562px) {
    .MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 {
        justify-content:space-around
    }
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 svg {
    width: 24px;
    height: 24px
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 a,.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 li {
    display: flex
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 a {
    color: var(--sweet-liquorice-800);
    transition: color .2s ease;
    font-size: 0
}

.MainNav_main-nav__segSu .MainNav_menu__2dfUZ .MainNav_menu-container__18O5O .MainNav_socials__SUpi8 a:hover {
    color: #020155
}

.MainNav_menu__2dfUZ {
    pointer-events: none;
    -webkit-clip-path: circle(0 at 100% 0);
    clip-path: circle(0 at 100% 0);
    background-color: #C99652;
    transition: none 1s var(--ease);
    transition-property: background-color,-webkit-clip-path;
    transition-property: clip-path,background-color;
    transition-property: clip-path,background-color,-webkit-clip-path
}

.MainNav_main-items__rAOIp li a:before {
    border-radius: 0;
    box-sizing: initial;
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    padding: .5ch 2ch;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%
}

.MainNav_is-expanded__wX_gU .MainNav_main-nav__segSu {
    background-color: var(--white-000)
}

.MainNav_is-expanded__wX_gU .MainNav_menu__2dfUZ {
    -webkit-clip-path: circle(150% at 100% 0);
    clip-path: circle(150% at 100% 0);
    background-color: var(--white-000);
    pointer-events: auto
}

.MainNav_is-expanded__wX_gU .blowing {
    opacity: 0
}

@keyframes Banner_appear__hna5J {
    0% {
        opacity: 0;
        transform: translate3d(0,30px,0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.Banner_banner__C1zqn {
    background-color: var(--fresh-mint-200);
    padding: var(--spacing-l);
    width: 100%;
    border-radius: var(--radius-1-xs);
    display: inline-grid;
    grid-template-columns: 1fr 48px;
    align-items: center;
    gap: var(--spacing-l)
}

@media screen and (max-width: 562px) {
    .Banner_banner__C1zqn {
        padding:var(--spacing-2-xl) var(--spacing-1-xl)
    }
}

.Banner_banner__C1zqn:before {
    content: "";
    left: 412px;
    top: -11px;
    width: 23px;
    height: 23px;
    transform: rotate(45deg);
    border-radius: var(--radius-1-xs);
    background-color: var(--fresh-mint-200);
    position: absolute
}

@media screen and (max-width: 1440px) {
    .Banner_banner__C1zqn:before {
        left:244px
    }
}

@media screen and (max-width: 562px) {
    .Banner_banner__C1zqn:before {
        left:50%;
        top: auto;
        bottom: -12px;
        transform: translateX(-12px) rotate(45deg);
        transform-origin: center
    }
}

@media screen and (max-width: 562px)and (max-height:720px) {
    .Banner_banner__C1zqn:before {
        display:none
    }
}

@media screen and (min-width: 1650px) {
    .Banner_banner__C1zqn:before {
        left:unset;
        right: 185px
    }
}

.Banner_banner__C1zqn h2 {
    margin-bottom: var(--spacing-1-xs);
    overflow: hidden;
    position: relative;
    height: calc(var(--leading-tight)*var(--heading-s))
}

.Banner_banner__C1zqn h2::-moz-selection {
    background-color: var(--selection-fresh-mint-200)
}

.Banner_banner__C1zqn h2::selection {
    background-color: var(--selection-fresh-mint-200)
}

.Banner_banner__C1zqn h2 span {
    --delay: 1100ms;
    transform: translate3d(0,20px,0);
    animation: Banner_appear__hna5J .4s cubic-bezier(.79,.14,.15,.86) forwards;
    animation-delay: var(--delay);
    position: absolute
}

.Banner_banner__C1zqn .Banner_shop__eQO41 {
    width: 45px;
    height: 48px
}

.Banner_banner__C1zqn .Banner_content__UqOZG {
    width: 100%;
    position: relative;
    overflow: hidden
}

.Banner_banner__C1zqn .Banner_content__UqOZG.Banner_done__TneJD {
    overflow: initial
}

.Banner_banner__C1zqn p {
    --delay: 1250ms;
    position: relative;
    display: inline-block;
    transform: translate3d(0,20px,0);
    animation: Banner_appear__hna5J .4s cubic-bezier(.79,.14,.15,.86) forwards;
    animation-delay: var(--delay)
}

.Banner_door__5Ys0w {
    transform-origin: 37.54px 24.51px!important
}

.Banner_squiggle__176Jr {
    position: absolute;
    right: -16px;
    top: calc(var(--leading-normal)*var(--body-s))
}

.Tooltip_container__JQEPe {
    position: relative;
    display: inline-block
}

.Tooltip_container__JQEPe:hover .Tooltip_tooltip__4l7fD {
    opacity: 1;
    transform: translateY(-10px) translateX(-50%)
}

.Tooltip_container__JQEPe:hover .Tooltip_close__w4OFR,.Tooltip_tooltip__4l7fD {
    opacity: 0;
    transform: translateY(-6px) translateX(-50%)
}

.Tooltip_tooltip__4l7fD {
    position: absolute;
    top: -100%;
    left: 50%;
    transition: none var(--regular-transition) var(--ease);
    transition-property: transform,opacity;
    background-color: #020155;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px
}

.Tooltip_tooltip__4l7fD:hover {
    opacity: 1;
    transform: translateY(-10px) translateX(-50%)
}

.Tooltip_tooltip__4l7fD:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #020155;
    bottom: -4px;
    left: calc(50% - 6px);
    transform: rotate(45deg);
    border-radius: 3px;
    z-index: -1
}

.Tooltip_tooltip__4l7fD p {
    color: var(--white-000);
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap
}

.Tooltip_tooltip__4l7fD.Tooltip_close__w4OFR {
    opacity: 0;
    transform: translateY(-6px) translateX(-50%)
}

.FixedYummygumMark_logo__uN9qM {
    --top: 44px;
    --left: 0;
    object-fit: contain;
    object-position: center left;
    position: fixed;
    top: var(--top);
    left: var(--left);
    z-index: 111;
    mix-blend-mode: difference;
    width: 43px;
    height: 40px
}

@media screen and (max-width: 1650px) {
    .FixedYummygumMark_logo__uN9qM {
        --top:36px
    }
}

@media screen and (max-width: 768px) {
    .FixedYummygumMark_logo__uN9qM {
        --top:28px
    }
}

@media screen and (max-width: 562px) {
    .FixedYummygumMark_logo__uN9qM {
        --top:32px;
        height: 32px;
        width: auto
    }
}

.FixedYummygumMark_logo__uN9qM img {
    height: 100%
}

.Layout_bubble-container__xEQQN {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center
}

.Layout_bubble__Cv0lK {
    height: 100vh;
    width: 100vh;
    transform: scale(0);
    border-radius: 50%;
    background-color: var(--sweet-liquorice-750);
    position: fixed;
    z-index: 998;
    pointer-events: none;
    bottom: 0;
    overflow: hidden
}

.Layout_logo__t_StM {
    width: min(64px,9vw);
    transform-origin: center;
    z-index: 999
}

.Footer_footer__1tiND {
    background-color: #020155;
    overflow-y: hidden;
    width: 100%;
    content-visibility: auto;
    contain-intrinsic-size: 650px
}

@media screen and (max-width: 768px) {
    .Footer_footer__1tiND {
        contain-intrinsic-size:1200px
    }
}

@media screen and (max-width: 562px) {
    .Footer_footer__1tiND {
        contain-intrinsic-size:900px
    }
}

.Footer_footer__1tiND address {
    font-style: normal;
    color: var(--sweet-liquorice-400)
}

.Footer_main__c7Ege .underline {
    --accent: #C99652;
    margin-bottom: -8px;
    padding-bottom: 8px
}

.Footer_main__c7Ege a,.Footer_main__c7Ege p {
    color: #C99652;
    --accent: #C99652
}

.Footer_main__c7Ege a::-moz-selection,.Footer_main__c7Ege p::-moz-selection {
    background-color: var(--selection-sweet-liquorice)
}

.Footer_main__c7Ege a::selection,.Footer_main__c7Ege p::selection {
    background-color: var(--selection-sweet-liquorice)
}

.Footer_inner__XKbDZ {
    opacity: 0;
    transition: opacity .3s var(--ease);
    transition-delay: .3s
}

.Footer_inner__XKbDZ.Footer_fade__7xt9Y {
    opacity: 1
}

@media screen and (max-width: 920px) {
    .Footer_inner__XKbDZ {
        padding-left:0!important;
        padding-right: 0!important
    }
}

.Footer_content__WRmDp {
    background-color: var(--black-000);
    -webkit-clip-path: circle(0 at 50% 0);
    clip-path: circle(0 at 50% 0);
    padding: 88px 0 0;
    transition: background-color .6s var(--ease),-webkit-clip-path .6s var(--ease);
    transition: clip-path .6s var(--ease),background-color .6s var(--ease);
    transition: clip-path .6s var(--ease),background-color .6s var(--ease),-webkit-clip-path .6s var(--ease)
}

.Footer_content__WRmDp.Footer_masked__q_h_v {
    background-color: #020155;
    -webkit-clip-path: circle(150% at 50% 0);
    clip-path: circle(150% at 50% 0)
}

.Footer_content__WRmDp.Footer_compact__nAGBo {
    padding: 56px 0 0
}

.Footer_content__WRmDp.Footer_compact__nAGBo>div {
    --gap: 44px
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_logo-container__aaqtq {
    margin: 0
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K {
    border-bottom: 0
}

@media screen and (max-width: 1120px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K {
        padding:0
    }
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap-inner___oAgt {
    flex-direction: row;
    align-items: center;
    display: flex;
    gap: var(--spacing-2-xl)
}

@media screen and (max-width: 1120px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap-inner___oAgt {
        align-items:start;
        flex-direction: column
    }
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-2-xl)
}

@media screen and (max-width: 1120px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv {
        flex-direction:column;
        align-items: start;
        gap: var(--spacing-2-xl)
    }

    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv>li:first-child {
        margin-top: 0
    }
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv>li {
    display: flex;
    flex-direction: row;
    border: none;
    padding: 0
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv>li a {
    font-size: var(--body-l)
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv>li>ul {
    display: none
}

@media screen and (max-width: 920px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap-inner___oAgt,.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sitemap-container__Seu5K .Footer_sitemap__kypdv {
        flex-direction:column;
        align-items: start
    }
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_right__RxVzC {
    padding: 0;
    border: none
}

@media screen and (max-width: 920px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_right__RxVzC {
        display:none
    }
}

@media screen and (max-width: 1120px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_right__RxVzC {
        align-items:end;
        justify-content:flex-end;
        height: 100%;
        padding-left: 112px;
        border-left: 1px solid var(--sweet-liquorice-700)
    }
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_right__RxVzC .Footer_newsletter__5vX_y {
    display: none
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_right__RxVzC .Footer_channels__aWhnQ {
    padding: 0;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: -24px;
    margin-top: -4px;
    gap: var(--spacing-s)
}

@media screen and (max-width: 1120px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_right__RxVzC .Footer_channels__aWhnQ {
        flex-direction:column;
        justify-content:flex-end;
        height: 100%
    }
}

@media screen and (max-width: 920px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_mobile-socials__ZPhoC {
        padding-left:var(--spacing-5-xl)
    }
}

@media screen and (max-width: 562px) {
    .Footer_content__WRmDp.Footer_compact__nAGBo .Footer_mobile-socials__ZPhoC {
        padding-left:var(--spacing-1-xl)
    }
}

.Footer_content__WRmDp.Footer_compact__nAGBo .Footer_sub-footer__eqMcA {
    margin-top: 0
}

@media screen and (max-width: 920px) {
    .Footer_content__WRmDp {
        padding:60px 0 0
    }
}

.Footer_logo-container__aaqtq {
    display: inline-flex;
    margin-bottom: var(--spacing-2-xl)
}

@media screen and (max-width: 920px) {
    .Footer_logo-container__aaqtq {
        margin-bottom:0
    }
}

.Footer_logo__yjYlw {
    height: 40px;
    width: 43px;
    display: block
}

@media screen and (max-width: 562px) {
    .Footer_logo__yjYlw {
        height:32px;
        width: 34px
    }
}

.Footer_sitemap-container__Seu5K {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 64px
}

@media screen and (max-width: 920px) {
    .Footer_sitemap-container__Seu5K {
        padding-right:0
    }
}

.Footer_sitemap__kypdv>li {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    padding: 20px 0;
    grid-column-gap: var(--spacing-3-xl);
    -moz-column-gap: var(--spacing-3-xl);
    column-gap: var(--spacing-3-xl);
    border-top: 1px solid var(--sweet-liquorice-700)
}

.Footer_sitemap__kypdv>li:last-child {
    padding-bottom: 0
}

.Footer_sitemap__kypdv>li>div {
    grid-area: 1/1/2/3
}

.Footer_sitemap__kypdv>li>div>a {
    font-weight: 600
}

.Footer_sitemap__kypdv>li>ul {
    grid-area: 1/4/2/6;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m)
}

.Footer_sitemap__kypdv>li>ul li {
    padding: 0
}

@media screen and (max-width: 920px) {
    .Footer_sitemap__kypdv>li {
        grid-template-columns:1fr
    }

    .Footer_sitemap__kypdv>li:first-child {
        margin-top: var(--spacing-1-xl);
        border-top: 1px solid var(--sweet-liquorice-700)
    }

    .Footer_sitemap__kypdv>li>div,.Footer_sitemap__kypdv>li>ul {
        grid-area: auto
    }

    .Footer_sitemap__kypdv>li>ul {
        padding: 20px 0 4px;
        gap: var(--spacing-1-xl)
    }
}

.Footer_sitemap__kypdv .Footer_career-count__NbmW_ {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-s);
    background-color: var(--fresh-mint-300);
    border-radius: var(--radius-full);
    margin-left: 8px;
    font-size: var(--body-s)
}

.Footer_sitemap__kypdv .Footer_career-container__hbQou {
    display: inline-flex;
    align-items: center;
    position: relative
}

.Footer_mobile-socials__ZPhoC {
    display: none
}

@media screen and (max-width: 920px) {
    .Footer_sitemap-container__Seu5K {
        flex-direction:row;
        border-bottom: 1px solid var(--sweet-liquorice-700);
        padding-bottom: var(--spacing-4-xl)
    }

    .Footer_sitemap-inner___oAgt {
        width: 50%;
        padding: 0 var(--spacing-2-xl)
    }

    .Footer_mobile-socials__ZPhoC {
        width: 50%;
        display: flex;
        align-items: flex-end;
        padding-left: var(--spacing-l);
        border-left: 1px solid var(--sweet-liquorice-700)
    }

    .Footer_mobile-socials__ZPhoC .Footer_channels__aWhnQ {
        display: flex
    }
}

.Footer_right__RxVzC {
    padding-left: 112px;
    border-left: 1px solid var(--sweet-liquorice-700)
}

@media screen and (max-width: 1120px) {
    .Footer_right__RxVzC {
        padding-left:84px
    }
}

@media screen and (max-width: 920px) {
    .Footer_right__RxVzC {
        padding-left:0;
        border-left: 0
    }
}

.Footer_newsletter__5vX_y {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1-xl);
    border-radius: var(--radius-1-xs);
    max-width: 408px
}

.Footer_newsletter__5vX_y p {
    color: #C99652;
    line-height: var(--leading-normal);
    padding-left: 24px
}

.Footer_newsletter__5vX_y input {
    --input-hover-bg-color: #020155
}

.Footer_newsletter__5vX_y .Footer_form__Mfg20 {
    display: flex;
    align-items: flex-end
}

.Footer_newsletter__5vX_y .Footer_form__Mfg20>div {
    width: 100%
}

@media screen and (max-width: 920px) {
    .Footer_newsletter__5vX_y {
        padding:0 var(--spacing-2-xl);
        max-width: 712px
    }

    .Footer_newsletter__5vX_y p {
        padding-left: 24px
    }
}

.Footer_channels__aWhnQ {
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l)
}

.Footer_channels__aWhnQ span {
    gap: 4px
}

.Footer_channels__aWhnQ a {
    --bg-color: #C99652;
    --button-primary-color: #C99652;
    --button-primary-hover-bg-color: #C99652;
    --button-primary-hover-negative-bg-color: #C99652;
    --button-primary-hover-color: #020155;
    font-weight: 600
}

@media screen and (max-width: 920px) {
    .Footer_channels__aWhnQ {
        display:none
    }
}

.Footer_submitted__PxRfr {
    height: 92px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.Footer_submitted__PxRfr p {
    overflow: hidden;
    color: #fff
}

.Footer_submitted__PxRfr span {
    display: flex
}

.Footer_door__xC0Cc {
    transform-origin: 37.54px 24.51px!important
}

.Footer_sub-footer__eqMcA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--sweet-liquorice-700);
    padding: var(--spacing-1-xl) 0;
    margin-top: var(--spacing-m)
}

@media screen and (max-width: 920px) {
    .Footer_sub-footer__eqMcA {
        flex-direction:column;
        padding: var(--spacing-1-xl) 0 var(--spacing-3-xl)
    }
}

.Footer_sub-footer-links__DK4rV {
    display: flex;
    gap: var(--spacing-m);
    align-items: center
}

.Footer_sub-footer-links__DK4rV a,.Footer_sub-footer-links__DK4rV span {
    color: #C99652
}

@media screen and (max-width: 920px) {
    .Footer_sub-footer-links__DK4rV {
        gap:var(--spacing-l)
    }
}

.Footer_copyright-notice__NXfsf {
    display: flex;
    align-items: center;
    gap: var(--spacing-m)
}

@media screen and (max-width: 920px) {
    .Footer_copyright-notice__NXfsf {
        flex-direction:column-reverse;
        align-items: center;
        gap: 0
    }
}

.Footer_copyright-notice__NXfsf a,.Footer_copyright-notice__NXfsf p {
    color: var(--sweet-liquorice-400)
}

.Footer_copyright-notice__NXfsf a::-moz-selection,.Footer_copyright-notice__NXfsf p::-moz-selection {
    background-color: var(--selection-sweet-liquorice)
}

.Footer_copyright-notice__NXfsf a::selection,.Footer_copyright-notice__NXfsf p::selection {
    background-color: var(--selection-sweet-liquorice)
}

@media screen and (max-width: 920px) {
    .Footer_copyright-notice__NXfsf .Footer_bullet__uaD40 {
        display:none
    }

    .Footer_copyright-notice__NXfsf svg {
        margin: var(--spacing-1-xl) 0 var(--spacing-l)
    }
}

.SignupInput_signup-input__5_Ida {
    contain: layout;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    flex: 1 1
}

.SignupInput_signup-input__5_Ida .SignupInput_label__LGSfw {
    margin-bottom: var(--spacing-m);
    margin-left: 24px;
    display: inline-flex;
    align-items: center;
    color: #C99652;
    min-height: 24px
}

@media screen and (max-width: 1024px) {
    .SignupInput_signup-input__5_Ida .SignupInput_label__LGSfw {
        flex-direction:column;
        align-items: flex-start
    }
}

.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0,.SignupInput_signup-input__5_Ida .SignupInput_success__tG9Ks {
    display: flex;
    align-items: center;
    margin: -3px 24px 0;
    border-radius: var(--radius-1-xs);
    padding: 12px;
    gap: 12px;
    position: relative;
    z-index: -1
}

.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0 svg,.SignupInput_signup-input__5_Ida .SignupInput_success__tG9Ks svg {
    margin-bottom: auto;
    flex-shrink: 0
}

.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0 {
    background-color: var(--chewy-cherry-250)
}

.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0,.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0 span {
    color: var(--chewy-cherry-900)
}

.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0 path:first-of-type {
    fill: var(--chewy-cherry-300)
}

.SignupInput_signup-input__5_Ida .SignupInput_error__OaMz0 path:nth-of-type(2) {
    fill: var(--chewy-cherry-400)
}

.SignupInput_signup-input__5_Ida .SignupInput_success__tG9Ks {
    background-color: var(--fresh-mint-250)
}

.SignupInput_signup-input__5_Ida .SignupInput_success__tG9Ks,.SignupInput_signup-input__5_Ida .SignupInput_success__tG9Ks span {
    color: var(--fresh-mint-950)
}

.SignupInput_signup-input__5_Ida .SignupInput_label__LGSfw.SignupInput_is-filled__qmwSz {
    color: var(--sweet-liquorice-500)
}

.SignupInput_signup-input__5_Ida .SignupInput_is-disabled__ohtvU {
    color: var(--sweet-liquorice-600)
}

.SignupInput_signup-button__Aen92 {
    position: absolute;
    right: 12px
}

.SignupInput_signup-input-container__8dBNi {
    position: relative;
    display: flex;
    align-items: center
}

.SignupInput_signup-input-element___YTGr {
    position: relative;
    background-color: #020155;
    border: 1px solid var(--sweet-liquorice-700);
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: var(--radius-full);
    margin: 0;
    width: 100%;
    font-family: inherit;
    color: var(--white-000);
    font-size: var(--body-1-xl);
    padding-left: 24px;
    padding-right: 68px;
    line-height: 68px
}

@media screen and (max-width: 1024px) {
    .SignupInput_signup-input-element___YTGr {
        line-height:64px
    }
}

.SignupInput_signup-input-element___YTGr::-moz-placeholder {
    color: var(--sweet-liquorice-500)
}

.SignupInput_signup-input-element___YTGr::placeholder {
    color: var(--sweet-liquorice-500)
}

.SignupInput_signup-input-element___YTGr:hover {
    border-color: var(--sweet-liquorice-500)
}

.SignupInput_signup-input-element___YTGr:focus,.SignupInput_signup-input-element___YTGr:focus-within {
    border-color: #C99652;
    outline: 0;
    caret-color: var(--input-caret-color)
}

.SignupInput_signup-input-element___YTGr.SignupInput_has-error__UNFAr {
    border-color: var(--chewy-cherry-500)
}

.SignupInput_signup-input-element___YTGr.SignupInput_has-error__UNFAr:focus,.SignupInput_signup-input-element___YTGr.SignupInput_has-error__UNFAr:focus-within,.SignupInput_signup-input-element___YTGr.SignupInput_has-error__UNFAr:hover:not(:disabled) {
    border-color: var(--chewy-cherry-800)
}

.SignupInput_signup-input-element___YTGr:disabled {
    background-color: var(--sweet-liquorice-800);
    border-color: var(--sweet-liquorice-600);
    
}

.SignupInput_signup-input-element___YTGr:-webkit-autofill,.SignupInput_signup-input-element___YTGr:-webkit-autofill:active,.SignupInput_signup-input-element___YTGr:-webkit-autofill:focus,.SignupInput_signup-input-element___YTGr:-webkit-autofill:hover {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #fff
}

.SignupInput_signup-input-element___YTGr.SignupInput_s__vp7Pb {
    line-height: 52px;
    font-size: var(--body-l)
}


.Cursor_cursor__Ye0fG {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    z-index: 1000
}

.Cursor_cursor__Ye0fG,.Cursor_inner-cursor__zAm_o {
    mix-blend-mode: difference;
    transform-origin: center center;
    will-change: transform
}

.Cursor_inner-cursor__zAm_o {
    align-items: center;
    backface-visibility: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1001
}

.Cursor_cursor-active__B8PT5 {
    opacity: 1;
    transition: all .3s cubic-bezier(.075,.82,.165,1)
}




.LocalSpot_local__Y_g69 {
    display: grid;
    grid-gap: var(--spacing-1-xl);
    gap: var(--spacing-1-xl);
    width: 100%
}

.LocalSpot_local__Y_g69.LocalSpot_inversed__ewakn p {
    color: #C99652
}

.ContactHeader_header__V95CA {
    padding-top: calc(var(--spacing-7-xl) + var(--spacing-3-xl))
}

@media screen and (max-width: 562px) {
    .ContactHeader_header__V95CA {
        padding-top:calc(84px + var(--spacing-6-xl))
    }
}

.ContactHeader_header__V95CA h1 {
    max-width: 664px;
    margin-bottom: 0
}

.HeaderBase_header__KVjAm .HeaderBase_tag__eHCjK,.HeaderBase_header__KVjAm h1 {
    margin-bottom: var(--spacing-l)
}

.HeaderBase_header__KVjAm .HeaderBase_flex-header__4CkPm {
    display: flex;
    flex-flow: row wrap
}

.HeaderBase_header__KVjAm .HeaderBase_description__PtiXa {
    max-width: 660px;
    width: 100%
}

.HeaderBase_header__KVjAm .HeaderBase_right__cvIT4 {
    max-width: 540px
}

@media screen and (max-width: 1024px) {
    .HeaderBase_header__KVjAm .HeaderBase_right__cvIT4 {
        margin-left:0
    }
}

.HeaderBase_header__KVjAm .HeaderBase_text__LoOWT {
    margin-bottom: var(--spacing-5-xl)
}

.HeaderBase_bad__RXBeS {
    position: relative;
    text-decoration: none
}

.HeaderBase_strike__WAGW0 {
    position: absolute;
    left: 0;
    content: " ";
    width: 100%;
    height: 32px;
    top: 55%;
    transform: translateY(-50%)
}

.HeaderBase_wrapper__PZFbP {
    overflow: hidden
}

.HeaderBase_screen-reader-only__HE6ky {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: polygon(0 0,0 0,0 0);
    clip-path: polygon(0 0,0 0,0 0);
    overflow: hidden
}

.HeaderBase_breadcrumb___rBpk {
    margin-bottom: var(--spacing-3-xl)
}

.HeaderBase_button__OOfJR {
    margin-top: var(--spacing-3-xl)
}

.HeaderBase_anim__JsPmx {
    height: 1.25em;
    line-height: 1.25;
    position: relative;
    display: inline-block;
    overflow: hidden
}

@media screen and (max-width: 375px) {
    .HeaderBase_anim__JsPmx {
        height:1.5em;
        line-height: 1.5;
        width: 100%
    }
}

.Breadcrumb_breadcrumb__3A31f {
    align-items: center;
    display: flex
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_item__SX_9h {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-gap: var(--spacing-s);
    gap: var(--spacing-s);
    margin-right: var(--spacing-s)
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_item__SX_9h:last-of-type {
    margin-right: 0
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_hidden__DVURu {
    display: none
}

.Breadcrumb_breadcrumb__3A31f svg {
    color: var(--sweet-liquorice-600)
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_back__bwyH0 {
    margin-right: var(--spacing-1-xs)
}

.Breadcrumb_breadcrumb__3A31f li {
    list-style: none
}

.Breadcrumb_breadcrumb__3A31f a,.Breadcrumb_breadcrumb__3A31f p {
    color: var(--sweet-liquorice-600);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    --accent: var(--sweet-liquorice-600)
}

.Breadcrumb_breadcrumb__3A31f a span,.Breadcrumb_breadcrumb__3A31f p span {
    text-transform: none;
    color: var(--sweet-liquorice-750);
    font-weight: 500
}

.Breadcrumb_breadcrumb__3A31f a:last-child,.Breadcrumb_breadcrumb__3A31f p:last-child {
    --accent: #020155;
    color: #020155;
    font-weight: 600
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_current__MeFmN {
    color: var(--service-color)!important;
    text-transform: none
}

.Tag_tag__5SKaf {
    --design: var(--blueberry-bubble-200);
    --dev: var(--icy-slushie-200);
    --user-experience: var(--sticky-citrus-200);
    --research: var(--chewy-cherry-200);
    --company: var(--fresh-mint-300);
    --closed: var(--chewy-cherry-200);
    --default: var(--sweet-liquorice-200);
    --design-text: var(--blueberry-bubble-900);
    --dev-text: var(--icy-slushie-900);
    --user-experience-text: var(--sticky-citrus-900);
    --research-text: var(--chewy-cherry-900);
    --company-text: var(--fresh-mint-900);
    --closed-text: var(--chewy-cherry-900);
    --default-text: #020155;
    align-items: center;
    background: var(--color,var(--default));
    border-radius: var(--radius-full);
    color: var(--text-color);
    display: inline-flex;
    font-family: inherit;
    font-size: var(--body-s);
    line-height: var(--leading-tight);
    padding: 1.5px 8px
}

.Tag_tag__5SKaf:not(.Tag_with-children__WleyP):before {
    content: "#"
}

.Tag_tag__5SKaf span {
    color: inherit
}

.Tag_tag__5SKaf.Tag_inversed__9UAZG {
    background-color: var(--sweet-liquorice-700);
    color: #C99652
}

.Contact_content__IUMuG {
    row-gap: var(--spacing-5-xl);
    padding: 64px var(--spacing-2-xl) 176px
}

@media screen and (max-width: 1440px) {
    .Contact_content__IUMuG {
        padding-left:var(--spacing-1-xl);
        padding-right: var(--spacing-1-xl)
    }
}

@media screen and (max-width: 920px) {
    .Contact_content__IUMuG {
        padding:0 24px 64px
    }
}

@media screen and (max-width: 1440px) {
    .Contact_contact-description__1nZLc {
        max-width:100%
    }
}

@media screen and (max-width: 920px) {
    .Contact_contact-description__1nZLc {
        grid-column:1/-1;
        --span: 12
    }
}

.Contact_contact-description__1nZLc>div {
    line-height: var(--leading-relaxed)
}

@media screen and (max-width: 920px) {
    .Contact_contact-description__1nZLc>div {
        font-size:var(--body-l)
    }
}

.Contact_contact-cta__OrQU1 {
    display: flex;
    padding-top: 92px;
    flex-direction: column;
    gap: var(--spacing-m);
    max-width: 412px
}

@media screen and (max-width: 920px) {
    .Contact_contact-cta__OrQU1 {
        max-width:100%;
        padding-top: var(--spacing-2-xl);
        padding-bottom: var(--spacing-4-xl);
        gap: var(--spacing-m);
        border-bottom: 1px solid var(--sweet-liquorice-200)
    }
}

.Contact_contact__n5RaJ {
    border-left: 1px solid var(--sweet-liquorice-200);
    padding-left: 64px;
    margin-left: 64px
}

@media screen and (max-width: 1280px) {
    .Contact_contact__n5RaJ {
        padding-left:64px;
        margin-left: 0;
        border: none
    }
}

@media screen and (max-width: 920px) {
    .Contact_contact__n5RaJ {
        padding-left:0;
        margin-left: 0;
        border-left: none
    }
}

.Avatar_avatar__wkq6Y {
    --size: var(--ui-size-1-xs);
    border-radius: var(--radius-full);
    width: var(--size);
    height: var(--size);
    overflow: hidden
}

.Avatar_image__TUPLD {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: var(--size);
    height: var(--size);
    object-fit: cover
}

.Avatar_image__TUPLD img,.Avatar_image__TUPLD picture {
    object-fit: cover;
    object-position: top
}

.Avatar_size-3-xl__u51ZT {
    --size: var(--ui-size-2-xl)
}

.Avatar_size-2-xl__v_G1l {
    --size: var(--ui-size-1-xl)
}

.Avatar_size-1-xl__PANnR {
    --size: var(--ui-size-l)
}

.Avatar_size-l__Gy1ks {
    --size: var(--ui-size-m)
}

.Avatar_size-m__KbhXf {
    --size: var(--ui-size-s)
}

.ContactForm_form__qdC_m {
    width: 100%;
    display: flex;
    flex-direction: column
}

.ContactForm_form__qdC_m .ContactForm_wrapper__2r6uv {
    margin-bottom: var(--spacing-3-xl)
}

@media screen and (max-width: 768px) {
    .ContactForm_form__qdC_m .ContactForm_wrapper__2r6uv {
        -moz-column-gap:var(--spacing-1-xl);
        column-gap: var(--spacing-1-xl)
    }
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf {
    display: flex;
    flex-direction: row;
    justify-content: flex-start
}

@media screen and (max-width: 768px) {
    .ContactForm_form__qdC_m .ContactForm_button-container__gUZkf {
        flex-direction:column;
        justify-content: flex-start
    }
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf:not(:last-child) {
    margin-bottom: var(--spacing-3-xl)
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf button {
    align-self: flex-start
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf p {
    margin-left: var(--spacing-1-xl);
    align-items: center;
    display: flex
}

@media screen and (max-width: 768px) {
    .ContactForm_form__qdC_m .ContactForm_button-container__gUZkf p {
        margin-left:0;
        margin-top: var(--spacing-1-xl)
    }
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf p svg {
    margin-right: var(--spacing-2-xs);
    flex-shrink: 0
}

.ContactForm_form__qdC_m .ContactForm_form-row__UTeLK {
    display: flex;
    flex-wrap: wrap;
    -moz-column-gap: var(--spacing-2-xl);
    column-gap: var(--spacing-2-xl)
}

@media screen and (max-width: 562px) {
    .ContactForm_form__qdC_m .ContactForm_form-row__UTeLK {
        flex-direction:column
    }
}

.Input_input__fIbUx {
    contain: layout;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    flex: 1 1
}

.Input_input__fIbUx .Input_label__ZjenT {
    margin-bottom: var(--spacing-s);
    margin-left: var(--spacing-1-xl);
    display: inline-flex;
    align-items: center;
    color: var(--input-label-color);
    min-height: 24px
}

.Input_input__fIbUx .Input_label__ZjenT.Input_is-filled__DN8Kn {
    color: var(--sweet-liquorice-500)
}

@media screen and (max-width: 1024px) {
    .Input_input__fIbUx .Input_label__ZjenT {
        flex-direction:column;
        align-items: flex-start
    }
}

.Input_input__fIbUx .Input_error__z7sMl,.Input_input__fIbUx .Input_success__UiSNj {
    display: flex;
    align-items: center;
    margin: -3px 24px 0;
    border-radius: var(--radius-1-xs);
    padding: 12px;
    gap: 12px
}

.Input_input__fIbUx .Input_error__z7sMl svg,.Input_input__fIbUx .Input_success__UiSNj svg {
    margin-bottom: auto;
    flex-shrink: 0
}

.Input_input__fIbUx .Input_error__z7sMl {
    background-color: var(--chewy-cherry-100)
}

.Input_input__fIbUx .Input_error__z7sMl,.Input_input__fIbUx .Input_error__z7sMl span {
    color: var(--chewy-cherry-800)
}

.Input_input__fIbUx .Input_success__UiSNj,.Input_input__fIbUx .Input_success__UiSNj span {
    color: var(--fresh-mint-950)
}

.Input_input__fIbUx .Input_is-disabled__ClCMF {
    color: var(--sweet-liquorice-600)
}

.Input_input-container__GjvvN {
    position: relative
}

.Input_input-container__GjvvN:after {
    position: absolute;
    color: var(--sweet-liquorice-400);
    font-weight: 300;
    content: none;
    font-family: inherit;
    font-size: 48px;
    bottom: -7px;
    line-height: 72px;
    right: 24px
}

@media screen and (max-width: 1024px) {
    .Input_input-container__GjvvN:after {
        line-height:64px
    }
}

.Input_input-element__QYM_i {
    background-color: var(--white-000);
    border: 1px solid #C99652;
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: var(--radius-full);
    margin: 0;
    width: 100%;
    font-family: inherit;
    color: #020155;
    padding: 0 24px;
    line-height: 72px;
    font-size: var(--body-1-xl)
}

@media screen and (max-width: 1024px) {
    .Input_input-element__QYM_i {
        line-height:64px
    }
}

.Input_input-element__QYM_i::-moz-placeholder {
    color: var(--input-placeholder-color)
}
