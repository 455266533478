@font-face {
    font-family: 'Times New Roman', Times, serif;
    src: url('../../guardian/GuardianSansRegular.woff') format('woff2'),
        url('../../guardian/GuardianSansRegularIt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.main_navtop {
    background: #333947;
    height: 39px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu_button {
    display: none;
}

.navtop_center {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navtop_center>img {
    width: 4px;
    height: 8px;
    top: 1px;
    left: 1px;
    border: 1.2px;
    cursor: pointer;
}

.navtop_center_text {
    font-family: 'Vollkorn', serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    width: 200px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    overflow-x: auto;
    color: #40462C;
}

.navtop_center_text>* {
    width: 100%;
    margin-right: 30px;
    margin-left: 40px;
}

.navtop_center_text::-webkit-scrollbar {
    display: none;
}

.nav_middle_main {
    width: 100vw;
    height: 39px;
    background: #40462C;
    display: flex;
    justify-content: center;
    align-items: center;
}

.myCarousel {
    height: 19px;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.myCarousel .navtop_center_text {
    font-family: 'Vollkorn', serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    width: 100%;
    letter-spacing: 0em;
    text-align: center;
    color: #40462C;
    padding: 0 10px;
}

.nav_middle_center {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.nav_middle_inner {
    display: flex;
    gap: 5px;
    align-items: center;
}

.nav_middle_inner>img {
    cursor: pointer;
}

.nav_middle_inner2 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.nav_middle_inner_text {
    font-family: 'Vollkorn', serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
}

.nav_main {
    width: 100vw;
    height: 83px;
    background: #4D5770 !important;
    display: flex;
    padding-left: 10%;
    align-items: center;
}

.nav_main_center {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 30px;
}

.nav_links {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 30px;
}

.nav_links>a {
    font-family: 'Larken Demo', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: white !important;
    cursor: none;
    text-decoration: none;
    margin-bottom: 0 !important;
}

.nav_links>* {
    min-width: 70px;
    font-size: 18px !important;
}

.nav_main_last {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.home_banner_text {
    font-family: 'Larken Demo', sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.8399999737739563px;
    text-align: center;
    color: #CEA165;
    width: 70%;
    text-align: start;
}

.home_banner_main {
    background-image: url('../../Assets/home/HOME\ PAGE1\ 1.png');
    width: 100vw;
    height: 650px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_banner_two {
    background-image: url('../../Assets/home/banner2.png');
    width: 100vw;
    height: 550px;
    background-color: rgba(0, 0, 0, 0.5);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo>img{
    height: 48px !important;
}

.home_banner_two_text {
    font-family: 'Vollkorn', serif;
    font-size: 18px !important;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.8399999737739563px;
    text-align: left;
    color: #40462C;
    width: 70%;
}

.banner3_main {
    width: 100vw;
    display: flex;
    gap: 10px;
    height: 600px;
}

.banner_first_half {
    height: 100%;
    width: calc(50% - 5px);
    background-image: url('../../Assets/home/crop\ 5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner_second_half {
    height: 600px;
    width: calc(50% - 5px);
    background-color: #4D5770;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner3_inner_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner_3_button:hover {
    background-color: #40462C;
    color: #CEA165;
}

.banner3_inner_text {
    font-family: 'Larken Demo', sans-serif;
    font-size: 34px;
    color: #CEA165;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.6800000071525574px;
    text-align: center;
    margin-bottom: 20px;
}

.banner_3_button {
    width: 149px;
    height: 50px;
    padding: 12.5px, 30px, 13.5px, 30px;
    border-radius: 25px;
    border: none;
    font-family: 'Vollkorn', serif;
    background-color: #CEA165;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #333947;
}

.home_text_div {
    width: 100vw;
    height: calc(fit-content + 60px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_text {
    width: 50%;
    font-family: 'Vollkorn', serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    color: white;
}

.news_heading {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: -0.6800000071525574px;
    text-align: left;
    color: #333947;
}

.home_news_main {
    width: 100vw;
    display: flex;
    justify-content: center;

}

.home_news_main_inner {
    width: 80%;
}

.home_news_first {
    width: 50%;
    padding: 10px;
    height: 795px;
    background-color: #4D5770;
    overflow: hidden;
    position: relative;
    z-index: 2;
    border-radius: 8px;
    padding-bottom: 40px;

}

.expandableDiv1:hover {
    scale: 1.2;

}

.home_news_first>img {
    width: 100%;
    height: 70% !important;
    position: relative;
    z-index: -1;
}

.news_images {
    display: flex;
    align-items: center;
    gap: 20px;
}

.home_news_text1 {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.47999998927116394px;
    text-align: center;
    color: #CEA165;
    margin-bottom: 10px;
    margin-top: 30px;
}

.home_news_text2 {
    font-family: 'Vollkorn', serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #CEA165;
    margin: 0;
}

.footer_main {
    background: #333947;
    padding: 65px 10%;
    width: 100vw;
}

.footer_main_first {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    width: 100%;
    gap: 10%;
}

.grid_item_title {
    font-family: 'Larken Demo', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: white;
    margin-bottom: 12px;
}

.grid_items {
    font-family: 'Vollkorn', serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    width: fit-content;
    margin: 0;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
    color: white;
}

.links_pages {
    font-family: 'Vollkorn', serif;
}

.grid_item_input {
    height: 51px;
    background: #333947;
    border: none;
    color: #CEA165;
    outline: none;
    padding: 20px;
    font-size: 16px;
}

.grid_item_input::placeholder {
    color: #CEA165;
    font-family: 'Vollkorn', serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.25999999046325684px;
    text-align: left;
}

.grid_item {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.footer_bottom_text {
    font-family: 'Vollkorn', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #CEA165;
}

.footer_bottom_first {
    display: flex;
    align-items: center;
    gap: 30px;
}

.footer_bottom {
    padding-top: 5%;
}

.footer_bottom_text>span {
    text-decoration: underline;
}

.footer_first_half {
    width: 45vw;
}

.footer_first_half>ul {
    width: 100%;
}

.footer_first_half>ul>div {
    width: 100%;
}

.footer_first_inner_div {
    display: flex;
    height: fit-content;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    border-top: 1px solid #CEA165;
    border-bottom: 1px solid #CEA165;
}

.footer_inner_heading {
    font-family: 'Vollkorn', serif;
    font-size: 30px;
    font-weight: 500;
    color: #CEA165 !important;
}

.footer_inner_items {
    font-family: 'Vollkorn', serif;
    font-size: 18px;
    font-weight: 400;
    color: #40462C;
}

.Footer_channels__aWhnQ {
    align-items: flex-start !important;
}

.copyright {
    font-family: 'Vollkorn', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;
    width: 100vw;
    color: white;
    padding-right: 10%;
}

@media screen and (max-width:1400px) {
    .home_news_first {
        height: 550px !important;
    }

    .home_news_first>img {
        height: 350px;
    }
}

.background_tint {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(17, 17, 17, 0.5);
}


.background_tint2 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(17, 17, 17, 0.5);
}

.underline_div:hover {}

img {
    object-fit: cover !important;
    height: fit-content !important;
}

@media screen and (max-width:1000px) {
    .news_images {
        flex-direction: column;
    }

    .news_images>* {
        width: 100%;
    }
    .footer_main_first {
        margin-left: 55px;
        height: fit-content !important;
        gap: 40px;
    }

    .grid_item {
        height: fit-content;
    }
}


@media screen and (max-width:500px) {
    .home_banner_two_text {
        width: 100%;
    }

    .banner3_main {
        flex-direction: column;
        align-items: center;
    }

    .banner3_main>* {
        width: 90%;
    }

    .home_text {
        width: 80%;
    }

    .footer_main {
        width: 100%;
        flex-direction: column;
        /* justify-content: flex-start; */
        align-items: center;
    }

    .menu_button {
        display: block;
    }

    .nav_links {
        display: none;
    }

    .nav_main {
        padding-left: 10px;
    }

    .footer_main_first {
        margin-left: 55px;
        height: fit-content !important;
        gap: 40px;
    }

    .grid_item {
        height: fit-content;
    }



    .copyright {}
}

@media screen and (max-width:500px) {
    .background_tint3{
        height: 295px !important;
        width: 90% !important;
    }

    .home_news_first1{
        padding-bottom: 30px;
        height: fit-content !important;
    }

    .home_news_first{
        height: fit-content !important;
        padding-bottom: 30px;
    }

    .news_dialogue_inner_div_parts{
        flex-direction: column;
        gap: 4px !important;
    }

    .news_dialogue_inner_div_parts>*{
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .news_dialogue_inner_div_part1{
        font-weight: 700;
    }

    .parteners_content_heading{
        padding: 0 !important;
    }
}

.sidebar_logo{
    height: 60px !important;
}