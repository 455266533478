.partners_content {
    padding: 30px 10%;
    background-color: white;
}

.parteners_content_heading {
    font-family: 'Vollkorn', serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 1px;
    text-align: start;
    color: black;
    padding: 10px 15%;
}

.partner_footer_title1 {
    font-family: 'Larken Demo', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px;
    letter-spacing: 0em;
    text-align: start;
    color: black;
}