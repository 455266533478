.ContactHeader_header__V95CA {
    padding-top: calc(var(--spacing-7-xl) + var(--spacing-3-xl))
}

@media screen and (max-width: 562px) {
    .ContactHeader_header__V95CA {
        padding-top:calc(84px + var(--spacing-6-xl))
    }
}

.ContactHeader_header__V95CA h1 {
    max-width: 664px;
    margin-bottom: 0
}

.HeaderBase_header__KVjAm .HeaderBase_tag__eHCjK,.HeaderBase_header__KVjAm h1 {
    margin-bottom: var(--spacing-l)
}

.HeaderBase_header__KVjAm .HeaderBase_flex-header__4CkPm {
    display: flex;
    flex-flow: row wrap
}

.HeaderBase_header__KVjAm .HeaderBase_description__PtiXa {
    max-width: 660px;
    width: 100%
}

.HeaderBase_header__KVjAm .HeaderBase_right__cvIT4 {
    max-width: 540px
}

@media screen and (max-width: 1024px) {
    .HeaderBase_header__KVjAm .HeaderBase_right__cvIT4 {
        margin-left:0
    }
}

.HeaderBase_header__KVjAm .HeaderBase_text__LoOWT {
    margin-bottom: var(--spacing-5-xl)
}

.HeaderBase_bad__RXBeS {
    position: relative;
    text-decoration: none
}

.HeaderBase_strike__WAGW0 {
    position: absolute;
    left: 0;
    content: " ";
    width: 100%;
    height: 32px;
    top: 55%;
    transform: translateY(-50%)
}

.HeaderBase_wrapper__PZFbP {
    overflow: hidden
}

.HeaderBase_screen-reader-only__HE6ky {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: polygon(0 0,0 0,0 0);
    clip-path: polygon(0 0,0 0,0 0);
    overflow: hidden
}

.HeaderBase_breadcrumb___rBpk {
    margin-bottom: var(--spacing-3-xl)
}

.HeaderBase_button__OOfJR {
    margin-top: var(--spacing-3-xl)
}

.HeaderBase_anim__JsPmx {
    height: 1.25em;
    line-height: 1.25;
    position: relative;
    display: inline-block;
    overflow: hidden
}

@media screen and (max-width: 375px) {
    .HeaderBase_anim__JsPmx {
        height:1.5em;
        line-height: 1.5;
        width: 100%
    }
}

.Breadcrumb_breadcrumb__3A31f {
    align-items: center;
    display: flex
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_item__SX_9h {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-gap: var(--spacing-s);
    gap: var(--spacing-s);
    margin-right: var(--spacing-s)
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_item__SX_9h:last-of-type {
    margin-right: 0
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_hidden__DVURu {
    display: none
}

.Breadcrumb_breadcrumb__3A31f svg {
    color: var(--sweet-liquorice-600)
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_back__bwyH0 {
    margin-right: var(--spacing-1-xs)
}

.Breadcrumb_breadcrumb__3A31f li {
    list-style: none
}

.Breadcrumb_breadcrumb__3A31f a,.Breadcrumb_breadcrumb__3A31f p {
    color: var(--sweet-liquorice-600);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    --accent: var(--sweet-liquorice-600)
}

.Breadcrumb_breadcrumb__3A31f a span,.Breadcrumb_breadcrumb__3A31f p span {
    text-transform: none;
    color: var(--sweet-liquorice-750);
    font-weight: 500
}

.Breadcrumb_breadcrumb__3A31f a:last-child,.Breadcrumb_breadcrumb__3A31f p:last-child {
    --accent: #40462C;
    color: #40462C;
    font-weight: 600
}

.Breadcrumb_breadcrumb__3A31f .Breadcrumb_current__MeFmN {
    color: var(--service-color)!important;
    text-transform: none
}

.Tag_tag__5SKaf {
    --design: var(--blueberry-bubble-200);
    --dev: var(--icy-slushie-200);
    --user-experience: var(--sticky-citrus-200);
    --research: var(--chewy-cherry-200);
    --company: var(--fresh-mint-300);
    --closed: var(--chewy-cherry-200);
    --default: var(--sweet-liquorice-200);
    --design-text: var(--blueberry-bubble-900);
    --dev-text: var(--icy-slushie-900);
    --user-experience-text: var(--sticky-citrus-900);
    --research-text: var(--chewy-cherry-900);
    --company-text: var(--fresh-mint-900);
    --closed-text: var(--chewy-cherry-900);
    --default-text: #40462C;
    align-items: center;
    background: var(--color,var(--default));
    border-radius: var(--radius-full);
    color: var(--text-color);
    display: inline-flex;
    font-family: inherit;
    font-size: var(--body-s);
    line-height: var(--leading-tight);
    padding: 1.5px 8px
}

.Tag_tag__5SKaf:not(.Tag_with-children__WleyP):before {
    content: "#"
}

.Tag_tag__5SKaf span {
    color: inherit
}

.Tag_tag__5SKaf.Tag_inversed__9UAZG {
    background-color: var(--sweet-liquorice-700);
    color: var(--sweet-liquorice-100)
}

.Contact_content__IUMuG {
    row-gap: var(--spacing-5-xl);
    padding: 64px var(--spacing-2-xl) 176px
}

@media screen and (max-width: 1440px) {
    .Contact_content__IUMuG {
        padding-left:var(--spacing-1-xl);
        padding-right: var(--spacing-1-xl)
    }
}

@media screen and (max-width: 920px) {
    .Contact_content__IUMuG {
        padding:0 24px 64px
    }
}

@media screen and (max-width: 1440px) {
    .Contact_contact-description__1nZLc {
        max-width:100%
    }
}

@media screen and (max-width: 920px) {
    .Contact_contact-description__1nZLc {
        grid-column:1/-1;
        --span: 12
    }
}

.Contact_contact-description__1nZLc>div {
    line-height: var(--leading-relaxed)
}

@media screen and (max-width: 920px) {
    .Contact_contact-description__1nZLc>div {
        font-size:var(--body-l)
    }
}

.Contact_contact-cta__OrQU1 {
    display: flex;
    padding-top: 92px;
    flex-direction: column;
    gap: var(--spacing-m);
    max-width: 412px
}

@media screen and (max-width: 920px) {
    .Contact_contact-cta__OrQU1 {
        max-width:100%;
        padding-top: var(--spacing-2-xl);
        padding-bottom: var(--spacing-4-xl);
        gap: var(--spacing-m);
        border-bottom: 1px solid var(--sweet-liquorice-200)
    }
}

.Contact_contact__n5RaJ {
    border-left: 1px solid var(--sweet-liquorice-200);
    padding-left: 64px;
    margin-left: 64px
}

@media screen and (max-width: 1280px) {
    .Contact_contact__n5RaJ {
        padding-left:64px;
        margin-left: 0;
        border: none
    }
}

@media screen and (max-width: 920px) {
    .Contact_contact__n5RaJ {
        padding-left:0;
        margin-left: 0;
        border-left: none
    }
}

.Avatar_avatar__wkq6Y {
    --size: var(--ui-size-1-xs);
    border-radius: var(--radius-full);
    width: var(--size);
    height: var(--size);
    overflow: hidden
}

.Avatar_image__TUPLD {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: var(--size);
    height: var(--size);
    object-fit: cover
}

.Avatar_image__TUPLD img,.Avatar_image__TUPLD picture {
    object-fit: cover;
    object-position: top
}

.Avatar_size-3-xl__u51ZT {
    --size: var(--ui-size-2-xl)
}

.Avatar_size-2-xl__v_G1l {
    --size: var(--ui-size-1-xl)
}

.Avatar_size-1-xl__PANnR {
    --size: var(--ui-size-l)
}

.Avatar_size-l__Gy1ks {
    --size: var(--ui-size-m)
}

.Avatar_size-m__KbhXf {
    --size: var(--ui-size-s)
}

.ContactForm_form__qdC_m {
    width: 100%;
    display: flex;
    flex-direction: column
}

.ContactForm_form__qdC_m .ContactForm_wrapper__2r6uv {
    margin-bottom: var(--spacing-3-xl)
}

@media screen and (max-width: 768px) {
    .ContactForm_form__qdC_m .ContactForm_wrapper__2r6uv {
        -moz-column-gap:var(--spacing-1-xl);
        column-gap: var(--spacing-1-xl)
    }
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf {
    display: flex;
    flex-direction: row;
    justify-content: flex-start
}

@media screen and (max-width: 768px) {
    .ContactForm_form__qdC_m .ContactForm_button-container__gUZkf {
        flex-direction:column;
        justify-content: flex-start
    }
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf:not(:last-child) {
    margin-bottom: var(--spacing-3-xl)
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf button {
    align-self: flex-start
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf p {
    margin-left: var(--spacing-1-xl);
    align-items: center;
    display: flex
}

@media screen and (max-width: 768px) {
    .ContactForm_form__qdC_m .ContactForm_button-container__gUZkf p {
        margin-left:0;
        margin-top: var(--spacing-1-xl)
    }
}

.ContactForm_form__qdC_m .ContactForm_button-container__gUZkf p svg {
    margin-right: var(--spacing-2-xs);
    flex-shrink: 0
}

.ContactForm_form__qdC_m .ContactForm_form-row__UTeLK {
    display: flex;
    flex-wrap: wrap;
    -moz-column-gap: var(--spacing-2-xl);
    column-gap: var(--spacing-2-xl)
}

@media screen and (max-width: 562px) {
    .ContactForm_form__qdC_m .ContactForm_form-row__UTeLK {
        flex-direction:column
    }
}

.Input_input__fIbUx {
    contain: layout;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    flex: 1 1
}

.Input_input__fIbUx .Input_label__ZjenT {
    margin-bottom: var(--spacing-s);
    margin-left: var(--spacing-1-xl);
    display: inline-flex;
    align-items: center;
    color: var(--input-label-color);
    min-height: 24px
}

.Input_input__fIbUx .Input_label__ZjenT.Input_is-filled__DN8Kn {
    color: var(--sweet-liquorice-500)
}

@media screen and (max-width: 1024px) {
    .Input_input__fIbUx .Input_label__ZjenT {
        flex-direction:column;
        align-items: flex-start
    }
}

.Input_input__fIbUx .Input_error__z7sMl,.Input_input__fIbUx .Input_success__UiSNj {
    display: flex;
    align-items: center;
    margin: -3px 24px 0;
    border-radius: var(--radius-1-xs);
    padding: 12px;
    gap: 12px
}

.Input_input__fIbUx .Input_error__z7sMl svg,.Input_input__fIbUx .Input_success__UiSNj svg {
    margin-bottom: auto;
    flex-shrink: 0
}

.Input_input__fIbUx .Input_error__z7sMl {
    background-color: var(--chewy-cherry-100)
}

.Input_input__fIbUx .Input_error__z7sMl,.Input_input__fIbUx .Input_error__z7sMl span {
    color: var(--chewy-cherry-800)
}

.Input_input__fIbUx .Input_success__UiSNj,.Input_input__fIbUx .Input_success__UiSNj span {
    color: var(--fresh-mint-950)
}

.Input_input__fIbUx .Input_is-disabled__ClCMF {
    color: var(--sweet-liquorice-600)
}

.Input_input-container__GjvvN {
    position: relative
}

.Input_input-container__GjvvN:after {
    position: absolute;
    color: var(--sweet-liquorice-400);
    font-weight: 300;
    content: none;
    font-family: inherit;
    font-size: 48px;
    bottom: -7px;
    line-height: 72px;
    right: 24px
}

@media screen and (max-width: 1024px) {
    .Input_input-container__GjvvN:after {
        line-height:64px
    }
}

.Input_input-element__QYM_i {
    background-color: black;
    border: 1px solid black;
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: var(--radius-full);
    margin: 0;
    width: 100%;
    font-family: inherit;
    color: #40462C;
    padding: 0 24px;
    line-height: 72px;
    font-size: var(--body-1-xl)
}

@media screen and (max-width: 1024px) {
    .Input_input-element__QYM_i {
        line-height:64px
    }
}

.Input_input-element__QYM_i::-moz-placeholder {
    color: var(--input-placeholder-color)
}

.Input_input-element__QYM_i::placeholder {
    color: var(--input-placeholder-color)
}


.Input_input-element__QYM_i:focus,.Input_input-element__QYM_i:focus-within {
    border-color: #40462C;
    outline: 0;
    caret-color: var(--input-caret-color)
}

.Input_input-element__QYM_i.Input_has-error__Z_E5l {
    border-color: var(--chewy-cherry-500)
}

.Input_input-element__QYM_i:disabled {
    background-color: var(--sweet-liquorice-100);
    border-color: var(--sweet-liquorice-200);
    cursor: not-allowed
}

.Input_has-asterisk__SakyO:after {
    content: "*"
}

.Input_has-asterisk__SakyO .Input_input-element__QYM_i {
    padding-right: 48px
}

.TextArea_textarea__rBSKV {
    contain: layout;
    display: inline-flex;
    flex-direction: column;
    position: relative
}

.TextArea_textarea__rBSKV .TextArea_label__HuKAt.TextArea_is-filled__sIYvH {
    color: var(--sweet-liquorice-500)
}

.TextArea_textarea__rBSKV .TextArea_is-disabled__foSyg {
    color: var(--sweet-liquorice-600)
}

.TextArea_textarea__rBSKV .TextArea_label__HuKAt {
    margin-bottom: var(--spacing-s);
    margin-left: var(--spacing-1-xl);
    display: inline-flex;
    align-items: center;
    color: var(--input-label-color);
    min-height: 24px
}

@media screen and (max-width: 1024px) {
    .TextArea_textarea__rBSKV .TextArea_label__HuKAt {
        flex-direction:column;
        align-items: flex-start
    }
}

.TextArea_textarea__rBSKV .TextArea_error__W98du {
    display: flex;
    align-items: center;
    background-color: var(--chewy-cherry-100);
    margin: -3px 24px 0;
    border-radius: var(--radius-1-xs);
    padding: 12px;
    gap: 12px
}

.TextArea_textarea__rBSKV .TextArea_error__W98du,.TextArea_textarea__rBSKV .TextArea_error__W98du span {
    color: var(--chewy-cherry-800)
}

.TextArea_textarea__rBSKV .TextArea_error__W98du svg {
    margin-bottom: auto;
    flex-shrink: 0
}

.TextArea_textarea-container__73ZqX {
    position: relative;
    display: flex
}

.TextArea_textarea-container__73ZqX:after {
    position: absolute;
    color: var(--sweet-liquorice-400);
    font-weight: 300;
    content: none;
    font-family: inherit;
    font-size: 48px;
    bottom: 0;
    line-height: 0;
    right: 24px;
    height: 0;
    top: 48px
}

.TextArea_textarea-element__UIFT7 {
    background-color: black;
    border: 1px solid black;
    outline: 0;
    transition: border-color var(--fast-transition) var(--ease);
    border-radius: var(--radius-5-xl);
    margin: 0;
    width: 100%;
    font-family: inherit;
    resize: vertical;
    color: #40462C;
    padding: 21px;
    font-size: var(--body-1-xl);
    scrollbar-width: none;
    min-height: 160px;
    height: 160px
}

.TextArea_textarea-element__UIFT7::-webkit-scrollbar {
    display: none
}

.TextArea_textarea-element__UIFT7::-moz-placeholder {
    color: var(--input-placeholder-color)
}

.TextArea_textarea-element__UIFT7::placeholder {
    color: var(--input-placeholder-color)
}

.TextArea_textarea-element__UIFT7:focus,.TextArea_textarea-element__UIFT7:focus-within {
    border-color: #40462C;
    outline: 0;
    caret-color: var(--input-caret-color)
}

.TextArea_textarea-element__UIFT7.TextArea_has-error__OPBhH {
    border-color: var(--chewy-cherry-500)
}

.TextArea_textarea-element__UIFT7:disabled {
    background-color: var(--sweet-liquorice-100);
    border-color: var(--sweet-liquorice-200);
    cursor: not-allowed
}

.TextArea_has-asterisk__3fXhV:after {
    content: "*"
}

.TextArea_has-asterisk__3fXhV .TextArea_textarea-element__UIFT7 {
    padding-right: 48px
}

.SectionFAQ_faq__ERUdf {
    padding: 144px 0
}

@media screen and (max-width: 920px) {
    .SectionFAQ_faq__ERUdf {
        padding:80px 0
    }
}

.SectionFAQ_faq__ERUdf.SectionFAQ_is-dark__4l0Rv {
    background-color: white;
    color: black
}

.SectionFAQ_faq__ERUdf.SectionFAQ_is-dark__4l0Rv .SectionFAQ_title___dzk8 {
    color: black;
    padding: 28px 0 8px
}

@media screen and (max-width: 920px) {
    .SectionFAQ_faq__ERUdf.SectionFAQ_is-dark__4l0Rv .SectionFAQ_title___dzk8 {
        padding:0 0 8px
    }
}

.SectionFAQ_faq__ERUdf.SectionFAQ_is-dark__4l0Rv .SectionFAQ_description__O1IHD {
    color: black;
    max-width: 292px;
    padding-bottom: 16px
}

@media screen and (max-width: 920px) {
    .SectionFAQ_faq__ERUdf.SectionFAQ_is-dark__4l0Rv .SectionFAQ_description__O1IHD {
        max-width:50vw
    }
}

@media screen and (max-width: 414px) {
    .SectionFAQ_faq__ERUdf.SectionFAQ_is-dark__4l0Rv .SectionFAQ_description__O1IHD {
        max-width:100%
    }
}

.FAQItem_faq__Fbxhy {
    overflow: hidden;
    background-color: black;
    border-bottom: 1px solid var(--sweet-liquorice-200);
    transition: border-color var(--fast-transition) ease-in-out
}

.FAQItem_faq__Fbxhy:last-child {
    border-bottom: 0
}

.FAQItem_faq__Fbxhy .FAQItem_icon__CINpU {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color var(--regular-transition) var(--ease-out)
}

.FAQItem_faq__Fbxhy .FAQItem_icon__CINpU>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition-property: opacity color;
    transition-duration: var(--ease-out);
    transition-duration: var(--fast-transition)
}

.FAQItem_faq__Fbxhy .FAQItem_plus-icon__5phm0 {
    opacity: 1
}

.FAQItem_faq__Fbxhy .FAQItem_minus-icon__H6mPU,.FAQItem_faq__Fbxhy.FAQItem_open__M4rW7 .FAQItem_plus-icon__5phm0 {
    opacity: 0
}

.FAQItem_faq__Fbxhy.FAQItem_open__M4rW7 .FAQItem_minus-icon__H6mPU {
    opacity: 1
}

.FAQItem_faq__Fbxhy.FAQItem_open__M4rW7>a {
    border-color: transparent
}

.FAQItem_faq__Fbxhy.FAQItem_open__M4rW7>a span {
    color: #40462C
}

.FAQItem_faq__Fbxhy>a {
    padding: 28px 0;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center
}

.FAQItem_faq__Fbxhy>a:focus {
    outline: 0
}

.FAQItem_faq__Fbxhy>a span {
    transition: color .15s ease-out
}

.FAQItem_faq__Fbxhy>a div,.FAQItem_faq__Fbxhy>a svg {
    justify-self: end
}


.FAQItem_faq__Fbxhy .FAQItem_body__qk5TX p {
    padding: 0 0 32px;
    max-width: calc(100% - 40px);
    line-height: var(--leading-relaxed)
}

@media screen and (max-width: 562px) {
    .FAQItem_faq__Fbxhy .FAQItem_body__qk5TX p {
        max-width:100%;
        padding: 0 0 20px
    }
}

.FAQItem_faq__Fbxhy .FAQItem_body__qk5TX p a {
    color: #40462C;
    text-decoration: underline
}

.FAQItem_faq__Fbxhy.FAQItem_is-dark__zv1Ig {
    background-color: #40462C;
    border-bottom-color: var(--sweet-liquorice-700)
}

.FAQItem_faq__Fbxhy.FAQItem_is-dark__zv1Ig.FAQItem_open__M4rW7>a span {
    color: black
}

.FAQItem_faq__Fbxhy.FAQItem_is-dark__zv1Ig>a span {
    color: black
}
.FAQItem_faq__Fbxhy.FAQItem_is-dark__zv1Ig .FAQItem_body__qk5TX p {
    color: black
}

.FAQItem_faq__Fbxhy.FAQItem_is-dark__zv1Ig .FAQItem_body__qk5TX p a {
    color: black;
    text-decoration: underline
}

.SectionDecks_section-decks__H0sWk {
    scroll-margin-top: var(--spacing-6-xl);
    margin-top: var(--spacing-6-xl);
    margin-bottom: var(--spacing-6-xl);
    overflow: hidden
}

.SectionDecks_section-decks__H0sWk h2 {
    margin-bottom: var(--spacing-m)
}

.SectionDecks_section-decks__H0sWk p {
    color: var(--sweet-liquorice-750)
}

@media screen and (max-width: 768px) {
    .SectionDecks_header__JjQTn {
        margin-bottom:var(--spacing-3-xl)
    }
}

@media screen and (max-width: 1280px) {
    .SectionDecks_wrapper__NqexH {
        grid-column:1/-1
    }
}

.SectionDecks_decks__mvxtR {
    width: calc(100% + var(--gap));
    overflow: hidden;
    margin-left: calc(-1*var(--gap))
}

@media screen and (max-width: 1120px) {
    .SectionDecks_decks__mvxtR {
        --gap:40px;
        width: 100%;
        margin-left: 0
    }
}

@media screen and (max-width: 1024px) {
    .SectionDecks_decks__mvxtR {
        grid-template-columns:repeat(2,1fr)
    }
}

@media screen and (max-width: 920px) {
    .SectionDecks_decks__mvxtR {
        --gap:32px;
        grid-template-columns: repeat(6,1fr)
    }
}

@media screen and (max-width: 562px) {
    .SectionDecks_decks__mvxtR {
        --gap:56px
    }
}

.DeckItem_deck-item__B4CDd {
    padding-left: var(--gap);
    border-left: 1px solid var(--sweet-liquorice-200);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1px
}

@media screen and (max-width: 1120px) {
    .DeckItem_deck-item__B4CDd {
        padding-left:0;
        border: none
    }
}

@media screen and (max-width: 920px) {
    .DeckItem_deck-item__B4CDd {
        grid-column:span 3/span 3
    }
}

@media screen and (max-width: 562px) {
    .DeckItem_deck-item__B4CDd {
        grid-column:span 6/span 6
    }
}

.DeckItem_deck-item__B4CDd .DeckItem_text__8uxP5 {
    margin-top: var(--spacing-2-xl)
}

.DeckItem_deck-item__B4CDd .DeckItem_text__8uxP5 h3 {
    margin-bottom: var(--spacing-m)
}

.DeckItem_deck-item__B4CDd .DeckItem_text__8uxP5 p {
    margin-bottom: var(--spacing-2-xl)
}

.DeckItem_deck-item__B4CDd:first-child {
    border: none
}

.DeckItem_filetype__RVXE6 {
    display: inline-block!important;
    margin-left: var(--spacing-3-xs);
    font-weight: 500;
    color: var(--sweet-liquorice-750)
}

@media screen and (max-width: 768px) {
    .DeckItem_filetype__RVXE6 {
        display:none!important
    }
}

.LazyImage_wrapper__KFcqr {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    border-radius: var(--radius-1-xs)
}

.LazyImage_bubble__UNDBu {
    --start-color: var(--sweet-liquorice-200);
    --end-color: var(--sweet-liquorice-100);
    animation: LazyImage_bubble__UNDBu .7s ease-out forwards;
    background-color: var(--start-color);
    border-radius: 50%;
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -40%;
    transform-origin: center;
    transform: translateZ(0);
    transition: opacity .15s ease-out;
    transition-property: transform,background-color,opacity;
    z-index: 50
}

.LazyImage_bubble-background__AwWqn {
    --section-background: black;
    background-color: var(--section-background);
    position: absolute;
    inset: 0;
    z-index: 49
}

@keyframes LazyImage_bubble__UNDBu {
    0% {
        background-color: var(--start-color);
        transform: translateZ(0) scale(0)
    }

    to {
        background-color: var(--end-color);
        transform: translateZ(0) scale(3)
    }
}

.SectionOffice_section-office__CyuGR {
    position: relative;
    overflow: hidden;
    margin-bottom: var(--spacing-6-xl)
}

.SectionOffice_section-office__CyuGR.SectionOffice_dark__I1Eg_ {
    background-color: #40462C;
    padding-bottom: var(--spacing-5-xl)
}

.SectionOffice_section-office__CyuGR.SectionOffice_dark__I1Eg_ h2::-moz-selection,.SectionOffice_section-office__CyuGR.SectionOffice_dark__I1Eg_ p::-moz-selection {
    background-color: var(--selection-sweet-liquorice)
}

.SectionOffice_section-office__CyuGR.SectionOffice_dark__I1Eg_ h2::selection,.SectionOffice_section-office__CyuGR.SectionOffice_dark__I1Eg_ p::selection {
    background-color: var(--selection-sweet-liquorice)
}

.SectionOffice_section-office__CyuGR .SectionOffice_grid___2onB {
    row-gap: 0
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY {
    grid-row: 1;
    z-index: 1;
    padding-top: var(--spacing-6-xl);
    padding-bottom: var(--spacing-5-xl)
}

@media screen and (max-width: 1024px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY {
        grid-column:1/6
    }
}

@media screen and (max-width: 920px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY {
        grid-column:1/-1;
        grid-row: auto
    }
}

@media screen and (max-width: 562px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY {
        padding-top:var(--spacing-5-xl)
    }
}

@media screen and (max-width: 414px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY {
        padding-top:0;
        margin-top: var(--spacing-1-xl)
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf {
    margin-bottom: var(--spacing-4-xl)
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf h2 {
    margin-bottom: var(--spacing-m)
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf [data-transport-mode] {
    --accent: var(--sweet-liquorice-600);
    /* cursor: pointer; */
    color: var(--sweet-liquorice-600)
}

@media screen and (max-width: 414px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf [data-transport-mode] {
        --accent:inherit;
        color: inherit
    }

    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf [data-transport-mode]:after,.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf [data-transport-mode]:before {
        display: none
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf.SectionOffice_dark__I1Eg_ {
    --accent: black
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf.SectionOffice_dark__I1Eg_ h2 {
    color: black
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf.SectionOffice_dark__I1Eg_ p {
    color: black
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf.SectionOffice_dark__I1Eg_ p [data-transport-mode] {
    --accent: black;
    color: black
}

@media screen and (max-width: 414px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf.SectionOffice_dark__I1Eg_ p [data-transport-mode] {
        --accent:inherit;
        color: inherit
    }
}

@media screen and (max-width: 920px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_heading__95DNf {
        max-width:100%
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_address__dB6Bb.SectionOffice_dark__I1Eg_ a,.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_address__dB6Bb.SectionOffice_dark__I1Eg_ h3 {
    color: black
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_address__dB6Bb address {
    font-style: unset
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_address__dB6Bb h3 {
    margin-bottom: var(--spacing-m)
}

.SectionOffice_section-office__CyuGR .SectionOffice_content__WqasY .SectionOffice_address__dB6Bb a {
    line-height: var(--leading-normal)
}

.SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze {
    position: absolute;
    top: -20px;
    right: 0;
    width: 100%;
    max-width: 1440px;
    height: 964px
}

.SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%
}

.SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center
}

@media screen and (max-width: 414px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze img {
        display:block
    }
}

@media screen and (max-width: 1024px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze {
        height:800px
    }
}

@media screen and (max-width: 920px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze {
        width:160%;
        height: 360px;
        grid-column: 1/-1;
        top: 0;
        position: relative;
        margin-left: -32px;
        left: -40%
    }
}

@media screen and (max-width: 562px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze {
        height:240px
    }
}

@media screen and (max-width: 414px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_map__Ad7ze {
        height:auto;
        width: calc(100% + 40px);
        margin-left: -20px;
        left: 0;
        grid-column: 1/-1
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc {
    --grid-size: var(--ui-size-l);
    display: grid;
    grid-gap: var(--spacing-1-xl);
    gap: var(--spacing-1-xl);
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(12,1fr);
    padding-top: var(--spacing-2-xl);
    z-index: 1
}

@media screen and (max-width: 920px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc {
        padding-bottom:var(--spacing-5-xl)
    }
}

@media screen and (max-width: 562px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc {
        grid-template-rows:repeat(2,1fr);
        padding-top: 0
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc>div:first-child {
    grid-area: 1/2/8/9
}

@media screen and (max-width: 562px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc>div:first-child {
        grid-area:1/1/2/11
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc>div:nth-child(2) {
    grid-area: 8/1/13/5
}

@media screen and (max-width: 562px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc>div:nth-child(2) {
        grid-area:2/5/3/13
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc>div:nth-child(3) {
    grid-area: 5/9/12/13
}

@media screen and (max-width: 562px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc>div:nth-child(3) {
        display:none!important
    }
}

.SectionOffice_section-office__CyuGR .SectionOffice_images__5zWPc img {
    width: 100%;
    height: auto
}

.SectionOffice_section-office__CyuGR .SectionOffice_office-carousel__ZuBoV {
    align-self: center;
    z-index: 1
}

@media screen and (max-width: 920px) {
    .SectionOffice_section-office__CyuGR .SectionOffice_office-carousel__ZuBoV {
        max-width:504px;
        width: 100%;
        margin: 0 auto
    }
}

.SectionOffice_overlay__Yr6BY {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: linear-gradient(to right,#40462C 400px,transparent 700px);
    z-index: 1
}

.SectionOffice_overlay__Yr6BY.SectionOffice_light__7jIMF {
    background-image: linear-gradient(90deg,#fff 400px,hsla(0,0%,100%,0) 700px)
}

@media screen and (max-width: 920px) {
    .SectionOffice_overlay__Yr6BY {
        display:none
    }
}

@media screen and (max-width: 414px) {
    .SectionOffice_svgMap__eN6qF {
        display:none
    }
}

.Carousel_carousel__xEsTa {
    position: relative
}

.Carousel_carousel__xEsTa.Carousel_hidden__ZlS7U {
    overflow: hidden
}

@media screen and (max-width: 562px) {
    .Carousel_carousel__xEsTa {
        overflow:scroll
    }
}

@media screen and (max-width: 768px) {
    .Carousel_carousel__xEsTa.Carousel_mobile__MxGVN {
        overflow:hidden
    }
}

.Carousel_carousel__xEsTa .Carousel_slides__PqH3g {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 24px;
    gap: 24px;
    scroll-margin-bottom: 32px;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: hidden;
    width: 100%
}

.Carousel_carousel__xEsTa .Carousel_slides-padding-top__VxPYp {
    padding-top: var(--spacing-2-xl)
}

.Carousel_carousel__xEsTa.Carousel_hide-scrollbar__gLiMH {
    -ms-overflow-style: none;
    scrollbar-width: none
}

.Carousel_carousel__xEsTa.Carousel_hide-scrollbar__gLiMH::-webkit-scrollbar {
    display: none
}

.Carousel_header__ctjMG {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.Carousel_header__ctjMG.Carousel_inversed__1sCiX h1,.Carousel_header__ctjMG.Carousel_inversed__1sCiX h2,.Carousel_header__ctjMG.Carousel_inversed__1sCiX h3,.Carousel_header__ctjMG.Carousel_inversed__1sCiX h4,.Carousel_header__ctjMG.Carousel_inversed__1sCiX p {
    color: black
}

.Carousel_controls__HznZg {
    display: flex;
    gap: 16px;
    align-items: center
}

@media screen and (max-width: 768px) {
    .Carousel_controls__HznZg>div {
        display:none
    }
}

.Carousel_controls__HznZg .Carousel_dots__ZpuwS {
    margin-right: var(--spacing-2-xl)
}

@media screen and (max-width: 562px) {
    .Carousel_controls__HznZg button {
        display:none
    }
}

@media screen and (max-width: 768px) {
    .Carousel_controls__HznZg.Carousel_mobile__MxGVN button {
        display:flex
    }
}

.Carousel_group__r4pIq {
    display: grid;
    width: 100%;
    opacity: 1;
    transition: opacity .4s
}

.Carousel_group__r4pIq.Carousel_inactive__KeWdE {
    opacity: .2
}

@media screen and (max-width: 562px) {
    .Carousel_group__r4pIq.Carousel_inactive__KeWdE {
        opacity:1
    }
}

.Carousel_group__r4pIq.Carousel_inactive__KeWdE.Carousel_hidden-slide__zqKMV {
    opacity: 0
}

.Carousel_control-wrapper__T7hDP {
    --left: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
    left: var(--left);
    position: absolute;
    top: 0;
    width: 100vw
}

.Carousel_divider__DuRS6 {
    margin-top: 12px;
    width: 120%;
    background: var(--sweet-liquorice-200)
}


@media screen and (max-width: 920px) {
    .Carousel_divider__DuRS6 {
        width:100%
    }
}

.Carousel_divider__DuRS6.Carousel_inversed__1sCiX {
    background: var(--sweet-liquorice-700)
}

.PaginationDotGroup_pagination-dot-group__NF7v5 {
    --indicator-color: #40462C;
    --ring-color: var(--sweet-liquorice-600);
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit,16px);
    height: 16px;
    justify-content: start
}

.PaginationDotGroup_pagination-dot-group__NF7v5.PaginationDotGroup_inversed__D8TOI {
    --indicator-color: black;
    --ring-color: var(--indicator-color)
}

.PaginationDotGroup_pagination-dot-group__NF7v5 input {
    display: none
}

.PaginationDotGroup_pagination-dot-group__NF7v5 input:checked+label {
    --ring-color: transparent;
    background: var(--indicator-color);
    transform: scale(1.5)
}


.PaginationDotGroup_pagination-dot-group__NF7v5 input:focus+label,.PaginationDotGroup_pagination-dot-group__NF7v5 label:focus,.PaginationDotGroup_pagination-dot-group__NF7v5 label:focus-within {
    box-shadow: 0 0 0 1px var(--icy-slushie-500)
}

.PaginationDotGroup_pagination-dot-group__NF7v5 label {
    background: 0 0;
    border-radius: 50%;
    box-sizing: initial;
    border: 1px solid var(--ring-color);
    /* cursor: pointer; */
    display: flex;
    height: 10px;
    width: 10px;
    justify-content: center;
    position: relative;
    transition: none var(--regular-transition) var(--ease-out);
    transition-property: background,transform
}

.PaginationDotGroup_pagination-dot-group__NF7v5 label:after {
    content: "";
    height: 300%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 300%
}
